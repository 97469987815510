define("ember-svg-jar/inlined/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2.483 8.736V7.62a.3.3 0 01.301-.3H3.9a.3.3 0 01.301.3v1.116a.3.3 0 01-.3.301H2.783a.304.304 0 01-.3-.3zm2.634.298h1.116a.3.3 0 00.301-.301V7.617a.3.3 0 00-.3-.3H5.116a.3.3 0 00-.3.3v1.116a.304.304 0 00.3.3zm2.333 0h1.116a.3.3 0 00.301-.301V7.617a.3.3 0 00-.3-.3H7.45a.3.3 0 00-.3.3v1.116a.304.304 0 00.3.3zm-2.333 2.274h1.116a.3.3 0 00.301-.301V9.891a.3.3 0 00-.3-.3H5.116a.3.3 0 00-.3.3v1.116a.304.304 0 00.3.301zm2.333 0h1.116a.3.3 0 00.301-.301V9.891a.3.3 0 00-.3-.3H7.45a.3.3 0 00-.3.3v1.116a.304.304 0 00.3.301zm2.337 0h1.115a.3.3 0 00.301-.301V9.891a.3.3 0 00-.3-.3H9.786a.3.3 0 00-.302.3v1.116a.3.3 0 00.302.301zm-7.003 0H3.9a.3.3 0 00.301-.301V9.891a.3.3 0 00-.3-.3H2.783a.3.3 0 00-.3.3v1.116a.3.3 0 00.3.301zm4.666 2.27h1.116a.3.3 0 00.301-.3v-1.116a.3.3 0 00-.3-.301H7.45a.3.3 0 00-.3.301v1.116a.304.304 0 00.3.3zm-2.333 0h1.116a.3.3 0 00.301-.3v-1.116a.3.3 0 00-.3-.301H5.116a.3.3 0 00-.3.301v1.116a.304.304 0 00.3.3zm4.67-4.544h1.115a.3.3 0 00.301-.301V7.617a.3.3 0 00-.3-.3H9.786a.3.3 0 00-.302.3v1.116a.3.3 0 00.302.3zm2.332 2.274h1.116a.3.3 0 00.301-.301V9.891a.3.3 0 00-.3-.3h-1.117a.3.3 0 00-.3.3v1.116a.304.304 0 00.3.301zm-2.332 2.27h1.115a.3.3 0 00.301-.3v-1.116a.3.3 0 00-.3-.301H9.786a.3.3 0 00-.302.301v1.116a.3.3 0 00.302.3zM0 14.25V3.475c0-.965.785-1.75 1.75-1.75h1.964V.637c0-.353.284-.638.638-.638h.998c.353 0 .638.285.638.638v1.083h4.024V.638c0-.353.285-.638.638-.638h.998c.354 0 .638.285.638.638v1.083h1.963c.966 0 1.751.785 1.751 1.75V14.25c0 .966-.785 1.751-1.75 1.751H1.75C.786 16 0 15.215 0 14.25zm1.355 0c0 .22.18.4.399.4h12.495a.4.4 0 00.4-.4V6.27H1.355v7.98z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});