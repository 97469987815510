define("ember-bulk-manager/components/sub/bulk-import-prefill-field-numeric", ["exports", "ember-bulk-manager/templates/sub/bulk-import-prefill-field-numeric", "ember-bulk-manager/components/sub/bulk-import-prefill-field-string"], function (_exports, _bulkImportPrefillFieldNumeric, _bulkImportPrefillFieldString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-prefill-field` representing field from type numeric.
   *
   * ```html
   * {{sub/bulk-import-prefill-field-numeric availableField=availableField}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(value)
   *
   * @namespace Component
   * @class BulkImportPrefillFieldNumeric
   * @extends BulkImportPrefillFieldString
   * @public
   */
  var _default = _bulkImportPrefillFieldString.default.extend({
    layout: _bulkImportPrefillFieldNumeric.default,
    attributeBindings: ['min'],
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Type attribute of <input> element.
     *
     * @property type
     * @type {String}
     * @public
     */
    type: 'number',

    /**
     * Minimum attribute of <input> element.
     *
     * @property min
     * @type {Number}
     * @public
     */
    min: 0
  });

  _exports.default = _default;
});