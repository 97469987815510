define("ember-svg-jar/inlined/values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M16.295 11.184H4.063V.708A.67.67 0 003.4.033a.669.669 0 00-.662.675v10.476H.662A.669.669 0 000 11.86a.67.67 0 00.662.675h2.076v1.757c0 .373.297.675.662.675a.669.669 0 00.663-.675v-1.757h12.232a.67.67 0 00.663-.675.67.67 0 00-.663-.676z\"/><path d=\"M5.638 9.838h1.823a.214.214 0 00.212-.216V5.317a.214.214 0 00-.212-.216H5.638a.214.214 0 00-.212.216v4.305c0 .12.095.216.212.216zm3.594-.1h1.823c.117 0 .212-.131.212-.293v-5.82c0-.16-.095-.292-.212-.292H9.232c-.117 0-.212.131-.212.293v5.82c0 .16.095.292.212.292zm3.593.13h1.823c.117 0 .212-.065.212-.145v-2.91c0-.08-.095-.146-.212-.146h-1.823c-.117 0-.212.065-.212.146v2.91c0 .08.095.146.212.146z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "15",
      "viewBox": "0 0 17 15"
    }
  };
  _exports.default = _default;
});