define("@vollersgroup/ember-cropster-common/mixins/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Scroll to top on route activation.
   *
   * @namespace EmberCropsterCommon.Mixin
   * @class ResetScroll
   * @extends Ember.Mixin
   * @public
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    /**
     * On activate, scroll to top.
     *
     * @method activate
     * @override
     * @protected
     */
    activate: function activate() {
      this._super();

      var rootElement = Ember.testing ? document.querySelector('#ember-testing-container') : window;
      rootElement.scrollTo(0, 0);
    }
  });

  _exports.default = _default;
});