define("ember-svg-jar/inlined/evaluators-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#282F39\" d=\"M12.28 13.626l-2.965-1.503a1.15 1.15 0 01-.625-1.026v-1.18c.083-.096.178-.22.28-.366.404-.579.71-1.216.922-1.884a.942.942 0 00.66-.898v-1.26a.945.945 0 00-.31-.698v-1.82S10.61.157 6.827.157c-3.783 0-3.414 2.834-3.414 2.834v1.82a.945.945 0 00-.31.698v1.26c0 .331.171.623.429.792a6.276 6.276 0 001.122 2.356v1.15c0 .42-.226.806-.59 1.007l-2.768 1.532A2.53 2.53 0 000 15.823v1.02h13.655v-.958a2.53 2.53 0 00-1.376-2.259z\"/><path fill=\"#67A337\" d=\"M13.747 12.659c-.166-.084-.301-.263-.396-.457a.265.265 0 01.235-.382h2.136s.783.076 1.388-.187a.624.624 0 00.254-.924c-.647-.935-1.985-3.135-2.028-5.408 0 0-.074-3.498-3.476-3.526-.68.005-1.233.15-1.688.374.117.472.07.842.07.842v1.82c.188.173.31.421.31.698v1.26c0 .423-.28.78-.66.898a6.964 6.964 0 01-.922 1.884c-.102.146-.197.27-.28.366v1.18c0 .268.092.522.254.723h.853c.213 0 .308.273.14.407-.057.046-.113.089-.164.128l2.506 1.27a2.53 2.53 0 011.376 2.26v.958H18v-.85c0-.847-.472-1.622-1.22-2.001l-3.033-1.333z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17"
    }
  };
  _exports.default = _default;
});