define("ember-svg-jar/inlined/start", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.137 7.206L.74.063A.361.361 0 00.371.04a.357.357 0 00-.192.316v14.285a.357.357 0 00.562.294l10.396-7.142a.356.356 0 000-.588z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "15",
      "viewBox": "0 0 12 15"
    }
  };
  _exports.default = _default;
});