define("ember-svg-jar/inlined/arrow-down-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-arrow-down-grey</title><path d=\"M0 0h8L4 5z\" fill=\"#6E8595\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "8",
      "height": "5",
      "viewBox": "0 0 8 5",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});