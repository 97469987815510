define("ember-svg-jar/inlined/mark-consumed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.188 7.247L1.588.648l-.94.941 6.599 6.6-1.648 1.647 4.222-.013.014-4.222z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
  _exports.default = _default;
});