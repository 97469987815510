define("ember-svg-jar/inlined/cropster-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#80C342\" d=\"M13.72 9.09c-1.737.136-6.469.442-6.469.442s.17-.409.17-.885c0-.681-.034-5.821-.034-5.821s-.068-.681.273-1.294h-3.2s-3.234 0-3.405 2.825C.851 7.217.272 15.183.272 15.183s.647-.306 1.192-.409c.102-.034.272-.034.715-.034 2.11-.034 8.068 0 8.749 0 .578 0 3.404-.034 3.642-3.336.034-.715.102-1.634.136-2.655-.238.136-.578.306-.987.34\"/><path fill=\"#00A44D\" d=\"M15.557.987c0 .681.069 3.983 0 4.698-.034.272 0 1.634-1.395 1.736l-5.209.375s.102-.17.102-.647V2.383S8.885.681 10.723.545C12.8.409 15.693.17 15.693.17s-.136.545-.136.817\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});