define("ember-svg-jar/inlined/schedule-completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M0 14.25V3.474c0-.965.785-1.75 1.75-1.75h1.964V.637c0-.353.284-.638.638-.638h.998c.353 0 .638.285.638.638v1.083h4.024V.638c0-.353.285-.638.638-.638h.998c.354 0 .638.285.638.638v1.083h1.963c.966 0 1.751.785 1.751 1.75V14.25c0 .966-.785 1.751-1.75 1.751H1.75C.786 16 0 15.215 0 14.25zm1.355 0c0 .219.18.399.399.399h12.495a.4.4 0 00.4-.4V6.27H1.355v7.98z\"/><path d=\"M7.95 12.167l-.688.688L5 10.594l.99-.99 1.57 1.57L10.589 8l1.014.966-3.339 3.5-.314-.299z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});