define("ember-bulk-manager/components/sub/bulk-import-prefill-field", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-import-prefill-field"], function (_exports, _bulkComponent, _bulkImportPrefillField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-prefill` representing one prefill field.
   *
   * ```html
   * {{sub/bulk-import-prefill-field
   *     availableField=availableField
   *     onChange=(action "onChange")}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(value)
   *
   * @namespace Component
   * @class BulkImportPrefillField
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkImportPrefillField.default,
    classNames: ['bulk-import-prefill-field'],
    classNameBindings: ['valueClassName', 'typeClassName'],
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Corresponding field from `availableFields`.
     *
     * @attribute availableField
     * @type {Object}
     * @default null
     * @public
     */
    availableField: null,

    /**
     * If true, the field won't be editable which
     * is currently only an aliase to the property
     * `availableField.prefillOptions.disabled`.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: Ember.computed.reads('availableField.prefillOptions.disabled'),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Class name for field's `value` property.
     *
     * @property valueClassName
     * @type {String}
     * @public
     */
    valueClassName: Ember.computed('availableField.value', {
      get: function get()
      /* key*/
      {
        var value = Ember.get(this, 'availableField.value') || 'unknown';
        return "bulk-import-prefill-field--".concat(value);
      }
    }),

    /**
     * Class name for field's `type` property.
     *
     * @property typeClassName
     * @type {String}
     * @public
     */
    typeClassName: Ember.computed('availableField.value', {
      get: function get()
      /* key*/
      {
        var type = Ember.get(this, 'availableField.prefillOptions.type') || 'string';
        return "bulk-import-prefill-field--".concat(type);
      }
    }),

    /**
     * Id for subcomponent and <label> property.
     *
     * @property subComponentId
     * @type {String}
     * @public
     */
    subComponentId: Ember.computed('elementId', {
      get: function get()
      /* key*/
      {
        var elementId = Ember.get(this, 'elementId');
        return "".concat(elementId, "-subcomponent");
      }
    }),

    /**
     * Corresponding sub component depending on `type` of `prefillField`.
     *
     * @property subComponentName
     * @type {String}
     * @default 'sub/bulk-import-prefill-field-string'
     * @public
     */
    subComponentName: Ember.computed('availableField.prefillOptions.type', {
      get: function get()
      /* key*/
      {
        var subComponentName;

        switch (Ember.get(this, 'availableField.prefillOptions.type')) {
          case 'json':
            {
              subComponentName = 'sub/bulk-import-prefill-field-json';
              break;
            }

          case 'checkbox':
            {
              subComponentName = 'sub/bulk-import-prefill-field-checkbox';
              break;
            }

          case 'numeric':
            {
              subComponentName = 'sub/bulk-import-prefill-field-numeric';
              break;
            }

          case 'timestamp':
            {
              subComponentName = 'sub/bulk-import-prefill-field-timestamp';
              break;
            }

          default:
            {
              subComponentName = 'sub/bulk-import-prefill-field-string';
            }
        }

        return subComponentName;
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Proxies `onChange` event to parent component with value.
       *
       * @event onChange
       * @param {Mixed} value
       * @private
       */
      onChange: function onChange(value) {
        this.invokeCallback('onChange', value);
      }
    }
  });

  _exports.default = _default;
});