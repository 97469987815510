define("ember-bulk-manager/utils/bulk-array-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unionArray = void 0;

  /**
   * Unifies to array into a single one by
   * checking with a comparator function.
   *
   * @param  {Array} arrayA
   * @param  {Array} arrayB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Array}
   */
  var unionArray = function unionArray(arrayA, arrayB, comparatorFunction) {
    comparatorFunction = Ember.typeOf(comparatorFunction) === 'function' ? comparatorFunction : function (a, b) {
      return a === b;
    };
    var union = arrayA.concat(arrayB);

    for (var i = 0; i < union.length; i++) {
      for (var j = i + 1; j < union.length; j++) {
        var a = union[i];
        var b = union[j];

        if (comparatorFunction(a, b)) {
          // replace with latter item
          union.splice(i, 1, b); // and remove latter item

          union.splice(j--, 1);
        }
      }
    }

    return union;
  };

  _exports.unionArray = unionArray;
});