define("ember-bulk-manager/utils/bulk-comparison-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compareArray = _exports.compareObject = void 0;

  /**
   * Makes a deep comparison of enumerable object properties.
   *
   * @param  {Object} objectA
   * @param  {Object} objectB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Boolean}
   */
  var compareObject = function compareObject(objectA, objectB, comparatorFunction) {
    comparatorFunction = Ember.typeOf(comparatorFunction) === 'function' ? comparatorFunction : function (a, b) {
      return a === b;
    }; // first: all keys must have same length

    var keysA = Object.keys(objectA).sort();
    var keysB = Object.keys(objectB).sort();

    if (keysA.length !== keysB.length) {
      return false;
    } // second: all keys should be the same


    var sameKeys = keysA.every(function (keyA, idx) {
      return keyA === keysB[idx];
    });

    if (sameKeys === false) {
      return false;
    } // check if any value is not equal


    return keysA.every(function (key) {
      var valueA = objectA[key];
      var valueB = objectB[key];

      switch (Ember.typeOf(valueA)) {
        case 'array':
          {
            return compareArray(valueA, valueB, comparatorFunction);
          }

        case 'object':
          {
            return compareObject(valueA, valueB, comparatorFunction);
          }

        case 'instance':
          {
            var idA = valueA.id || Ember.guidFor(valueA);
            var idB = valueB.id || Ember.guidFor(valueB);
            return idA === idB;
          }

        default:
          return comparatorFunction(valueA, valueB);
      }
    });
  };
  /**
   * Makes a deep comparison of two arrays, array-like objects and nested arrays.
   *
   * @param  {Array} arrayA
   * @param  {Array} arrayB
   * @param  {Function} [comparatorFunction=(a,b) => a===b]
   * @return {Boolean}
   */


  _exports.compareObject = compareObject;

  var compareArray = function compareArray(arrayA, arrayB, comparatorFunction) {
    comparatorFunction = Ember.typeOf(comparatorFunction) === 'function' ? comparatorFunction : function (a, b) {
      return a === b;
    };

    if (Ember.isArray(arrayA) !== Ember.isArray(arrayB)) {
      return false;
    }

    if (arrayA.length !== arrayB.length) {
      return false;
    }

    return arrayA.every(function (valueA, idx) {
      var valueB = arrayB[idx]; // input type: 'array'

      if (Ember.isArray(valueA)) {
        if (!Ember.isArray(valueB)) {
          return false;
        }

        return compareArray(valueA, valueB, comparatorFunction);
      } // input type: 'object'


      switch (Ember.typeOf(valueA)) {
        case 'object':
          {
            if (Ember.typeOf(valueB) !== 'object') {
              return false;
            }

            var idA = valueA.id || Ember.guidFor(valueA);
            var idB = valueB.id || Ember.guidFor(valueB);
            return idA === idB;
          }

        case 'instance':
          {
            if (Ember.typeOf(valueB) !== 'instance') {
              return false;
            }

            return (valueA && valueA.id) === (valueB && valueB.id);
          }

        default:
      } // input type: primitives


      return comparatorFunction(valueA, valueB);
    });
  };

  _exports.compareArray = compareArray;
});