define("ember-svg-jar/inlined/rating-no-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.5 0C33.355 0 43 9.645 43 21.5S33.355 43 21.5 43 0 33.355 0 21.5 9.645 0 21.5 0zm-7 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm14 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM22 26h-.003c-7.702 0-10.69 4.22-10.814 4.4-.303.44-.221 1.06.18 1.393.401.332.976.247 1.283-.188.104-.147 2.63-3.605 9.351-3.605H22c6.722.001 9.25 3.46 9.35 3.6.18.262.455.4.734.4a.867.867 0 00.55-.2c.404-.331.487-.958.182-1.4C32.693 30.22 29.703 26 22 26z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43"
    }
  };
  _exports.default = _default;
});