define("ember-svg-jar/inlined/coffee-bean-roasting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.48 19.7c.289-.513.466-1.05.497-1.607.062-1.129-.423-2.25-1.367-3.157-.504-.486-2.154-2.222-2.192-4.172a3.177 3.177 0 01.177-1.096c.151-.011.296-.038.454-.03 2.4.129 4.645 2.514 5.017 5.33.312 2.36-.804 4.254-2.586 4.732m-.613-1.655c-.036.668-.334 1.313-.85 1.915-2.291-.29-4.382-2.577-4.74-5.282-.28-2.13.595-3.89 2.077-4.567-.031.219-.05.442-.046.671.046 2.357 1.967 4.386 2.555 4.953.693.666 1.05 1.486 1.004 2.31m5.47-7.32c-.76-2.354-1.692-3.452-2.23-3.924a.241.241 0 00-.4.198c.157 2.54-.799 3.184-1.343 1.731-.217-.58-.344-1.583-.344-2.804 0-2.034-.59-2.965-1.892-4.667A6.603 6.603 0 005.938.051a.241.241 0 00-.387.208c.1 1.379.009 4.168-3.46 8.889C-1.055 13.527.164 16.89.597 17.8c.827 1.745 1.98 2.77 3.107 3.368a.237.237 0 00.136.028c.895.432 1.963.683 3.11.683 1.45 0 2.773-.4 3.78-1.06 1.522-.945 3.045-2.65 3.235-5.717.079-1.05-.04-2.522-.629-4.379\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "22",
      "viewBox": "0 0 14 22"
    }
  };
  _exports.default = _default;
});