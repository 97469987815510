define("ember-svg-jar/inlined/copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M15.773 0H6.335c-.673 0-1.22.545-1.22 1.215v2.85h1.113v-2.85c0-.059.048-.106.107-.106h9.438c.058 0 .106.047.106.106v9.397a.106.106 0 01-.106.105h-2.774v1.11h2.774c.672 0 1.22-.546 1.22-1.215V1.215c0-.67-.548-1.215-1.22-1.215z\"/><path d=\"M10.665 5.174H1.227c-.672 0-1.22.545-1.22 1.214v9.397c0 .67.548 1.215 1.22 1.215h9.438c.673 0 1.22-.545 1.22-1.215V6.388c0-.67-.547-1.214-1.22-1.214zm0 10.717H1.227a.106.106 0 01-.106-.106V6.388c0-.058.048-.105.106-.105h9.438c.06 0 .107.047.107.105v9.397a.106.106 0 01-.107.106z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17"
    }
  };
  _exports.default = _default;
});