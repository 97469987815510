define("ember-bulk-manager/components/bulk-import-prefill", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/bulk-import-prefill", "ember-bulk-manager/utils/bulk-errors"], function (_exports, _bulkComponent, _bulkImportPrefill, _bulkErrors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A wrapper component for providing an interface to predefine default values before
   * forwarding import data to `bulk-grid` component. The component operates directly
   * with `default` property of `availableFields`, which is also used by `bulk-grid`
   * to show defaults and thus reflected in resulting output data from table. Per
   * default, all fields, which are not mapped or empty will show up. Display as
   * well as type specific information can be provided via `prefillOptions` in
   * `availableFields`. Please refer to `bulk-lookup-data` for full
   * documentation of these settings.
   *
   * ```html
   * {{bulk-import-prefill
   *     template=template
   *     onChange=(action "onChange")
   *     availableFields=availableFields}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(availableField,value)
   *
   *
   * Note: The component doesn't use block content.
   *
   * @namespace Component
   * @class BulkImportPrefill
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkImportPrefill.default,
    classNames: ['bulk-import-prefill'],
    // -------------------------------------------------------------------------
    // Dependencies
    bulkTemplate: Ember.inject.service(),
    bulkSpreadsheet: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current template for prefilling process.
     *
     * @attribute template
     * @type {Object}
     * @default null
     * @public
     */
    template: null,

    /**
     * Available fields for prefilling process. This fields should be defined
     * in extended `bulk-lookup-data` service and must at least contain a
     * `value` and `label` property. The component further makes use of
     * `default` and dedicated `prefillOptions` configuration. Please
     * refer to `bulk-lookup-data` for full documentation.
     *
     * @attribute availableFields
     * @type {Object}
     * @default {}
     */
    availableFields: Ember.computed(function () {
      return {};
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Alias to `data` from last import result of `bulk-spreadsheet`.
     *
     * @property fields
     * @type {Array}
     * @public
     */
    importData: Ember.computed.reads('bulkSpreadsheet.lastImport.data'),

    /**
     * Computes a hash with empty columns which will be automatically skipped
     * unless the `force` option set to true within `prefillOptions` hash.
     *
     * @property emptyFields
     * @type {Object}
     * @public
     */
    emptyFields: Ember.computed('importData.[]', 'template.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'template.mappings');
        var importData = Ember.get(this, 'importData');
        var emptyFields = {};

        if (Ember.isNone(importData)) {
          return emptyFields;
        }

        if (Ember.isNone(mappings)) {
          return emptyFields;
        }

        var totalRows = importData.length;

        for (var i = 0; i < totalRows; i++) {
          var row = importData[i];

          for (var key in row) {
            var mappedKey = mappings[key];

            if (Ember.isNone(mappedKey)) {
              continue;
            }

            var count = emptyFields[mappedKey];

            if (!Ember.isBlank(row[key]) && !count) {
              emptyFields[mappedKey] = false;
              continue;
            }

            count = count ? count + 1 : 1;
            emptyFields[mappedKey] = count;
          }
        }

        return emptyFields;
      }
    }),

    /**
     * Computes showable fields depending on `emptyFields`, `skip` and `force`
     * options provided within `prefillOptions` hash from availableFields.
     *
     * @property filteredFields
     * @type {Array}
     * @public
     */
    filteredFields: Ember.computed('availableFields', 'emptyFields', {
      get: function get()
      /* key*/
      {
        var availableFields = Ember.get(this, 'availableFields');
        var emptyFields = Ember.get(this, 'emptyFields');
        var filteredFields = [];

        var _processField = function _processField(field) {
          // `skip` field manually set in options
          if (Ember.get(field, 'prefillOptions.skip')) {
            return;
          } // `skip` fields which are mapped and
          // have a value defined in input data


          var value = Ember.get(field, 'value');

          if (emptyFields.hasOwnProperty(value)) {
            if (Ember.get(emptyFields, value) === false) {
              return;
            }
          }

          filteredFields.push(field);
        };

        switch (Ember.typeOf(availableFields)) {
          case 'object':
            {
              for (var key in availableFields) {
                _processField(availableFields[key]);
              }

              break;
            }

          case 'array':
            {
              availableFields.forEach(_processField);
              break;
            }

          default:
            {
              throw new _bulkErrors.WrongParameterTypeError(Ember.get(this, 'l10n').t('`availableFields` parameter has to be an array or object!'));
            }
        }

        return filteredFields;
      }
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `default` property on `availableField` with new value.
       *
       * @event onChange
       * @param {Object} availableField
       * @param {Mixed} value
       * @private
       */
      onChange: function onChange(availableField, value) {
        Ember.set(availableField, 'default', value);
        this.invokeCallback('onChange', availableField, value);
      }
    }
  });

  _exports.default = _default;
});