define("ember-bulk-manager/utils/bulk-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Base class for all components from addon.
   * It provides generic method for callbacks.
   *
   * @abstract
   * @namespace Utils
   * @class BulkComponent
   */
  var _default = Ember.Component.extend({
    /**
     * Tries to invoke a callback on `attrs` with params.
     *
     * @public
     * @method invokeCallback
     * @param {String} callbackMethod
     *
     * @return {Void}
     */
    invokeCallback: function invokeCallback(callbackName) {
      try {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return Ember.get(this, callbackName).apply(void 0, args);
      } catch (exception) {
        if (!Ember.isNone(Ember.get(this, callbackName))) {
          // eslint-disable-next-line no-console
          console.error("bulk-component.js: invokeCallback() exception: ".concat(exception.message));
        }

        return null;
      }
    }
  });

  _exports.default = _default;
});