define("ember-bulk-manager/components/sub/bulk-progress-bar", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-progress-bar"], function (_exports, _bulkComponent, _bulkProgressBar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Utils = Ember.Handlebars.Utils;
  /**
   * A generic progress bar displaying current progress on various task.
   *
   * ```html
   * {{#sub/bulk-progress-bar progress=progress}}
   *   {{!-- Optional block content --}}
   * {{/sub/bulk-progress-bar}}
   * ```
   *
   * @namespace Component
   * @class BulkProgressBar
   * @extends Ember
   * @public
   */

  var _default = _bulkComponent.default.extend({
    layout: _bulkProgressBar.default,
    classNames: ['bulk-progress-bar'],
    classNameBindings: ['isDone:bulk-progress-bar--done'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current progress of in percent.
     *
     * @attribute progress
     * @type {Number}
     * @public
     */
    progress: 0,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Bindable style attribute for bar.
     *
     * @property style
     * @type {String}
     * @public
     */
    style: Ember.computed('progress', {
      get: function get()
      /* key*/
      {
        var escaped = Utils.escapeExpression(Ember.get(this, 'progress'));
        return Ember.String.htmlSafe("width: ".concat(escaped, "%"));
      }
    }),

    /**
     * Computes finished state depending on `progress`.
     *
     * @property isDone
     * @type {Boolean}
     * @public
     */
    isDone: Ember.computed.gte('progress', 100)
  });

  _exports.default = _default;
});