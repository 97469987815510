define("@vollersgroup/ember-cropster-common/utils/promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPromise = isPromise;
  _exports.resolveIfPromise = resolveIfPromise;
  _exports.default = void 0;

  function isPromise(val) {
    return val && Ember.typeOf(val.then) === 'function';
  }

  function resolveIfPromise(val) {
    return isPromise(val) ? val : Ember.RSVP.Promise.resolve(val);
  }

  var _default = {
    isPromise: isPromise,
    resolveIfPromise: resolveIfPromise
  };
  _exports.default = _default;
});