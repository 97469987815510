define('ember-prism/components/code-block', ['exports', 'ember-prism/components/code-base'], function (exports, _codeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _codeBase.default.extend({
    tagName: 'pre',
    classNames: ['code-block'],

    getElement: function getElement() {
      return this.element.querySelector('[class*=language-]');
    }
  });
});