define("ember-svg-jar/inlined/blacklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 .074h49.759v45.121H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FEFEFE\" d=\"M18.626 44h13.261V12H18.626z\"/><g transform=\"translate(0 2)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"#F3CA3E\" d=\"M27.408 20.551c-.122 2.15-.256 4.292-.378 6.443-.062.696-.062 1.331-.062 2.017-.061 1.137-.952 2.018-2.09 2.018-1.136 0-2.017-.82-2.089-1.956-.184-3.35-.379-6.637-.563-9.987-.061-.88-.123-1.772-.195-2.652 0-1.455.82-2.653 2.151-3.032a2.784 2.784 0 013.226 1.577c.195.44.256.881.256 1.393-.061 1.404-.194 2.796-.256 4.18m-2.53 17.944a2.798 2.798 0 01-2.775-2.776 2.798 2.798 0 012.776-2.776 2.725 2.725 0 012.714 2.848c.071 1.444-1.26 2.704-2.714 2.704m23.885-4.486L31.331 3.815c-2.847-4.988-10.048-4.988-12.885 0L1.004 34.01c-2.848 4.988.696 11.185 6.442 11.185H42.26c5.746 0 9.352-6.259 6.504-11.185\" mask=\"url(#b)\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "50",
      "height": "50",
      "viewBox": "0 0 50 50"
    }
  };
  _exports.default = _default;
});