define("@vollersgroup/ember-cropster-common/components/dropdown-menu/component", ["exports", "@vollersgroup/ember-cropster-common/components/dropdown-menu/template", "@vollersgroup/ember-cropster-common/utils/dom/event-listener"], function (_exports, _template, _eventListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A dropdown menu. Can contain arbitrary content.
  
   * @namespace Component
   * @class DropdownMenu
   * @extends Ember.Component
   * @public
   * @example
   * ```handlebars
   * <div class="align-right">
   *   {{#dropdown-menu}}
   *     My dropdown content.
   *   {{/dropdown-menu}}
   * </div>
   * ```
   * @example
   * ```handlebars
   * <div class="align-right">
   *   {{#dropdown-menu buttonText="Options" autoClose=false alignLeft=true}}
   *     Do not close on inside click.
   *   {{/dropdown-menu}}
   * </div>
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['dropdown-menu__content', 'position-relative'],
    classNameBindings: ['parsedTooltip:tooltip-info'],
    attributeBindings: ['parsedTooltip:data-tooltip'],

    /**
     * A string of classes to add to the dropdown container.
     *
     * @attribute dropdownClass
     * @type {String}
     * @public
     */
    dropdownClass: null,

    /**
     * If true, align the dropdown left instead of right.
     *
     * @attribute alignLeft
     * @type {Boolean}
     * @default false
     * @public
     */
    alignLeft: false,

    /**
     * A string of classes to add to the toggle button.
     *
     * @attribute buttonClass
     * @type {String}
     * @public
     */
    buttonClass: null,

    /**
     * An svg-icon name to add to the dropdown.
     *
     * @attribute buttonIcon
     * @type {String}
     * @public
     */
    buttonIcon: null,

    /**
     * An optional class to add to the svg icon.
     *
     * @attribute buttonIconClass
     * @type {String}
     * @public
     */
    buttonIconClass: null,

    /**
     * The text to show in the button.
     *
     * @attribute buttonText
     * @type {String}
     * @default 'Open'
     * @public
     */
    buttonText: 'Open',

    /**
     * If set, the dropdown button will get this text as a tooltip.
     *
     * @attribute tooltip
     * @type {Text}
     * @optional
     * @public
     */
    tooltip: null,

    /**
     * If true, the menu is shown.
     *
     * @attribute isOpen
     * @type {Boolean}
     * @default false
     * @public
     */
    isOpen: false,

    /**
     * If this is true, close the dropdown after clicking inside.
     *
     * @attribute autoClose
     * @type {Boolean}
     * @default true
     * @public
     */
    autoClose: true,

    /**
     * If true, close the dropdown if the user clicks outside of it.
     *
     * @attribute autoCloseOutside
     * @type {Boolean}
     * @default true
     * @public
     */
    autoCloseOutside: true,

    /**
     * The tooltip should only be shown if the dropdown is not open.
     *
     * @property parsedTooltip
     * @type {String}
     * @readOnly
     * @protected
     */
    parsedTooltip: Ember.computed('isOpen', 'tooltip', function () {
      if (Ember.get(this, 'isOpen')) {
        return null;
      }

      return Ember.get(this, 'tooltip');
    }),
    _addEventListeners: function _addEventListeners() {
      var _this = this;

      if (!Ember.get(this, 'autoCloseOutside')) {
        return;
      }

      var el = this.element;
      (0, _eventListener.addEventListener)(document.body, 'click', this.elementId, function (e) {
        var target = e.target;

        if (Ember.get(_this, 'autoClose') || !el.contains(target)) {
          _this._close();
        }
      });
    },
    _removeEventListeners: function _removeEventListeners() {
      if (!Ember.get(this, 'autoCloseOutside')) {
        return;
      }

      (0, _eventListener.removeEventListener)(document.body, 'click', this.elementId);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (Ember.get(this, 'isOpen')) {
        this._addEventListeners();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._removeEventListeners();
    },
    _close: function _close() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      this.set('isOpen', false);

      this._removeEventListeners();
    },
    _open: function _open() {
      this.set('isOpen', true);

      this._addEventListeners();
    },
    actions: {
      toggle: function toggle(e) {
        e.stopPropagation();
        e.preventDefault();

        if (Ember.get(this, 'isOpen')) {
          this._close();
        } else {
          this._open();
        }
      },
      close: function close() {
        this._close();
      },
      open: function open() {
        this._open();
      }
    }
  });

  _exports.default = _default;
});