define("ember-svg-jar/inlined/download-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#000\" d=\"M0 13.125h15V15H0zM7.5 12.188L12.188 7.5H9.375V0h-3.75v7.5H2.813z\"/><rect width=\"22\" height=\"12\" x=\"17\" y=\"1\" fill=\"#D05606\" rx=\"2\"/><path fill=\"#FFF\" d=\"M22.77 8.05V10h-1.325V4.312h2.309c.674 0 1.21.173 1.605.518.396.345.594.796.594 1.354 0 .56-.198 1.01-.594 1.353-.395.343-.93.514-1.605.514h-.984zm0-1.015h.984c.286 0 .504-.079.654-.236a.846.846 0 00.225-.608.877.877 0 00-.225-.62c-.15-.162-.368-.243-.654-.243h-.984v1.707zM26.695 10V4.312h2.055c.67 0 1.23.219 1.68.655.45.436.675.995.675 1.678v1.023c0 .685-.225 1.245-.675 1.68-.45.435-1.01.652-1.68.652h-2.055zm1.325-4.672v3.656h.64c.352 0 .626-.12.824-.361.198-.24.297-.56.297-.955V6.637c0-.394-.099-.71-.297-.95-.198-.24-.472-.359-.824-.359h-.64zm7.503 2.383h-2.277V10h-1.324V4.312h4v1.016h-2.676v1.367h2.277v1.016z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "39",
      "height": "15",
      "viewBox": "0 0 39 15"
    }
  };
  _exports.default = _default;
});