define("ember-svg-jar/inlined/availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M79.042 152.053h64.095v55.234H79.042zM157.708 152.053h64.095v55.234h-64.095zM.386 152.053h64.095v55.234H.386zM79.042 76.289h64.095v55.234H79.042zM.386 76.289h64.095v55.234H.386zM157.708 76.289h64.095v55.234h-64.095zM79.042.537h64.095v55.234H79.042zM.386.537h64.095v55.234H.386z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "222",
      "height": "208",
      "viewBox": "0 0 222 208"
    }
  };
  _exports.default = _default;
});