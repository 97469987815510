define("ember-svg-jar/inlined/camera", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M30 15.5c-6.617 0-12 5.383-12 12s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12z\"/><path d=\"M55.201 10.5h-8.524l-4-10H17.323l-4 10H12v-5H6v5H4.799A4.803 4.803 0 000 15.299v29.368A4.838 4.838 0 004.833 49.5h50.334A4.838 4.838 0 0060 44.667V15.299a4.803 4.803 0 00-4.799-4.799zM10 10.5H8v-3h2v3zm20 35c-9.925 0-18-8.075-18-18s8.075-18 18-18 18 8.075 18 18-8.075 18-18 18zm22-23c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "60",
      "height": "50",
      "viewBox": "0 0 60 50"
    }
  };
  _exports.default = _default;
});