define("ember-svg-jar/inlined/trim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M18 1.5L16.5 0l-3 3H6V0H3v3H0v3h3v9h9v3h3v-3h3v-3h-3V4.5l3-3zM6 6h4l-4 4V6zm6 6H8l4-4v4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});