define("ember-svg-jar/inlined/split", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M16 10l-2.3 2.3-2.9-2.9-1.4 1.4 2.9 2.9L10 16h6zM16 6V0h-6l2.3 2.3L7.6 7H0v2h8.4l5.3-5.3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});