define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M0 1.405l4.392 4.447 1.423-1.405L1.423 0z\"/><path d=\"M1.423 8.852l4.392-4.447L4.392 3 0 7.447z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "6",
      "height": "9",
      "viewBox": "0 0 6 9"
    }
  };
  _exports.default = _default;
});