define("@vollersgroup/ember-cropster-common/mixins/bread-crumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Use this mixin on routes that should appear in the breadcrumbs.
   *
   * Specify a `breadCrumb` property on the route.
   * This property should return an object:
   *
   * ```js
   * breadCrumb: computed(function() {
   *  return {
   *    title: 'Page Title',
   *    route: 'route-name',
   *    model: myModel
   *  };
   * })
   * ```
   *
   * Model and route will default to the current route/model if not given.
   * Note that when using a model, you'll probably want to ensure it is added here in the afterModel hook:
   *
   * ```js
   * afterModel(model) {
   *   let breadCrumb = get(this, 'breadCrumb');
   *   set(breadCrumb, 'model', model);
   * }
   * ```
   *
   * @namespace Mixin
   * @class BreadCrumbs
   * @extends Ember.Mixin
   * @public
   */
  // eslint-disable-next-line ember/no-new-mixins
  var _default = Ember.Mixin.create({
    breadCrumbs: Ember.inject.service(),
    breadCrumb: null,
    _breadCrumb: null,
    breadCrumbProperty: Ember.computed.alias('_breadCrumb'),
    _autoUpdateBreadCrumb: Ember.observer('breadCrumb', 'breadCrumb.{model,route,title}', function () {
      Ember.run.debounce(this, this._updateBreadCrumb, 100);
    }),
    _initBreadCrumb: function _initBreadCrumb() {
      var breadCrumb = Ember.Object.create({});
      Ember.set(this, '_breadCrumb', breadCrumb);
      return breadCrumb;
    },
    _updateBreadCrumb: function _updateBreadCrumb() {
      var breadCrumb = Ember.get(this, '_breadCrumb');
      var data = Ember.get(this, 'breadCrumb');

      if (!breadCrumb) {
        breadCrumb = this._initBreadCrumb();
      }

      if (!data || Ember.get(this, 'isDestroyed')) {
        return null;
      }

      if (Ember.typeOf(Ember.get(data, 'title')) !== 'undefined') {
        Ember.set(breadCrumb, 'title', Ember.get(data, 'title'));
      }

      if (Ember.typeOf(Ember.get(data, 'route')) !== 'undefined') {
        Ember.set(breadCrumb, 'route', Ember.get(data, 'route'));
      }

      if (Ember.typeOf(Ember.get(data, 'model')) !== 'undefined') {
        Ember.set(breadCrumb, 'model', Ember.get(data, 'model'));
      }

      if (Ember.typeOf(Ember.get(breadCrumb, 'route')) === 'undefined') {
        Ember.set(breadCrumb, 'route', Ember.get(this, 'routeName'));
      }

      return breadCrumb;
    },
    activate: function activate() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.next(this, function () {
        _this._initBreadCrumb();

        var breadCrumbs = Ember.get(_this, 'breadCrumbs');
        var breadCrumb = Ember.get(_this, '_breadCrumb');

        if (breadCrumb) {
          breadCrumbs.add(breadCrumb);
        }
      });
      Ember.run.debounce(this, this._updateBreadCrumb, 100);
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      var breadCrumbs = Ember.get(this, 'breadCrumbs');
      var breadCrumb = Ember.get(this, '_breadCrumb');

      if (breadCrumb) {
        breadCrumbs.remove(breadCrumb);
      }
    }
  });

  _exports.default = _default;
});