define("ember-bulk-manager/templates/sub/bulk-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8IHXx58a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[23,[\"wormholeElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"tooltipClass\"],[27,\"if\",[[23,[\"isTop\"]],\" bulk-tooltip--top\"],null],[27,\"if\",[[23,[\"isLeft\"]],\" bulk-tooltip--left\"],null],[27,\"if\",[[23,[\"isRight\"]],\" bulk-tooltip--right\"],null],[27,\"if\",[[23,[\"isBottom\"]],\" bulk-tooltip--bottom\"],null],[27,\"if\",[[23,[\"isVisible\"]],\" bulk-tooltip--visible\"],null],[27,\"if\",[[23,[\"isCloseable\"]],\" bulk-tooltip--closable\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bulk-tooltip-text\"],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isCloseable\"]]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"javascript:void(0)\"],[11,\"class\",\"bulk-tooltip-close\"],[9],[0,\"\\n        ×\\n      \"],[3,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"isVisible\"]]],null],false]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/sub/bulk-tooltip.hbs"
    }
  });

  _exports.default = _default;
});