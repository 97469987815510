define("ember-svg-jar/inlined/crown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.063 4.05L7 0 3.938 4.05 0 0l.875 9h12.25L14 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "9",
      "viewBox": "0 0 14 9"
    }
  };
  _exports.default = _default;
});