define("ember-svg-jar/inlined/download-pdf-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#000\" d=\"M0 13.125h15V15H0zM7.5 12.188L12.188 7.5H9.375V0h-3.75v7.5H2.813z\"/><rect width=\"41\" height=\"12\" x=\"17\" y=\"1\" fill=\"#AD1D01\" rx=\"2\"/><path fill=\"#FFF\" d=\"M22.77 8.05V10h-1.325V4.312h2.309c.674 0 1.21.173 1.605.518.396.345.594.796.594 1.354 0 .56-.198 1.01-.594 1.353-.395.343-.93.514-1.605.514h-.984zm0-1.015h.984c.286 0 .504-.079.654-.236a.846.846 0 00.225-.608.877.877 0 00-.225-.62c-.15-.162-.368-.243-.654-.243h-.984v1.707zM26.695 10V4.312h2.055c.67 0 1.23.219 1.68.655.45.436.675.995.675 1.678v1.023c0 .685-.225 1.245-.675 1.68-.45.435-1.01.652-1.68.652h-2.055zm1.325-4.672v3.656h.64c.352 0 .626-.12.824-.361.198-.24.297-.56.297-.955V6.637c0-.394-.099-.71-.297-.95-.198-.24-.472-.359-.824-.359h-.64zm7.503 2.383h-2.277V10h-1.324V4.312h4v1.016h-2.676v1.367h2.277v1.016zm6.008.781c0-.2-.066-.355-.199-.465-.133-.109-.371-.225-.715-.347-.719-.227-1.251-.47-1.597-.729-.347-.259-.52-.633-.52-1.123 0-.476.206-.862.62-1.156.412-.294.936-.442 1.571-.442.63 0 1.147.163 1.55.487.402.324.596.741.583 1.252l-.008.023h-1.28a.718.718 0 00-.231-.553c-.154-.141-.366-.212-.637-.212-.266 0-.474.058-.625.175a.53.53 0 00-.227.434c0 .167.077.3.229.4.152.1.438.226.857.377.646.193 1.133.43 1.46.711.326.281.49.668.49 1.16 0 .5-.195.892-.584 1.174-.39.283-.906.424-1.55.424-.645 0-1.204-.162-1.675-.486-.471-.325-.7-.791-.688-1.4l.008-.024h1.285c0 .328.089.562.266.703.177.14.445.21.805.21.273 0 .477-.054.611-.163a.528.528 0 00.201-.43zm6.7-4.18V8.04c0 .649-.216 1.151-.647 1.508-.431.357-.995.535-1.693.535-.706 0-1.275-.178-1.707-.535-.433-.357-.649-.86-.649-1.508V4.313h1.324v3.726c0 .349.09.607.27.775.18.168.433.252.762.252.325 0 .576-.083.752-.25.175-.166.263-.425.263-.777V4.313h1.324zm2.601 0l1.273 3.981h.024l1.289-3.98h1.742V10h-1.324V8.59l.125-2.598-.023-.004L52.566 10h-.89L50.32 6.023l-.023.004.125 2.563V10h-1.324V4.312h1.734z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "58",
      "height": "15",
      "viewBox": "0 0 58 15"
    }
  };
  _exports.default = _default;
});