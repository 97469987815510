define("ember-svg-jar/inlined/coffee-bean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M13.741 1.472c-.158-.18-.35-.316-.527-.472a5.799 5.799 0 00-1.647.785C9.02 3.569 8.491 6.935 8.39 7.924c-.188 1.848-1.154 3.384-2.719 4.324-.773.463-1.677.748-2.67.89 2.554 1.532 6.27 1.068 9.07-1.385 3.336-2.924 4.085-7.527 1.671-10.28\"/><path d=\"M1.157 12.214c1.355.029 2.532-.22 3.459-.777 1.143-.686 1.847-1.811 1.985-3.169.119-1.152.744-5.079 3.824-7.235.3-.21.616-.388.94-.55-2.494-1.025-5.774-.415-8.3 1.798-3.206 2.81-3.99 7.153-1.908 9.933\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "14",
      "viewBox": "0 0 16 14"
    }
  };
  _exports.default = _default;
});