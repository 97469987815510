define("ember-svg-jar/inlined/remove-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>remove-color</title><path d=\"M4.158.158L.221.118v1.75l3.937-.039H5.83l3.938.04V.119l-3.938.04z\" fill=\"#AD1D02\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "10",
      "height": "2",
      "viewBox": "0 0 10 2",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});