define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M5.564 10.15l.05.05L4.2 11.614 0 7.414 1.414 6 4.15 8.736l8.1-8.1 1.414 1.414-8.1 8.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "12",
      "viewBox": "0 0 14 12"
    }
  };
  _exports.default = _default;
});