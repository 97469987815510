define("ember-svg-jar/inlined/switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M13.642 3.766H1.436v1.741h12.206v3.046l3.891-3.917L13.642.72zM4.192 13.059h12.206v-1.741H4.192V8.272L.301 12.188l3.891 3.917z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17"
    }
  };
  _exports.default = _default;
});