define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M3.5 6.388c.525.875 1.225.7 1.225 1.312 0 .525-.612.7-1.225.788-.962 0-1.837-.175-2.712 1.4C.262 10.85 0 14 0 14h11.988s-.263-3.15-.7-4.113c-.876-1.662-1.75-1.4-2.713-1.487-.612-.088-1.225-.263-1.225-.788 0-.524.7-.35 1.225-1.312 0 .088 2.363-6.3-2.537-6.3S3.5 6.388 3.5 6.388z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14"
    }
  };
  _exports.default = _default;
});