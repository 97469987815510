define("ember-svg-jar/inlined/moisture", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.187 3.21A40.454 40.454 0 005.652.11a.34.34 0 00-.5 0 40.478 40.478 0 00-2.535 3.1C.907 5.538.039 7.346.039 8.583.04 11.57 2.445 14 5.402 14c2.956 0 5.362-2.43 5.362-5.416 0-1.237-.867-3.045-2.577-5.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "14",
      "viewBox": "0 0 11 14"
    }
  };
  _exports.default = _default;
});