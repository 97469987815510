define("ember-tooltips/components/ember-tooltip-base", ["exports", "tooltip.js", "ember-tooltips/templates/components/ember-tooltip-base"], function (_exports, _tooltip2, _emberTooltipBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var ANIMATION_CLASS = 'ember-tooltip-show';
  var POPPER_DEFAULT_MODIFIERS = {
    flip: {
      enabled: true
    },
    preventOverflow: {
      escapeWithReference: true
    }
  };

  function getOppositeSide(placement) {
    if (!placement) {
      return null;
    }

    var _placement$split = placement.split('-'),
        _placement$split2 = _slicedToArray(_placement$split, 1),
        side = _placement$split2[0];

    var oppositeSide;

    switch (side) {
      case 'top':
        oppositeSide = 'bottom';
        break;

      case 'right':
        oppositeSide = 'left';
        break;

      case 'bottom':
        oppositeSide = 'top';
        break;

      case 'left':
        oppositeSide = 'right';
        break;
    }

    return oppositeSide;
  }

  function cleanNumber(stringOrNumber) {
    var cleanNumber;

    if (stringOrNumber && typeof stringOrNumber === 'string') {
      cleanNumber = parseInt(stringOrNumber, 10);
      /* Remove invalid parseInt results */

      if (isNaN(cleanNumber) || !isFinite(cleanNumber)) {
        cleanNumber = 0;
      }
    } else {
      cleanNumber = stringOrNumber;
    }

    return cleanNumber;
  }

  var _default = Ember.Component.extend({
    classNames: ['ember-tooltip-base'],
    delay: 0,
    delayOnChange: true,
    duration: 0,
    effect: 'slide',
    // Options: fade, slide, none // TODO - make slide work
    event: 'hover',
    // Options: hover, click, focus, none
    tooltipClass: 'tooltip',
    arrowClass: 'tooltip-arrow',
    innerClass: 'tooltip-inner',
    tooltipClassName: Ember.computed.deprecatingAlias('_tooltipVariantClass', {
      id: 'EmberTooltipBase._tooltipVariantClass',
      for: 'ember-tooltips',
      since: {
        enabled: '3.3.0'
      },
      until: '4.0.0'
    }),
    isShown: false,
    text: null,
    side: 'top',
    spacing: 10,
    targetId: null,
    targetElement: null,
    layout: _emberTooltipBase.default,
    updateFor: null,
    popperOptions: null,
    popperContainer: false,
    animationDuration: 200,

    /* Actions */
    onDestroy: null,
    onHide: null,
    onRender: null,
    onShow: null,
    _hideOn: null,
    // eslint-disable-next-line ember/require-computed-property-dependencies
    hideOn: Ember.computed('event', {
      get: function get() {
        if (this.get('_hideOn')) {
          return this.get('_hideOn');
        }

        var event = this.get('event');
        var hideOn;

        switch (event) {
          case 'hover':
            hideOn = 'mouseleave';
            break;

          case 'focus':
            hideOn = 'blur';
            break;

          case 'ready':
            hideOn = null;
            break;

          default:
            hideOn = event;
            break;
        }

        return hideOn;
      },
      set: function set(_key, value) {
        return this._hideOn = value;
      }
    }),
    _showOn: null,
    // eslint-disable-next-line ember/require-computed-property-dependencies
    showOn: Ember.computed('event', {
      get: function get() {
        if (this.get('_showOn')) {
          return this.get('_showOn');
        }

        var event = this.get('event');
        var showOn;

        switch (event) {
          case 'hover':
            showOn = 'mouseenter';
            break;

          default:
            showOn = event;
            break;
        }

        return showOn;
      },
      set: function set(_key, value) {
        return this._showOn = value;
      }
    }),
    // eslint-disable-next-line ember/require-computed-property-dependencies
    target: Ember.computed('targetId', 'targetElement', function () {
      var targetId = this.get('targetId');
      var target;

      if (targetId) {
        target = document.getElementById(targetId);

        if (!target) {
          (false && Ember.warn('No target found for targetId ', targetId, {
            id: 'ember-tooltips.no-element-with-targetId'
          }));
        }
      } else {
        target = this.get('targetElement') || this.element.parentNode;
      }

      return target;
    }),

    /* An ID used to identify this tooltip from other tooltips */
    _renderElementId: Ember.computed('elementId', function () {
      var elementId = this.get('elementId');

      if (elementId) {
        return "".concat(elementId, "-et-target");
      } else {
        return null;
      }
    }),
    _renderElement: Ember.computed('_renderElementId', function () {
      var renderElementId = this.get('_renderElementId');

      if (renderElementId) {
        return document.getElementById(renderElementId);
      } else {
        return null;
      }
    }),
    _fastboot: Ember.computed(function () {
      var owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    _shouldRenderContent: Ember.computed('_fastboot.isFastBoot', '_awaitingTooltipElementRendered', function () {
      return this.get('_fastboot.isFastBoot') || !this.get('_awaitingTooltipElementRendered');
    }),
    _awaitingTooltipElementRendered: true,
    _tooltipEvents: null,
    _tooltip: null,
    _spacingRequestId: null,
    _animationDuration: Ember.computed('animationDuration', function () {
      var config = Ember.getOwner(this).resolveRegistration('config:environment');
      var inTestingMode = config.environment === 'test';
      return inTestingMode ? 0 : this.get('animationDuration');
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_tooltipEvents', []);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.createTooltip();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      if (this.get('isShown')) {
        this.show();
        /* If updateFor exists, update the tooltip incase the changed Attr affected the tooltip content's height or width */

        if (this.get('updateFor') !== null && this.get('_tooltip').popperInstance) {
          this._updatePopper();
        }
      } else {
        this.hide();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var _tooltipEvents = this.get('_tooltipEvents');
      /* Remove event listeners used to show and hide the tooltip */


      _tooltipEvents.forEach(function () {
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
            callback = _ref.callback,
            target = _ref.target,
            eventName = _ref.eventName;

        target.removeEventListener(eventName, callback);
      });

      this._cleanupTimers();

      this.get('_tooltip').dispose();

      this._dispatchAction('onDestroy', this);
    },
    addTargetEventListeners: function addTargetEventListeners() {
      this.addTooltipTargetEventListeners();
    },
    addTooltipBaseEventListeners: function addTooltipBaseEventListeners() {},
    addTooltipTargetEventListeners: function addTooltipTargetEventListeners() {
      var _this = this;

      /* Setup event handling to hide and show the tooltip */
      var event = this.get('event');
      /* Setup event handling to hide and show the tooltip */

      if (event === 'none') {
        return;
      }

      var hideOn = this.get('hideOn');
      var showOn = this.get('showOn');
      /* If show and hide are the same (e.g. click) toggle
      the visibility */

      if (showOn === hideOn) {
        this._addEventListener(showOn, function () {
          _this.toggle();
        });
      } else {
        /* Else, add the show and hide events individually */
        if (showOn !== 'none') {
          this._addEventListener(showOn, function () {
            _this.show();
          });
        }

        if (hideOn !== 'none') {
          this._addEventListener(hideOn, function () {
            _this.hide();
          });
        }
      }
      /* Hide and show the tooltip on focus and escape
      for accessibility */


      if (event !== 'focus') {
        /* If the event is click, we don't want the
        click to also trigger focusin */
        if (event !== 'click') {
          this._addEventListener('focusin', function () {
            _this.show();
          });
        }

        this._addEventListener('focusout', function () {
          _this.hide();
        });
      }

      this._addEventListener('keydown', function (keyEvent) {
        if (keyEvent.which === 27 && _this.get('isShown')) {
          _this.hide();

          keyEvent.stopImmediatePropagation();
          /* So this callback only fires once per keydown */

          keyEvent.preventDefault();
          return false;
        }
      }, document);
    },
    createTooltip: function createTooltip() {
      var _this2 = this;

      var target = this.get('target');
      var tooltipClass = this.get('tooltipClass');
      var arrowClass = this.get('arrowClass');
      var innerClass = this.get('innerClass');
      var emberTooltipClass = this.get('_tooltipVariantClass');
      var emberTooltipArrowClass = "".concat(Ember.String.w(emberTooltipClass).join('-arrow '), "-arrow");
      var emberTooltipInnerClass = "".concat(Ember.String.w(emberTooltipClass).join('-inner '), "-inner");
      var targetTitle = target.title;
      target.removeAttribute('title');
      var tooltip = new _tooltip2.default(target, {
        container: this.get('popperContainer'),
        html: true,
        placement: this.get('side'),
        title: '<span></span>',
        trigger: 'manual',
        arrowSelector: ".".concat(Ember.String.w(emberTooltipArrowClass).join('.')),
        innerSelector: ".".concat(Ember.String.w(emberTooltipInnerClass).join('.')),
        // eslint-disable prettier/prettier
        // prettier-ignore
        template: "<div\n                   class=\"".concat(tooltipClass, " ").concat(emberTooltipClass, " ember-tooltip-effect-").concat(this.get('effect'), "\"\n                   role=\"tooltip\"\n                   style=\"margin:0;margin-").concat(getOppositeSide(this.get('side')), ":").concat(this.get('spacing'), "px;\">\n                   <div class=\"").concat(arrowClass, " ").concat(emberTooltipArrowClass, "\"></div>\n                   <div class=\"").concat(innerClass, " ").concat(emberTooltipInnerClass, "\" id=\"").concat(this.get('_renderElementId'), "\"></div>\n                 </div>"),
        // eslint-enable prettier/prettier
        popperOptions: {
          modifiers: mergeModifiers(POPPER_DEFAULT_MODIFIERS, this.get('popperOptions.modifiers')),
          onCreate: function onCreate() {
            Ember.run(function () {
              _this2._dispatchAction('onRender', _this2);

              _this2.set('_awaitingTooltipElementRendered', false);
              /* The tooltip element must exist in order to add event listeners to it */


              _this2.addTooltipBaseEventListeners();
              /* Once the wormhole has done it's work, we need the tooltip to be positioned again */


              Ember.run.scheduleOnce('afterRender', _this2, _this2._updatePopper);
              target.setAttribute('title', targetTitle);
            });
          },
          onUpdate: function onUpdate() {
            _this2.setSpacing();
          }
        }
      });
      /* Add a class to the tooltip target */

      target.classList.add('ember-tooltip-target');
      this.addTargetEventListeners();
      this.set('_tooltip', tooltip);
      /* If user passes isShown=true, show the tooltip as soon as it's created */

      if (this.get('isShown')) {
        this.show();
      }
    },
    _updatePopper: function _updatePopper() {
      var _this$get = this.get('_tooltip'),
          popperInstance = _this$get.popperInstance;

      popperInstance.update();
    },
    setSpacing: function setSpacing() {
      var _this3 = this;

      if (!this.get('isShown') || this.get('isDestroying')) {
        return;
      }

      this._spacingRequestId = requestAnimationFrame(function () {
        _this3._spacingRequestId = null;

        if (!_this3.get('isShown') || _this3.get('isDestroying')) {
          return;
        }

        var _this3$get = _this3.get('_tooltip'),
            popperInstance = _this3$get.popperInstance;

        var popper = popperInstance.popper;
        var side = popper.getAttribute('x-placement');
        var marginSide = getOppositeSide(side);
        var style = popper.style;
        style.marginTop = 0;
        style.marginRight = 0;
        style.marginBottom = 0;
        style.marginLeft = 0;
        popper.style["margin".concat(Ember.String.capitalize(marginSide))] = "".concat(_this3.get('spacing'), "px");
      });
    },
    hide: function hide() {
      if (this.get('isDestroying')) {
        return;
      }
      /* If the tooltip is about to be showed by
      a delay, stop is being shown. */


      Ember.run.cancel(this.get('_showTimer'));

      this._hideTooltip();
    },
    show: function show() {
      if (this.get('isDestroying')) {
        return;
      }

      var delay = this.get('delay');
      var duration = this.get('duration');
      Ember.run.cancel(this.get('_showTimer'));
      Ember.run.cancel(this.get('_completeHideTimer'));

      if (duration) {
        this.setHideTimer(duration);
      }

      if (delay) {
        this.setShowTimer(delay);
      } else {
        this._showTooltip();
      }
    },
    setHideTimer: function setHideTimer(duration) {
      duration = cleanNumber(duration);
      Ember.run.cancel(this.get('_hideTimer'));

      if (duration) {
        /* Hide tooltip after specified duration */
        var hideTimer = Ember.run.later(this, this.hide, duration);
        /* Save timer ID for canceling should an event
        hide the tooltip before the duration */

        this.set('_hideTimer', hideTimer);
      }
    },
    setShowTimer: function setShowTimer(delay) {
      var _this4 = this;

      delay = cleanNumber(delay);

      if (!this.get('delayOnChange')) {
        /* If the `delayOnChange` property is set to false, we
        don't want to delay opening this tooltip/popover if there is
        already a tooltip/popover shown in the DOM. Check that here
        and adjust the delay as needed. */
        var shownTooltipsOrPopovers = document.querySelectorAll(".".concat(ANIMATION_CLASS));

        if (shownTooltipsOrPopovers.length) {
          delay = 0;
        }
      }

      var _showTimer = Ember.run.later(this, function () {
        _this4._showTooltip();
      }, delay);

      this.set('_showTimer', _showTimer);
    },
    _hideTooltip: function _hideTooltip() {
      var _this5 = this;

      var _tooltip = this.get('_tooltip');

      if (!_tooltip || this.get('isDestroying')) {
        return;
      }

      if (_tooltip.popperInstance) {
        _tooltip.popperInstance.popper.classList.remove(ANIMATION_CLASS);
      }

      var _completeHideTimer = Ember.run.later(function () {
        if (_this5.get('isDestroying')) {
          return;
        }

        cancelAnimationFrame(_this5._spacingRequestId);

        _tooltip.hide();

        _this5.set('_isHiding', false);

        _this5.set('isShown', false);

        _this5._dispatchAction('onHide', _this5);
      }, this.get('_animationDuration'));

      this.set('_completeHideTimer', _completeHideTimer);
    },
    _showTooltip: function _showTooltip() {
      var _this6 = this;

      if (this.get('isDestroying')) {
        return;
      }

      var _tooltip = this.get('_tooltip');

      _tooltip.show();

      this.set('isShown', true);
      Ember.run(function () {
        if (_this6.get('isDestroying')) {
          return;
        }

        _tooltip.popperInstance.popper.classList.add(ANIMATION_CLASS);

        _this6._dispatchAction('onShow', _this6);
      });
    },
    toggle: function toggle() {
      /* We don't use toggleProperty because we centralize
      logic for showing and hiding in the show() and hide()
      methods. */
      if (this.get('isShown')) {
        this.hide();
      } else {
        this.show();
      }
    },
    _addEventListener: function _addEventListener(eventName, callback, element) {
      var target = element || this.get('target');
      /* Remember event listeners so they can removed on teardown */

      var boundCallback = Ember.run.bind(this, callback);
      this.get('_tooltipEvents').push({
        callback: boundCallback,
        target: target,
        eventName: eventName
      });
      /* Add the event listeners */

      target.addEventListener(eventName, boundCallback);
    },
    _dispatchAction: function _dispatchAction(actionName) {
      var action = this.get(actionName);

      if (!this.isDestroying && !this.isDestroyed && action) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key2 = 1; _key2 < _len; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        action.apply(void 0, args);
      }
    },
    _cleanupTimers: function _cleanupTimers() {
      Ember.run.cancel(this.get('_showTimer'));
      cancelAnimationFrame(this._spacingRequestId);
    }
  });

  _exports.default = _default;

  function mergeModifiers(defaults) {
    var overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var defaultKeys = Object.keys(defaults);
    var overriddenKeys = Object.keys(overrides);
    var keys = [].concat(defaultKeys, overriddenKeys).reduce(function (acc, key) {
      if (acc.indexOf(key) === -1) acc.push(key);
      return acc;
    }, []);

    var modifiers = _objectSpread({}, defaults);

    keys.forEach(function (key) {
      if (defaultKeys.indexOf(key) !== -1 && overriddenKeys.indexOf(key) !== -1) {
        modifiers[key] = _objectSpread(_objectSpread({}, defaults[key]), overrides[key]);
      } else if (overriddenKeys.indexOf(key) !== -1) {
        modifiers[key] = overrides[key];
      }
    });
    return modifiers;
  }
});