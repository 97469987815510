define("@vollersgroup/ember-cropster-common/services/dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service can be used to show dialog messages.
   * If you want to use it, don't forget to add `{{modal-dialog}}` to your application template.
   *
   * The provided functions return a promise that resolves (if confirmed) or reject (if canceled).
   *
   * @namespace Service
   * @class Dialog
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    /**
     * The default confirmation text.
     * Replace this e.g. with a localized version if required.
     * This can be overwritten with the 'confirmButtonText' option.
     *
     * @attribute defaultConfirmButtonText
     * @type {String}
     * @default 'OK'
     * @public
     */
    defaultConfirmButtonText: 'OK',

    /**
     * The default cancel text.
     * Replace this e.g. with a localized version if required.
     * This can be overwritten with the 'cancelButtonText' option.
     * If this is overwritten as null, the button will not be displayed.
     *
     * @attribute defaultCancelButtonText
     * @type {String}
     * @default 'Cancel'
     * @public
     */
    defaultCancelButtonText: 'Cancel',

    /**
     * If the dialog is currently open.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property isOpen
     * @type {Boolean}
     * @protected
     */
    isOpen: false,

    /**
     * The title for the dialog.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property title
     * @type {String}
     * @protected
     */
    title: null,

    /**
     * The content for the dialog.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property message
     * @type {String}
     * @protected
     */
    message: null,

    /**
     * The dialog mode. This is used for styling.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property mode
     * @type {String}
     * @protected
     */
    mode: 'confirm',

    /**
     * Additional modal classes.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property modalClass
     * @type {String}
     * @protected
     */
    modalClass: '',

    /**
     * The text for the confirm button. This will overwrite the default text.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property confirmTextOverwrite
     * @type {String}
     * @protected
     */
    confirmTextOverwrite: undefined,

    /**
     * The text for the cancel button. This will overwrite the default text. If null, do not show the cancel button.
     * Do not manually set this, this is handled by the service methods.
     *
     * @property cancelTextOverwrite
     * @type {String}
     * @protected
     */
    cancelTextOverwrite: undefined,

    /**
     * The built class names for the modal.
     * These include the custom modalClass from the options.
     *
     * @property modalClassNames
     * @type {String}
     * @readOnly
     * @public
     */
    modalClassNames: Ember.computed('mode', 'modalClass', function () {
      return "modal--dialog modal--dialog--".concat(Ember.get(this, 'mode'), " ").concat(Ember.get(this, 'modalClass') || '');
    }),

    /**
     * The actual confirm button text to use.
     *
     * @property confirmButtonText
     * @type {String}
     * @readOnly
     * @public
     */
    confirmButtonText: Ember.computed.or('confirmTextOverwrite', 'defaultConfirmButtonText'),

    /**
     * The actual cancel button text to use.
     *
     * @property cancelButtonText
     * @type {String}
     * @readOnly
     * @public
     */
    cancelButtonText: Ember.computed('cancelTextOverwrite', 'defaultCancelButtonText', function () {
      if (Ember.typeOf(Ember.get(this, 'cancelTextOverwrite')) !== 'undefined') {
        return Ember.get(this, 'cancelTextOverwrite');
      }

      return Ember.get(this, 'defaultCancelButtonText');
    }),

    /**
     * An internal reference to the promise that is created when the dialog is opened.
     *
     * @property _promise
     * @type {RSVP.Promise}
     * @private
     */
    _promise: null,

    /**
     * An internal reference to the resolve function of the generated promise
     *
     * @property _resolve
     * @type {Function}
     * @private
     */
    _resolve: null,

    /**
     * An internal reference to the reject function of the generated promise.
     *
     * @property _reject
     * @type {Function}
     * @private
     */
    _reject: null,

    /**
     * Show an info message.
     *
     * @method info
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {Object} options Additional options for the dialog.
     * @returns {*|RSVP.Promise}
     */
    info: function info(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('info', message, title, Ember.assign({
        cancelButtonText: null
      }, options));
    },

    /**
     * Show a confirm dialog.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method confirm
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {Object} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    confirm: function confirm(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('confirm', message, title, options);
    },

    /**
     * Shows an alert message, with just a confirm button.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method alert
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{ modalClass: undefined, confirmButtonText: undefined}} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    alert: function alert(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('alert', message, title, Ember.assign({
        cancelButtonText: null
      }, options));
    },

    /**
     * Show a confirm dialog, with some additional 'warning' styling.
     * This returns a promise that resolves if confirmed, else rejects.
     *
     * @method confirmWarn
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{ modalClass: undefined, confirmButtonText: undefined, cancelButtonText: undefined }} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    confirmWarn: function confirmWarn(message) {
      var title = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      return this.show('confirm-warn', message, title, Ember.assign({
        modalClass: 'modal--dialog--warning'
      }, options));
    },

    /**
     * This is an alias for confirmWarn.
     *
     * @method confirmDelete
     * @return {RSVP.Promise}
     * @public
     */
    confirmDelete: function confirmDelete() {
      return this.confirmWarn.apply(this, arguments);
    },

    /**
     * This is used by the component to close the dialog.
     *
     * @method close
     * @protected
     */
    close: function close() {
      Ember.set(this, 'isOpen', false);
      var resolve = Ember.get(this, '_resolve');

      if (resolve) {
        resolve();
      }

      this._reset();
    },

    /**
     * This is used by the component to close the dialog.
     *
     * @method closeCancel
     * @protected
     */
    closeCancel: function closeCancel() {
      Ember.set(this, 'isOpen', false);
      var reject = Ember.get(this, '_reject');

      if (reject) {
        reject();
      }

      this._reset();
    },

    /**
     * Show a dialog.
     * This is used under the hood by e.g. confirm, alert, ...
     *
     * @method show
     * @param {String} type The type of message. This is used for styling.
     * @param {String} message The message body.
     * @param {String} title An optional title for the dialog.
     * @param {{ modalClass: undefined, confirmButtonText: undefined, cancelButtonText: undefined }} options Additional options for the dialog.
     * @return {RSVP.Promise}
     * @public
     */
    show: function show(type, message, title) {
      var _this = this;

      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var modalClass = options.modalClass,
          confirmButtonText = options.confirmButtonText,
          cancelButtonText = options.cancelButtonText;
      Ember.set(this, 'title', title);
      Ember.set(this, 'message', message);
      Ember.set(this, 'mode', type);
      Ember.set(this, 'modalClass', modalClass);
      Ember.set(this, 'confirmTextOverwrite', confirmButtonText);
      Ember.set(this, 'cancelTextOverwrite', cancelButtonText);
      Ember.set(this, 'isOpen', true);
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.set(_this, '_resolve', resolve);
        Ember.set(_this, '_reject', reject);
      });
      Ember.set(this, '_promise', promise);
      return promise;
    },

    /**
     * Reset the dialog and all it's properties.
     *
     * @method _reset
     * @private
     */
    _reset: function _reset() {
      Ember.set(this, '_resolve', null);
      Ember.set(this, '_reject', null);
      Ember.set(this, '_promise', null);
      Ember.set(this, 'confirmTextOverwrite', undefined);
      Ember.set(this, 'cancelTextOverwrite', undefined);
      Ember.set(this, 'title', null);
      Ember.set(this, 'message', null);
      Ember.set(this, 'modalClass', null);
    }
  });

  _exports.default = _default;
});