define("ember-svg-jar/inlined/arrow-down-black", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>arrow-custom-select</title><path d=\"M0 0h8L4 6 0 0z\" fill=\"#282F39\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "8",
      "height": "6",
      "viewBox": "0 0 8 6",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});