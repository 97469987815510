define("ember-svg-jar/inlined/wateractivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M10.791 6.021a26.572 26.572 0 00-1.69-2.026.23.23 0 00-.333 0c-.009.009-.856.911-1.69 2.026-1.14 1.524-1.719 2.706-1.719 3.515 0 1.952 1.604 3.54 3.575 3.54 1.972 0 3.575-1.588 3.575-3.54 0-.809-.578-1.991-1.718-3.515z\"/><path d=\"M17.313 6.15a8.29 8.29 0 00-2.204-3.664A8.51 8.51 0 0012.281.64 8.755 8.755 0 009.008 0v1.04c1.977 0 3.928.748 5.372 2.17a7.297 7.297 0 011.92 3.2 7.275 7.275 0 01.133 3.674 7.208 7.208 0 01-1.684 3.284l-1.475-1.419v3.535l3.68.055-1.476-1.447a8.318 8.318 0 001.958-3.748 8.205 8.205 0 00-.123-4.193zM1.686 10.596a7.28 7.28 0 01-.123-3.674 7.258 7.258 0 011.694-3.294l1.475 1.438V1.512l-3.68-.037L2.52 2.904A8.315 8.315 0 00.56 6.652a8.215 8.215 0 00.123 4.202 8.285 8.285 0 002.204 3.656 8.507 8.507 0 002.828 1.846 8.817 8.817 0 003.292.64v-1.02a7.625 7.625 0 01-5.382-2.19c-.93-.916-1.585-1.975-1.94-3.191z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17"
    }
  };
  _exports.default = _default;
});