define("ember-svg-jar/inlined/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.127 5.186c0 .732-.523 1.177-.904 1.504-.145.123-.414.349-.413.433a.584.584 0 01-.583.595h-.004a.585.585 0 01-.588-.585c-.005-.648.46-1.037.832-1.356.293-.25.485-.427.485-.588a.587.587 0 00-.581-.593.588.588 0 00-.581.593c0 .33-.263.598-.588.598a.593.593 0 01-.587-.598c0-.987.788-1.79 1.756-1.79s1.756.8 1.756 1.787zm-1.888 3.085a.582.582 0 00-.575.599v.004c0 .33.25.597.575.597.324 0 .587-.27.587-.601a.593.593 0 00-.587-.6zm-11.84 4.171a.593.593 0 00-.588.599c0 .33.263.599.588.599h.048a.593.593 0 00.588-.6.593.593 0 00-.588-.598H4.4zm2.041 0a.593.593 0 00-.587.599c0 .33.263.599.587.599h.049a.593.593 0 00.587-.6.593.593 0 00-.587-.598H6.44zm17.252-9.564v6.74c0 1.596-1.255 2.89-2.82 2.89h-7.618c-.15 0-.272 0-.374-.002a3.059 3.059 0 00-.276.002 3.92 3.92 0 00-.286.255l-.154.143-2.155 2.002a.589.589 0 01-.64.106.61.61 0 01-.362-.548V8.117H2.854c-.918 0-1.68.767-1.68 1.702v6.74c0 .936.762 1.672 1.68 1.672h8.509c.146 0 .283.068.392.169l1.755 1.65v-5.69c0-.33.263-.599.588-.599.324 0 .587.268.587.599v7.049a.591.591 0 01-.346.544.554.554 0 01-.233.048.588.588 0 01-.396-.163l-2.575-2.409H2.854A2.86 2.86 0 010 16.56V9.82c0-1.595 1.288-2.9 2.854-2.9h6.153V2.879A2.877 2.877 0 0111.87 0h9.002c1.565 0 2.82 1.282 2.82 2.878zm-1.175 0c0-.936-.728-1.68-1.645-1.68H11.87c-.917 0-1.688.744-1.688 1.68v10.231l1.18-1.088c.056-.051.117-.098.162-.14.539-.507.677-.588 1.38-.573.096.002.21.003.35.003h7.618c.917 0 1.645-.758 1.645-1.693v-6.74z\" fill=\"#4C4F59\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "22",
      "viewBox": "0 0 24 22"
    }
  };
  _exports.default = _default;
});