define("ember-svg-jar/inlined/density", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.81 1.696l-.375 3.395H2.34l-.385-3.395H13.81zM11.076 13.02c-1.27 1.627-3.263 2.278-4.835 1.724.531-.183.997-.434 1.37-.768a3.052 3.052 0 001.022-2.63c-.052-.545-.13-2.421 1.076-3.659.236-.242.513-.44.819-.602.114.066.234.118.342.2 1.627 1.228 1.72 3.796.206 5.735zM5.18 8.57c1.146-1.468 2.882-2.15 4.366-1.865-.161.123-.315.253-.457.399-1.457 1.497-1.372 3.686-1.31 4.322a2.231 2.231 0 01-.745 1.926c-.449.4-1.069.662-1.816.792-1.45-1.279-1.494-3.71-.038-5.574zm-1.674 8.402h8.763c.901 0 1.64-.653 1.745-1.503L15.774 0H0l1.762 15.47c.106.85.842 1.503 1.744 1.503z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17"
    }
  };
  _exports.default = _default;
});