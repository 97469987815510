define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M0 14.125h15V16H0zM7.5 0L2.812 4.688h2.813v7.5h3.75v-7.5h2.812z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16"
    }
  };
  _exports.default = _default;
});