define("ember-svg-jar/inlined/update-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.097.878c-3.28.033-5.967 2.689-6 5.93l-.05 4.694-2.424 4.72 5.345-.055c-.017 1.62 1.299 2.92 2.939 2.903 1.64-.017 2.984-1.344 3-2.965l5.346-.055-2.326-4.67.048-4.695C15.01 3.444 12.378.844 9.097.878zM8.92 17.896a1.728 1.728 0 01-1.763-1.742l3.563-.037c-.01.973-.816 1.77-1.8 1.78zm4.868-11.199l-.048 4.695 1.745 3.502-13.067.136 1.818-3.54.05-4.694c.026-2.594 2.176-4.717 4.8-4.744 2.624-.028 4.73 2.052 4.702 4.645z\" fill=\"#282F39\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "20",
      "viewBox": "0 0 18 20"
    }
  };
  _exports.default = _default;
});