define("ember-svg-jar/inlined/double-blind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M7.685 10.07A3.454 3.454 0 014.22 6.628c0-1.903 1.552-3.444 3.464-3.444 1.915 0 3.465 1.542 3.465 3.444S9.598 10.07 7.685 10.07m0-8.437A8.443 8.443 0 00.03 6.488a.327.327 0 000 .277 8.443 8.443 0 007.654 4.854 8.443 8.443 0 007.655-4.854.327.327 0 000-.277 8.443 8.443 0 00-7.655-4.854\"/><path d=\"M7.685 4.42c1.225 0 2.218.987 2.218 2.205A2.212 2.212 0 017.685 8.83a2.212 2.212 0 01-2.218-2.205c0-1.218.993-2.205 2.218-2.205\"/><path d=\"M1.024 11.468a.73.73 0 010-1.03L11.248.212a.73.73 0 011.03 0 .732.732 0 010 1.031L2.056 11.468a.732.732 0 01-1.031 0m2.068.784a.732.732 0 010-1.03L13.316.996a.73.73 0 011.031 0 .73.73 0 010 1.031L4.123 12.252a.732.732 0 01-1.03 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "13",
      "viewBox": "0 0 16 13"
    }
  };
  _exports.default = _default;
});