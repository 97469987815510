define("ember-svg-jar/inlined/categories-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#61A12D\" d=\"M13.485 9.143H5.007c-.585 0-1.06.482-1.06 1.077s.475 1.077 1.06 1.077h8.478c.585 0 1.06-.482 1.06-1.077s-.475-1.077-1.06-1.077z\"/><path fill=\"#4F99AC\" d=\"M13.485 4.429H5.007c-.585 0-1.06.482-1.06 1.077 0 .594.475 1.077 1.06 1.077h8.478c.585 0 1.06-.483 1.06-1.077 0-.595-.475-1.077-1.06-1.077z\"/><path fill=\"#282F39\" d=\"M5.007 2.654h8.478c.585 0 1.06-.482 1.06-1.077S14.07.5 13.484.5H5.007c-.585 0-1.06.482-1.06 1.077s.475 1.077 1.06 1.077z\"/><ellipse cx=\"1.076\" cy=\"1.593\" fill=\"#282F39\" rx=\"1.076\" ry=\"1.093\"/><ellipse cx=\"1.076\" cy=\"5.521\" fill=\"#4F99AC\" rx=\"1.076\" ry=\"1.093\"/><ellipse cx=\"1.076\" cy=\"10.236\" fill=\"#61A12D\" rx=\"1.076\" ry=\"1.093\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "12",
      "viewBox": "0 0 15 12"
    }
  };
  _exports.default = _default;
});