define("@vollersgroup/ember-cropster-common/utils/dom/trigger-click", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = triggerClick;

  // A rough replacement for $el.click()
  // Note that el.click() does not always trigger actions correctly
  function triggerClick(el) {
    if (document.createEvent) {
      var evt = document.createEvent('MouseEvents');
      evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      el.dispatchEvent(evt);
    } else {
      el.click();
    }
  }
});