define("ember-bulk-manager/components/sub/bulk-file-button", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-file-button", "ember-bulk-manager/utils/bulk-file-helper"], function (_exports, _bulkComponent, _bulkFileButton, _bulkFileHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A generic component providing a button for file uploads.
   * By default, it also shows a drop area for droppable uploads.
   * The component calls an `onChange` action providing files(s).
   *
   * ```html
   * {{#bulk-file-button
   *     files=files|[]
   *     multiple=true|[false]
   *     dropLabel="Drop label"
   *     dragAndDrop=[true]|false
   *     buttonLabel="Button label"
   *     onChange=(action "onChange")
   *     allowedTypes=allowedTypes||[]
   *     onInvalid=(action "onInvalid")}}
   *     {{!-- Optional label --}}
   *     Choose a file
   * {{/bulk-file-button}}
   * ```
   * Action signatures:
   *
   * - onChange(file)
   * - onInvalid(invalidFiles)
   *
   * @namespace Component
   * @class BulkFileButton
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkFileButton.default,
    classNames: ['bulk-file-button'],
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current selection of files. Could be passed in by another
     * FileReader component such as bulk-import-file for showing
     * the currently selected files within drop area message.
     *
     * @attribute files
     * @type {Array}
     * @default []
     * @public
     */
    files: Ember.computed({
      get: function get()
      /* key*/
      {
        return [];
      }
    }),

    /**
     * Flag to set whether multiple files are allowed or not.
     *
     * @attribute multiple
     * @type {Boolean}
     * @default false
     * @public
     */
    multiple: false,

    /**
     * Flag to set whether drag and drop is enabled or not.
     *
     * @attribute dragAndDrop
     * @type {Boolean}
     * @default true
     * @public
     */
    dragAndDrop: true,

    /**
     * Collection of supported file types to allow. NOTE:
     * This does only a cheap file extension check, the
     * file content can still be from another type!
     *
     * @attribute allowedTypes
     * @type {Array}
     * @default []
     * @public
     */
    allowedTypes: Ember.computed({
      get: function get()
      /* key*/
      {
        return [];
      }
    }),

    /**
     * Label of button if no block is provided.
     *
     * @attribute buttonLabel
     * @type {String}
     * @default 'Choose file'
     * @public
     */
    buttonLabel: Ember.computed({
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'l10n').t('Choose file');
      }
    }),

    /**
     * Label of drop area.
     *
     * @attribute dropLabel
     * @type {String}
     * @default null
     * @public
     */
    dropLabel: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Flag determining if `FileReader` API is supported.
     *
     * @property dropLabel
     * @type {String}
     * @default 'Drop file here'
     * @public
     */
    supportsFileAPI: Ember.computed(function () {
      return (0, _bulkFileHelper.supportsFileAPI)();
    }),
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Handles `change` event of input element.
       * Proxy to both `onChange` and `onInvalid`
       * callbacks shared with `bulk-drop-area`.
       *
       * @event onFile
       * @param {Object} evt
       * @return {Void}
       * @private
       */
      onFile: function onFile(evt) {
        var files = evt.target.files;
        var param = !Ember.get(this, 'multiple') ? files[0] : files;
        var invalidFiles = (0, _bulkFileHelper.validateFiles)(Ember.get(this, 'allowedTypes'), param);

        if (Ember.get(invalidFiles, 'length') !== 0) {
          this.send('onInvalid', invalidFiles);
          Ember.set(this, 'files', []);
          return;
        }

        Ember.set(this, 'files', files);
        this.send('onChange', param);
      },

      /**
       * Invokes `onChange` callback on `attrs`.
       * Handles callbacks for `bulk-drop-area`
       * and is called by `onFile` for <input>.
       *
       * @event onChange
       * @param {Mixed} param
       * @private
       */
      onChange: function onChange(param) {
        this.invokeCallback('onChange', param);
      },

      /**
       * Invokes `onInvalid` callback on `attrs`.
       * Handles callbacks for `bulk-drop-area`
       * and is called by `onFile` for <input>.
       *
       * @event onInvalid
       * @param {Array} invalidFiles
       * @private
       */
      onInvalid: function onInvalid(invalidFiles) {
        this.invokeCallback('onInvalid', invalidFiles);
      }
    }
  });

  _exports.default = _default;
});