define("ember-svg-jar/inlined/sort_desc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.809 13.052H7.634a.264.264 0 00-.196.076.265.265 0 00-.076.196v1.631c0 .08.025.144.076.196.051.05.116.076.196.076h2.175c.08 0 .145-.026.195-.076a.264.264 0 00.077-.196v-1.631a.264.264 0 00-.077-.196.264.264 0 00-.195-.076zM5.73 11.964H4.1V.272a.265.265 0 00-.077-.196A.265.265 0 003.827 0H2.196A.265.265 0 002 .076a.265.265 0 00-.076.196v11.692H.292c-.124 0-.21.057-.255.17-.045.108-.025.207.06.297l2.719 2.72c.062.05.127.076.195.076a.284.284 0 00.196-.076l2.71-2.711a.319.319 0 00.085-.204.266.266 0 00-.076-.196.265.265 0 00-.196-.076zm5.71-3.263H7.634a.265.265 0 00-.196.077.265.265 0 00-.076.195v1.632c0 .079.025.144.076.195.051.05.116.076.196.076h3.806c.08 0 .145-.025.196-.076a.264.264 0 00.076-.195V8.973a.264.264 0 00-.076-.195.264.264 0 00-.196-.077zM14.9.076A.265.265 0 0014.703 0h-7.07a.265.265 0 00-.195.076.265.265 0 00-.076.196v1.631c0 .08.025.145.076.196.051.05.116.076.196.076h7.07c.079 0 .144-.025.195-.076a.265.265 0 00.076-.196V.272A.265.265 0 0014.9.076zm-1.827 4.275H7.634a.264.264 0 00-.196.076.265.265 0 00-.076.196v1.631c0 .08.025.144.076.195.051.051.116.077.196.077h5.438c.08 0 .144-.026.196-.077a.265.265 0 00.076-.195V4.623a.265.265 0 00-.076-.196.266.266 0 00-.196-.076z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "16",
      "viewBox": "0 0 15 16"
    }
  };
  _exports.default = _default;
});