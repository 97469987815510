define("ember-svg-jar/inlined/print-labels-on-hub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#282F39\" d=\"M0 6.875v2.75h1.375V11h8.25V9.625H11v-2.75H0zm8.938 3.438H2.061V8.25h6.876v2.063zM8.25 4.125v-2.75L6.394 0H2.75v4.125H0v2.063h11V4.124H8.25zM6.187.687l.894.688h-.894V.687zm1.375 4.125H3.438V.688H5.5v1.375h2.063v2.75zm2.75.688h-.687v-.688h.688V5.5z\"/><path stroke=\"#282F39\" stroke-linecap=\"square\" d=\"M5.5 8.3v2.2\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 11 11"
    }
  };
  _exports.default = _default;
});