define("ember-svg-jar/inlined/lab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12.632 14.521L9.589 6.803V2.364h.711V.93H2.525v1.434h.711v4.44l-3.035 7.72a2.47 2.47 0 00.311 2.369A2.752 2.752 0 002.737 18h7.36c.887 0 1.72-.414 2.225-1.108.506-.694.622-1.58.31-2.37zM3.906 9.196l.84-2.133V2.365H8.08v4.698l.84 2.133H3.907z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18"
    }
  };
  _exports.default = _default;
});