define("ember-svg-jar/inlined/rating-maybe-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.5 0C33.355 0 43 9.645 43 21.5S33.355 43 21.5 43 0 33.355 0 21.5 9.645 0 21.5 0zM15 18a2 2 0 100-4 2 2 0 000 4zm14 0a2 2 0 100-4 2 2 0 000 4zm2.125 9h-19.25c-.484 0-.875.447-.875 1 0 .553.391 1 .875 1h19.25c.484 0 .875-.447.875-1 0-.553-.391-1-.875-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43"
    }
  };
  _exports.default = _default;
});