define("ember-svg-jar/inlined/cupping-sheets-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><rect width=\"16.776\" height=\"11.765\" x=\"1.398\" y=\".25\" fill=\"#6E8595\" rx=\"2\"/><rect width=\"16.776\" height=\"11.765\" y=\"1.662\" fill=\"#282F39\" rx=\"2\"/><rect width=\"16.776\" height=\"11.765\" y=\"1.662\" fill=\"#609D2F\" rx=\"2\"/><path d=\"M10.117 8.113a.497.497 0 00.135-.334.496.496 0 00-.135-.334.484.484 0 00-.662 0 .494.494 0 00-.135.334c0 .122.05.245.135.334a.477.477 0 00.331.137.488.488 0 00.33-.137zm1.067-.804a.468.468 0 00-.466.47c0 .26.208.47.466.47h2.796c.258 0 .466-.21.466-.47s-.208-.47-.466-.47h-2.796zm1.398 2.823h-2.33a.468.468 0 00-.466.47c0 .26.208.471.466.471h2.33c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zm1.533.137a.485.485 0 00-.135.334c0 .122.05.244.135.334.088.084.21.136.33.136a.488.488 0 00.332-.136.497.497 0 00.135-.334.487.487 0 00-.135-.334.484.484 0 00-.662 0zM2.796 8.249h5.126c.258 0 .466-.21.466-.47s-.208-.47-.466-.47H2.796a.468.468 0 00-.466.47c0 .26.208.47.466.47zm1.398 1.883H2.796a.468.468 0 00-.466.47c0 .26.208.471.466.471h1.398c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zm4.194 0h-2.33a.468.468 0 00-.466.47c0 .26.208.471.466.471h2.33c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zM4.66 5.426h2.33c.258 0 .466-.21.466-.47s-.208-.471-.466-.471H4.66a.468.468 0 00-.466.47c0 .26.208.471.466.471zm-1.864 0a.488.488 0 00.33-.136.497.497 0 00.136-.334.496.496 0 00-.135-.335.484.484 0 00-.662 0 .494.494 0 00-.135.335c0 .127.05.244.135.334.088.084.21.136.331.136zm10.252 0h1.398c.258 0 .466-.21.466-.47s-.208-.471-.466-.471h-1.398a.468.468 0 00-.466.47c0 .26.208.471.466.471zm-1.398-.47c0-.26-.208-.471-.466-.471h-2.33a.468.468 0 00-.466.47c0 .26.208.471.466.471h2.33c.258 0 .466-.21.466-.47z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "14",
      "viewBox": "0 0 19 14"
    }
  };
  _exports.default = _default;
});