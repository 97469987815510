define("@vollersgroup/ember-cropster-common/components/info-message/component", ["exports", "@vollersgroup/ember-cropster-common/components/info-message/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A simple info message.
   *
   * @namespace Component
   * @class InfoMessage
   * @extend Ember.Component
   * @public
   * @example
   * ```handlebars
   * {{#info-message}}
   *   Very basic example
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message title='My title'}}
   *   Additional content
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message title='Info Message' icon='info'}}
   *   My message content.
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message title='Error Message' type='error' icon='error'}}
   *   My message content.
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message title='Success Message' type='success' icon='success'}}
   *   My message content.
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message title='Warning Message' type='warning' icon='warning'}}
   *   My message content.
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message icon='info'}}
   *   Icon without title
   * {{/info-message}}
   * ```
   * @example
   * ```handlebars
   * {{#info-message icon='info'}}
   *   Info with buttons.
   *
   *   <div class='message__button-group'>
   *     <button class='button button--primary'>Primary Action</button>
   *     <button class='button button--secondary'>Secondary Action</button>
   *   </div>
   * {{/info-message}}
   * ```
   */
  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['message'],
    classNameBindings: ['icon:message--with-wrapper', 'isTypeError:message--error', 'isTypeWarning:message--warning', 'isTypeSuccess:message--success'],

    /**
     * One of: error, success, warning. Or leave empty for default message.
     *
     * @attribute type
     * @type {String}
     * @optional
     * @public
     */
    type: '',

    /**
     * A title to display.
     *
     * @attribute title
     * @type {String}
     * @optional
     * @public
     */
    title: '',

    /**
     * An icon to render for the info message.
     *
     * @attribute icon
     * @type {String}
     * @optional
     * @public
     */
    icon: null,

    /**
     * If `icon` is specified, this will be set as class on the icon.
     *
     * @attribute iconClass
     * @type {String}
     * @default 'icon--medium'
     * @public
     */
    iconClass: 'icon--medium',

    /**
     * If the type of the message is 'error'.
     *
     * @property isTypeError
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isTypeError: Ember.computed.equal('type', 'error'),

    /**
     * If the type of the message is 'warning'.
     *
     * @property isTypeWarning
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isTypeWarning: Ember.computed.equal('type', 'warning'),

    /**
     * If the type of the message is 'success'.
     *
     * @property isTypeSuccess
     * @type {Boolean}
     * @readOnly
     * @protected
     */
    isTypeSuccess: Ember.computed.equal('type', 'success')
  });

  _exports.default = _default;
});