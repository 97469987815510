define("ember-svg-jar/inlined/rating-yes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.5 0C9.645 0 0 9.645 0 21.5S9.645 43 21.5 43 43 33.355 43 21.5 33.355 0 21.5 0zm0 41.208c-10.867 0-19.708-8.84-19.708-19.708 0-10.867 8.84-19.708 19.708-19.708 10.867 0 19.708 8.84 19.708 19.708 0 10.867-8.84 19.708-19.708 19.708z\"/><path d=\"M33.296 25.034c-.496-.108-.986.17-1.102.618-1.18 4.523-5.577 7.681-10.692 7.681-5.121 0-9.52-3.163-10.694-7.692-.117-.449-.614-.721-1.102-.62-.493.106-.798.555-.681 1.003C10.396 31.309 15.528 35 21.502 35c5.967 0 11.097-3.685 12.473-8.962.117-.449-.187-.898-.679-1.004z\"/><circle cx=\"13.5\" cy=\"16.5\" r=\"1.5\"/><circle cx=\"28.5\" cy=\"16.5\" r=\"1.5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43"
    }
  };
  _exports.default = _default;
});