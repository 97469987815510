define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.907 17h10.714a.4.4 0 00.401-.396V5.61H.506v10.993c0 .22.179.396.401.396zm7.669-9.257c0-.357.293-.648.656-.648h.014c.362 0 .657.29.657.648v7.127a.652.652 0 01-.657.648h-.014a.652.652 0 01-.656-.648V7.743zm-2.975 0c0-.357.293-.648.656-.648h.014c.362 0 .657.29.657.648v7.127a.652.652 0 01-.657.648h-.014a.652.652 0 01-.656-.648V7.743zm-2.975 0c0-.357.293-.648.656-.648h.014c.362 0 .657.29.657.648v7.127a.652.652 0 01-.657.648h-.014a.652.652 0 01-.656-.648V7.743zm6.205-5.86V.99C8.83.444 8.38 0 7.827 0H4.699a.998.998 0 00-1.003.99v.892h.803V1.19A.4.4 0 014.9.793h2.724a.4.4 0 01.402.396v.693h.805zM.004 3.07v1.351a.4.4 0 00.401.397h11.716a.4.4 0 00.401-.397v-1.35a.4.4 0 00-.401-.397H.405a.399.399 0 00-.401.396z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "17",
      "viewBox": "0 0 13 17"
    }
  };
  _exports.default = _default;
});