define("ember-svg-jar/inlined/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M360.514 170.668c-9.899 48.619-52.992 85.333-104.512 85.333s-94.613-36.715-104.512-85.333H.002v128h512v-128H360.514zm-44.437 170.666h-120.15c8.832 24.768 32.299 42.667 60.075 42.667 27.776 0 51.243-17.899 60.075-42.667z\"/><path d=\"M256 426.668c-51.499 0-94.592-36.715-104.512-85.333H0v149.333c0 11.776 9.557 21.333 21.333 21.333h469.333c11.776 0 21.333-9.557 21.333-21.333V341.334H360.512c-9.92 48.619-52.992 85.334-104.512 85.334zm-63.998-277.334c0 35.285 28.715 64 64 64s64-28.715 64-64c0-11.776 9.557-21.333 21.333-21.333h159.317L400.386 7.681a21.32 21.32 0 00-16.384-7.68h-256a21.32 21.32 0 00-16.384 7.68L11.351 128.001h159.317c11.777 0 21.334 9.557 21.334 21.333z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "512",
      "height": "512",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});