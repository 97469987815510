define("ember-bulk-manager/components/sub/bulk-tooltip", ["exports", "ember-bulk-manager/templates/sub/bulk-tooltip"], function (_exports, _bulkTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ALIGNMENT_BOTTOM = _exports.ALIGNMENT_RIGHT = _exports.ALIGNMENT_TOP = _exports.ALIGNMENT_LEFT = void 0;

  /**
   * @constant ALIGNMENT_LEFT
   * @type {String}
   * @public
   */
  var ALIGNMENT_LEFT = 'LEFT';
  /**
   * @constant ALIGNMENT_TOP
   * @type {String}
   * @public
   */

  _exports.ALIGNMENT_LEFT = ALIGNMENT_LEFT;
  var ALIGNMENT_TOP = 'TOP';
  /**
   * @constant ALIGNMENT_RIGHT
   * @type {String}
   * @public
   */

  _exports.ALIGNMENT_TOP = ALIGNMENT_TOP;
  var ALIGNMENT_RIGHT = 'RIGHT';
  /**
   * @constant ALIGNMENT_BOTTOM
   * @type {String}
   * @public
   */

  _exports.ALIGNMENT_RIGHT = ALIGNMENT_RIGHT;
  var ALIGNMENT_BOTTOM = 'BOTTOM';
  /**
   * A simple tooltip message.
   *
   * ```hbs
   * {{#bulk-tooltip text='My Title' alignment='TOP'}}
   *   {{!-- Other content such as components --}}
   * {{/bulk-tooltip}}
   * ```
   *
   * @namespace Component
   * @class CTooltip
   * @extend Ember.Component
   * @public
   */

  _exports.ALIGNMENT_BOTTOM = ALIGNMENT_BOTTOM;

  var _default = Ember.Component.extend({
    layout: _bulkTooltip.default,
    classNameBindings: ['isInline:inline'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * A custom target where tooltip will be displayed at.
     * Accepts both string selectors or even DOM nodes.
     *
     * @attribute target
     * @type {String|DOMNode}
     * @public
     */
    target: null,

    /**
     * If true the tooltip will be shown.
     *
     * @attribute isVisible
     * @type {Boolean}
     * @default false
     * @optional
     * @public
     */
    isVisible: false,

    /**
     * If the tooltip should be inline.
     * This is in most cases required for alignment=RIGHT to work.
     *
     * @attribute isInline
     * @type {Boolean}
     * @default true
     * @public
     */
    isInline: true,

    /**
     * If true the tooltip can be hidden.
     *
     * @attribute isCloseable
     * @type {Boolean}
     * @default true
     * @optional
     * @public
     */
    isCloseable: false,

    /**
     * One of: 'LEFT', 'TOP', 'RIGHT' or 'BOTTOM'.
     * You can use the exported constants to set
     * this value like: ALIGNEMENT_RIGHT.
     *
     * @attribute alignment
     * @type {String}
     * @default 'TOP'
     * @optional
     * @public
     */
    alignment: ALIGNMENT_TOP,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * General CSS classname for the tooltip.
     *
     * @property tooltipClass
     * @type {String}
     * @public
     */
    tooltipClass: 'bulk-tooltip',

    /**
     * If the current alignment is 'LEFT'
     *
     * @property isLeft
     * @type {Boolean}
     * @public
     */
    isLeft: Ember.computed.equal('alignment', ALIGNMENT_LEFT),

    /**
     * If the current alignment is 'TOP'
     *
     * @property isTop
     * @type {Boolean}
     * @public
     */
    isTop: Ember.computed.equal('alignment', ALIGNMENT_TOP),

    /**
     * If the current alignment is 'RIGHT'
     *
     * @property isRight
     * @type {Boolean}
     * @public
     */
    isRight: Ember.computed.equal('alignment', ALIGNMENT_RIGHT),

    /**
     * If the current alignment is 'BOTTOM'
     *
     * @property isBottom
     * @type {Boolean}
     * @public
     */
    isBottom: Ember.computed.equal('alignment', ALIGNMENT_BOTTOM),

    /**
     * DOM id of actual tooltip container.
     *
     * @property wormholeId
     * @type {String}
     * @public
     */
    wormholeId: Ember.computed(function () {
      return "tooltip-wormhole-".concat(Ember.guidFor(this));
    }),

    /**
     * The window element mainly used for testing.
     *
     * @property windowElement
     * @type {DOMElement}
     * @protected
     */
    _windowElement: null,
    windowElement: Ember.computed('_windowElement', function () {
      var _windowElement = Ember.get(this, '_windowElement');

      return _windowElement ? document.querySelector(_windowElement) : window;
    }),

    /**
     * The body element mainly used for testing.
     *
     * @property bodyElement
     * @type {DOMElement}
     * @protected
     */
    _bodyElement: 'body',
    bodyElement: Ember.computed('_bodyElement', function () {
      return document.querySelector(Ember.get(this, '_bodyElement'));
    }),

    /**
     * The DOM element of the content itself.
     *
     * @property targetElement
     * @type {DOMElement}
     * @readOnly
     * @protected
     */
    targetElement: Ember.computed('target', function () {
      var target = Ember.get(this, 'target');

      if (Ember.typeOf(target) === 'string') {
        target = document.querySelector(target);
      }

      if (target instanceof Element) {
        return target;
      }

      return this.element;
    }),

    /**
     * The wormhole element.
     *
     * @property wormholeElement
     * @type {DOMElement}
     * @public
     */
    wormholeElement: Ember.computed('wormholeId', function () {
      var wormholeId = Ember.get(this, 'wormholeId');
      var element = document.createElement('div');
      element.setAttribute('id', wormholeId);
      return element;
    }),

    /**
     * The tooltip DOM element.
     *
     * @property tooltipElement
     * @type {DOMElement}
     * @public
     */
    tooltipElement: Ember.computed('wormholeElement', function () {
      var wormhole = Ember.get(this, 'wormholeElement');
      return wormhole.querySelector(".".concat(this.tooltipClass));
    }),

    /**
     * Caches the original alignment.
     *
     * @property _originalAlignment
     * @type {String}
     * @private
     */
    _originalAlignment: null,

    /**
     * Caches mutation observer.
     *
     * @property _mutationObserver
     * @type {Object}
     * @private
     */
    _mutationObserver: null,

    /**
     * If not added, tests are dramatically sped up, as they do not need to wait on the listeners on every change.
     *
     * @property _shouldAddResizeListener
     * @type {Boolean}
     * @readOnly
     * @private
     */
    _shouldAddResizeListener: Ember.computed(function () {
      return !Ember.testing; // eslint-disable-line ember-suave/no-direct-property-access
    }),
    // -------------------------------------------------------------------------
    // Hooks

    /**
     * Checks user settings if tooltip has been
     * hidden previously and will adapt these
     * settings.
     *
     * @method init
     * @return {Void}
     * @public
     */
    init: function init() {
      this._super.apply(this, arguments);

      var alignment = Ember.get(this, 'alignment');
      Ember.set(this, '_originalAlignment', alignment);
    },

    /**
     * Triggers recomputation of tooltip offset.
     *
     * @method didReceiveAttrs
     * @return {Void}
     * @public
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', this, this._setPosition);
    },

    /**
     * Sets the initial offset of the tooltip and
     * registers resize handler/mutation observer.
     *
     * @method didInsertElement
     * @return {Void}
     * @public
     */
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this._shouldAddResizeListener) {
        this._addResizeListener();

        this._addMutationObserver();
      }

      this._addWormwholeInDOM();

      Ember.run.scheduleOnce('afterRender', this, this._setPosition);
    },

    /**
     * Removes whormhole element and cleans resize
     * handler/muation observer on dom desctruction.
     *
     * @method willDestroyElement
     * @return {Void}
     * @public
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this._shouldAddResizeListener) {
        this._removeResizeListener();

        this._removeMutationObserver();
      }

      this._removeWormholeFromDOM();
    },
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Add the mutation observer to check the body elements.
     *
     * @method _addMutationObserver
     * @private
     */
    _addMutationObserver: function _addMutationObserver() {
      var _this = this;

      var MutationObserver = this._getCrossBrowserMutationObserver();

      if (Ember.isNone(MutationObserver)) {
        return;
      }

      var mutationObserver = new MutationObserver(function () {
        Ember.run.debounce(_this, _this._onResize, 1000, true);
      });
      mutationObserver.observe(Ember.get(this, 'bodyElement'), {
        characterData: true,
        attributes: true,
        childList: true,
        subtree: true
      });
      Ember.set(this, '_mutationObserver', mutationObserver);
    },

    /**
     * Remove the DOM mutation observer - if it exists.
     *
     * @method _removeMutationObserver
     * @private
     */
    _removeMutationObserver: function _removeMutationObserver() {
      var mutationObserver = Ember.get(this, '_mutationObserver');

      if (!Ember.isNone(mutationObserver)) {
        mutationObserver.disconnect();
      }
    },

    /**
     * Add the resize listener.
     *
     * @method _addResizeListener
     * @private
     */
    _addResizeListener: function _addResizeListener() {
      var _this2 = this;

      var window = Ember.get(this, 'windowElement'); // Create this listener and save it on the class, so we can remove it later

      var _windowResizeListener = function _windowResizeListener() {
        return _this2._onResize();
      };

      Ember.set(this, '_windowResizeListener', _windowResizeListener);
      window.addEventListener('resize', _windowResizeListener);
    },

    /**
     * Remove the resize listener;
     *
     * @method _removeResizeListener
     * @private
     */
    _removeResizeListener: function _removeResizeListener() {
      var window = Ember.get(this, 'windowElement');

      var _windowResizeListener = Ember.get(this, '_windowResizeListener');

      window.removeEventListener('resize', _windowResizeListener);
    },

    /**
     * Adds the wormhole into the DOM.
     *
     * @method _addWormwholeInDOM
     * @private
     */
    _addWormwholeInDOM: function _addWormwholeInDOM() {
      var wormholeElement = Ember.get(this, 'wormholeElement');
      Ember.get(this, 'bodyElement').appendChild(wormholeElement);
    },

    /**
     * Removes the wormhole from the DOM.
     *
     * @method _removeWormholeFromDOM
     * @private
     */
    _removeWormholeFromDOM: function _removeWormholeFromDOM() {
      var wormholeElement = Ember.get(this, 'wormholeElement');
      Ember.get(this, 'bodyElement').removeChild(wormholeElement);
    },

    /**
     * Tries to reset to original alignment and
     * recalculates tooltip position debounced.
     *
     * @method _onResize
     * @return {Void}
     * @private
     */
    _onResize: function _onResize() {
      Ember.set(this, 'alignment', Ember.get(this, '_originalAlignment'));
      Ember.run.debounce(this, this._setPosition, 10);
    },

    /**
     * Sets correct offset depending on `alignment`.
     *
     * @method _setPosition
     * @return {Void}
     * @private
     */
    _setPosition: function _setPosition() {
      if (Ember.get(this, 'isDestroyed')) {
        return;
      }

      if (!Ember.get(this, 'isVisible')) {
        return;
      }

      var element = Ember.get(this, 'targetElement');
      var tooltip = Ember.get(this, 'tooltipElement');
      var eRect = element.getBoundingClientRect();
      var tRect = tooltip.getBoundingClientRect();
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      var eTop = eRect.top;
      var eLeft = eRect.left;
      var tWidth = tRect.width;
      var eWidth = eRect.width;
      var tHeight = tRect.height;
      var eHeight = eRect.height;
      var vCenter = eHeight / 2 - tHeight / 2;

      switch (Ember.get(this, 'alignment')) {
        case ALIGNMENT_LEFT:
          tooltip.style.top = "".concat(eTop + scrollTop + vCenter, "px");
          tooltip.style.left = "".concat(eLeft + scrollLeft - tWidth, "px");
          break;

        case ALIGNMENT_TOP:
          tooltip.style.top = "".concat(eTop + scrollTop - tHeight, "px");
          tooltip.style.left = "".concat(eLeft + scrollLeft, "px");
          break;

        case ALIGNMENT_RIGHT:
          tooltip.style.top = "".concat(eTop + scrollTop + vCenter, "px");
          tooltip.style.left = "".concat(eLeft + scrollLeft + eWidth, "px");
          break;

        case ALIGNMENT_BOTTOM:
          tooltip.style.top = "".concat(eTop + scrollTop + eHeight, "px");
          tooltip.style.left = "".concat(eLeft + scrollLeft, "px");
          break;
      }

      this._fixAlignment();
    },

    /**
     * Checks if current alignment is appropriate
     * for current window size, otherwise tries
     * to auto fix the alignment to better value.
     *
     * @method _fixAlignment
     * @return {Void}
     * @private
     */
    _fixAlignment: function _fixAlignment() {
      var overlap = this._getOverlap();

      switch (Ember.get(this, 'alignment')) {
        case ALIGNMENT_LEFT:
          if (overlap.left) {
            Ember.set(this, 'alignment', ALIGNMENT_TOP);

            this._setPosition();
          }

          break;

        case ALIGNMENT_TOP:
          if (overlap.top) {
            Ember.set(this, 'alignment', ALIGNMENT_RIGHT);

            this._setPosition();
          }

          break;

        case ALIGNMENT_RIGHT:
          if (overlap.right) {
            Ember.set(this, 'alignment', ALIGNMENT_BOTTOM);

            this._setPosition();
          }

          break;

        case ALIGNMENT_BOTTOM:
          if (overlap.bottom) {
            Ember.set(this, 'alignment', ALIGNMENT_LEFT);

            this._setPosition();
          }

          break;
      }
    },

    /**
     * Calculates if container overlaps on any side
     * depending on current window size. Provides
     * a hash with boolean values for each side.
     *
     * @method _getOverlap
     * @return {Object}
     * @private
     */
    _getOverlap: function _getOverlap() {
      var window = Ember.get(this, 'windowElement');
      var tooltip = Ember.get(this, 'tooltipElement'); // `window` is usually the window itself,
      // but when testing, it might be overwritten with a
      // DOMElement - use the appropriate methods for both

      var wWidth = window.offsetWidth || window.innerWidth;
      var wHeight = window.offsetHeight || window.innerHeight;
      var wOffsetTop = window.offsetTop || 0;
      var wOffsetLeft = window.offsetLeft || 0;
      var tWidth = tooltip.offsetWidth;
      var tHeight = tooltip.offsetHeight;
      var tOffsetLeft = tooltip.offsetLeft;
      var tOffsetTop = tooltip.offsetTop;
      return {
        top: tOffsetTop < wOffsetTop,
        left: tOffsetLeft < wOffsetLeft,
        right: tOffsetLeft + tWidth - wOffsetLeft > wWidth,
        bottom: tOffsetTop + tHeight - wOffsetTop > wHeight
      };
    },

    /**
     * Tries to get supported `MutationObserver` for
     * current browser environment to be used for dom
     * change detection in order to reposition tooltip.
     *
     * @method _getCrossBrowserMutationObserver
     * @return {Object}
     * @private
     */
    _getCrossBrowserMutationObserver: function _getCrossBrowserMutationObserver() {
      var prefixes = ['', 'WebKit', 'Moz', 'O', 'Ms'];

      for (var i = 0; i < prefixes.length; i++) {
        var observer = "".concat(prefixes[i], "MutationObserver");

        if (observer in window) {
          return window[observer];
        }
      }

      return null;
    }
  });

  _exports.default = _default;
});