define("@vollersgroup/ember-cropster-common/components/bread-crumbs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P9R0wdAZ",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[7,\"li\"],[9],[0,\"\\n\\n\"],[4,\"link-to\",[[23,[\"rootPage\"]]],[[\"class\"],[\"breadcrumbs__item\"]],{\"statements\":[[0,\"    Home\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,2],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"items\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[22,1,[\"route\"]],[22,1,[\"model\"]]],null]],null,{\"statements\":[[4,\"link-to\",[[22,1,[\"route\"]],[22,1,[\"model\"]]],[[\"class\"],[\"breadcrumbs__item\"]],{\"statements\":[[4,\"if\",[[22,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[22,1,[\"route\"]]],null,{\"statements\":[[4,\"link-to\",[[22,1,[\"route\"]]],[[\"class\"],[\"breadcrumbs__item\"]],{\"statements\":[[4,\"if\",[[22,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"breadcrumbs__item breadcrumbs__item--no-link\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"title\"]]],null,{\"statements\":[[0,\"          \"],[1,[22,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          Loading...\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/ember-cropster-common/components/bread-crumbs/template.hbs"
    }
  });

  _exports.default = _default;
});