define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\" transform=\"rotate(45 6.218 15.01)\"><path d=\"M4.775 13.61h.076V3.234H0V13.61h.076a.296.296 0 00-.076.189l2.426.754c1.34 0 2.425-.338 2.425-.754a.296.296 0 00-.076-.19z\"/><rect width=\"4.851\" height=\"2.021\" rx=\"1.011\"/><path d=\"M.049 15.362H0L2.426 19l2.425-3.638h-.048c-.225.461-1.204.808-2.377.808-1.174 0-2.153-.347-2.377-.808z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});