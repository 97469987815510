define("ember-bulk-manager/components/sub/bulk-drop-area", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-drop-area", "ember-bulk-manager/utils/bulk-file-helper"], function (_exports, _bulkComponent, _bulkDropArea, _bulkFileHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A generic component providing drop area for file uploads.
   * The component calls an `onDrop` action providing files(s).
   *
   * ```html
   * {{#bulk-drop-area
   *     files=files||[]
   *     multiple=true|[false]
   *     onChange=(action "onChange")
   *     allowedTypes=allowedTypes||[]
   *     onInvalid=(action "onInvalid")}}
   *     {{!-- Optional label --}}
   *     Drop your files here
   * {{/bulk-drop-area}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(file)
   * - onInvalid(invalidFiles)
   *
   * @namespace Component
   * @class BulkDropArea
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkDropArea.default,
    classNames: ['bulk-drop-area'],
    classNameBindings: ['isActive:bulk-drop-area--active', 'hasFiles:bulk-drop-area--selection', 'supportsFileAPI:bulk-drop-area--enabled'],
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Label of drop area.
     *
     * @attribute label
     * @type {String}
     * @default 'Drop file(s) here'
     * @public
     */
    label: '',

    /**
     * Current selection of files. Could be passed in by another
     * FileReader component such as bulk-import-file for showing
     * the currently selected files within drop area message.
     *
     * @attribute files
     * @type {Array}
     * @default []
     * @public
     */
    files: Ember.computed({
      get: function get()
      /* key*/
      {
        return [];
      }
    }),

    /**
     * Flag to set whether multiple files are allowed or not.
     *
     * @attribute multiple
     * @type {Boolean}
     * @default false
     * @public
     */
    multiple: false,

    /**
     * Collection of supported file types to allow. NOTE:
     * This does only a cheap file extension check, the
     * file content can still be from another type!
     *
     * @attribute allowedTypes
     * @type {Array}
     * @default []
     * @public
     */
    allowedTypes: Ember.computed({
      get: function get()
      /* key*/
      {
        return [];
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Computes label depending `label` and `multiple` attribute.
     * If consumer sets label, this will be used, otherwise the
     * default label `Drop file(s) here` depending on `multiple`
     * will be displayed in UI.
     *
     * @property computedLabel
     * @type {String}
     * @public
     */
    computedLabel: Ember.computed('label', 'multiple', {
      get: function get()
      /* key*/
      {
        var label = Ember.get(this, 'label');

        if (!Ember.isNone(label)) {
          return label;
        }

        if (!Ember.get(this, 'multiple')) {
          return Ember.get(this, 'l10n').t('Drop file here');
        }

        return Ember.get(this, 'l10n').t('Drop files here');
      }
    }),

    /**
     * Flag determining if `FileReader` API is supported.
     *
     * @property dropLabel
     * @type {String}
     * @default 'Drop file here'
     * @public
     */
    supportsFileAPI: Ember.computed({
      get: function get()
      /* key*/
      {
        return (0, _bulkFileHelper.supportsFileAPI)();
      }
    }),

    /**
     * Flag determining if files have been dropped.
     *
     * @property dropLabel
     * @type {Boolean}
     * @default false
     * @public
     */
    hasFiles: Ember.computed('files.length', {
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'files.length') > 0;
      }
    }),

    /**
     * Flag determining if user currently hovers drop area.
     *
     * @property isActive
     * @type {Boolean}
     * @default false
     * @public
     */
    isActive: false,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets `dropEffect` for event's `dataTransfer`.
     *
     * @method dragOver
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    dragOver: function dragOver(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.dataTransfer.dropEffect = 'copy';
    },

    /**
     * Sets `isActive` to true.
     *
     * @method dragEnter
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    dragEnter: function dragEnter(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.set('isActive', true);
    },

    /**
     * Sets `isActive` to false.
     *
     * @method dragLeave
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    dragLeave: function dragLeave(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.set('isActive', false);
    },

    /**
     * Reads file from event's `dataTransfer` and
     * calls provided callback action if provided.
     *
     * @method drop
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    drop: function drop(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      var files = evt.dataTransfer.files;
      var param = !Ember.get(this, 'multiple') ? files[0] : files;
      var invalidFiles = (0, _bulkFileHelper.validateFiles)(Ember.get(this, 'allowedTypes'), param);

      if (Ember.get(invalidFiles, 'length') !== 0) {
        this.invokeCallback('onInvalid', invalidFiles);
        Ember.set(this, 'files', []);
        return;
      }

      Ember.set(this, 'files', files);
      this.invokeCallback('onChange', param);
    }
  });

  _exports.default = _default;
});