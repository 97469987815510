define("ember-svg-jar/inlined/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M1.5 14.5h11V5.276a18.11 18.11 0 01-1.285-1c-.239-.205-.464-.411-.674-.618l-.086-.087c-.25-.253-.508-.536-.771-.843a25.28 25.28 0 01-.98-1.228H1.5v13zM0 0h9.467s1.078 1.552 2.129 2.59C12.646 3.63 14 4.48 14 4.48V16H0V0z\"/><path d=\"M10.752 3.48l.09.09.493.43H10V2.633l.752.847zM9 0l2.5 2.816L14 5H9V0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
  _exports.default = _default;
});