define("ember-svg-jar/inlined/categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M13.485 8.643H5.007c-.585 0-1.06.482-1.06 1.077s.475 1.077 1.06 1.077h8.478c.585 0 1.06-.482 1.06-1.077s-.475-1.077-1.06-1.077zm0-4.714H5.007c-.585 0-1.06.482-1.06 1.077 0 .594.475 1.077 1.06 1.077h8.478c.585 0 1.06-.483 1.06-1.077 0-.595-.475-1.077-1.06-1.077zM5.007 2.154h8.478c.585 0 1.06-.482 1.06-1.077S14.07 0 13.484 0H5.007c-.585 0-1.06.482-1.06 1.077s.475 1.077 1.06 1.077z\"/><ellipse cx=\"1.076\" cy=\"1.093\" rx=\"1.076\" ry=\"1.093\"/><ellipse cx=\"1.076\" cy=\"5.021\" rx=\"1.076\" ry=\"1.093\"/><ellipse cx=\"1.076\" cy=\"9.736\" rx=\"1.076\" ry=\"1.093\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "11",
      "viewBox": "0 0 15 11"
    }
  };
  _exports.default = _default;
});