define("ember-bulk-manager/templates/sub/bulk-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83D0ZFqm",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bulk-progress-bar-content\"],[9],[14,1],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"bulk-progress-bar-indicator\"],[12,\"style\",[21,\"style\"]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/sub/bulk-progress-bar.hbs"
    }
  });

  _exports.default = _default;
});