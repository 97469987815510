define("@vollersgroup/ember-cropster-common/components/info-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g1+d5XbT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"svg-icon\",[[23,[\"icon\"]]],[[\"class\"],[[23,[\"iconClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[27,\"if\",[[23,[\"icon\"]],\"message__wrapper\"],null]],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"message__title\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/ember-cropster-common/components/info-message/template.hbs"
    }
  });

  _exports.default = _default;
});