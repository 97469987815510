define("ember-svg-jar/inlined/check-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:none;stroke:#fff;stroke-width:2;stroke-linecap:square}</style><title>Line + Line Copy</title><g id=\"Page-1\"><g id=\"Line-_x2B_-Line-Copy\" transform=\"translate(2 1)\"><path id=\"Line\" class=\"st0\" d=\"M0 4l3 3\"/><path id=\"Line-Copy\" class=\"st0\" d=\"M3.5 6.5L9 1\"/></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 13 10",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});