define("ember-svg-jar/inlined/cupping-labels-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#6E8595\" d=\"M8 0h11.494v11H8V0zm3.462 8.8V2.153h2.31c.769 0 1.37.151 1.803.454.432.303.648.756.648 1.358 0 .302-.09.57-.272.808a1.603 1.603 0 01-.753.53c.413.088.731.278.954.57.222.293.334.632.334 1.019 0 .624-.213 1.098-.64 1.422-.426.324-1.014.486-1.765.486h-2.619zm1.16-3.022v2.136h1.46c.4 0 .709-.087.925-.262.216-.175.324-.429.324-.76 0-.353-.09-.627-.272-.822-.18-.195-.464-.292-.849-.292h-1.588zm0-.827H13.9c.36 0 .643-.081.852-.244.208-.163.312-.393.312-.692 0-.328-.11-.573-.329-.732-.22-.16-.54-.24-.964-.24h-1.15v1.908z\"/><g fill=\"#79BB43\"><path d=\"M0 4h11.494v11H0V4zm7.032 7.295H4.313l-.553 1.552H2.586L5.138 6.2h1.098l2.523 6.647H7.581l-.549-1.552z\"/><path d=\"M4.647 10.368h2.056L5.697 7.529h-.029z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "15",
      "viewBox": "0 0 20 15"
    }
  };
  _exports.default = _default;
});