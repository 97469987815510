define("ember-bulk-manager/components/sub/bulk-import-prefill-field-json", ["exports", "ember-bulk-manager/components/sub/bulk-select"], function (_exports, _bulkSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-prefill-field` representing field from type json.
   *
   * ```html
   * {{sub/bulk-import-prefill-field-json availableField=availableField}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(value)
   *
   * @namespace Component
   * @class BulkImportPrefillFieldJson
   * @extends BulkSelect
   * @public
   */
  var _default = _bulkSelect.default.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Multiple attribute of <select> element.
     *
     * @property multiple
     * @type {Boolean}
     * @default false
     * @public
     */
    multiple: Ember.computed('availableField.prefillOptions.multiple', {
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'availableField.prefillOptions.multiple') || false;
      }
    }),

    /**
     * Id attribute of <input> element.
     *
     * @attribute id
     * @type {String}
     * @public
     */
    id: Ember.computed.reads('elementId'),

    /**
     * Text for prompt option.
     *
     * @property prompt
     * @type {String}
     * @public
     */
    prompt: Ember.computed({
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'l10n').t('Please choose...');
      }
    }),

    /**
     * If true, bound property will be saved, otherwise
     * only corresponding idProperty (`value` or `id`).
     *
     * @attribute reference
     * @type {Boolean}
     * @default true
     * @public
     */
    reference: Ember.computed('availableField.prefillOptions.reference', {
      get: function get()
      /* key*/
      {
        var option = Ember.get(this, 'availableField.prefillOptions.reference');
        return Ember.isPresent(option) ? option : true;
      }
    }),

    /**
     * Property to be used as label for <select>
     *
     * @attribute labelProperty
     * @type {Mixed}
     * @default 'label'
     * @public
     */
    labelProperty: Ember.computed('availableField.prefillOptions.labelProperty', {
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'availableField.prefillOptions.labelProperty') || 'label';
      }
    }),

    /**
     * Selected option for <select> linked to `default` of `availableField`.
     *
     * @attribute selected
     * @type {Mixed}
     * @public
     */
    selected: Ember.computed.alias('availableField.default'),

    /**
     * Options collection for <select> linked to `prefillOptions.dataSet` of `availableField`.
     *
     * @attribute options
     * @type {Array}
     * @public
     */
    options: Ember.computed.reads('availableField.prefillOptions.source')
  });

  _exports.default = _default;
});