define("ember-bulk-manager/templates/sub/bulk-drop-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DlFqo+dj",
    "block": "{\"symbols\":[\"file\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"supportsFileAPI\"]]],null,{\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"hasFiles\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"files\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"bulk-drop-area-file\"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"bulk-drop-area-label\"],[9],[0,\"\\n        \"],[1,[21,\"computedLabel\"],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/sub/bulk-drop-area.hbs"
    }
  });

  _exports.default = _default;
});