define("ember-svg-jar/inlined/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M3 0h13v2H3zM0 0h2v2H0zM3 4h13v2H3zM0 4h2v2H0zM3 8h13v2H3zM0 8h2v2H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "10",
      "viewBox": "0 0 16 10"
    }
  };
  _exports.default = _default;
});