define("ember-date-components/components/time-picker", ["exports", "ember-date-components/templates/components/time-picker", "moment", "ember-date-components/utils/parse-time", "ember-date-components/utils/build-time-range", "ember-date-components/utils/should-use-am-pm"], function (_exports, _timePicker, _moment, _parseTime, _buildTimeRange, _shouldUseAmPm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * An input field to choose a time in a day.
   * The user can either enter a time directly, or choose from a list.
   *
   * @namespace EmberDateComponents.Component
   * @class TimePicker
   * @extends Ember.Component
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _timePicker.default,
    classNames: ['time-picker__wrapper'],
    classNameBindings: ['isOpen:time-picker__wrapper--open'],

    /**
     * The current value of the time picker.
     * Has to be a moment.js object or null.
     *
     * @attribute value
     * @type {Date}
     * @public
     */
    value: null,

    /**
     * If this is true, the time picker is disabled and the selected time cannot be changed.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * The action to call when the time changes.
     *
     * @event action
     * @param {Date} time The new time
     * @public
     */
    action: null,

    /**
     * If the display format should use am/pm or the 24:00 format.
     * By default, this will be determined by checking the localized date formatting of moment.js.
     * However, if you don't use moment.js-locales, this will always return true (because the default locale, 'en', uses am/pm).
     * In such a case, you can just overwrite this.
     *
     * @attribute amPm
     * @type {Boolean}
     * @public
     */
    amPm: null,

    /**
     * The minimum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '00:00'
     * @public
     */
    minTime: '00:00',

    /**
     * The maximum time which can be selected.
     * This should be either a parseable string or a moment.js object.
     *
     * @attribute minTime
     * @type {String|Object}
     * @default '23:59'
     * @public
     */
    maxTime: '23:59',

    /**
     * The placeholder for the time input.
     *
     * @attribute placeholder
     * @type {String}
     * @public
     */
    placeholder: 'Enter time...',

    /**
     * The step in minutes which can be selected.
     * Entered times will be rounded to this accuracy.
     * If you don't specify a selectStep separately, this value will also be used as selectStep.
     *
     * @attribute step
     * @type {Number}
     * @default 30
     * @public
     */
    step: 30,

    /**
     * The step from which dates can be selected in the dropdown.
     * If this is not explicitly set, step will be used for this value as well.
     * However, if values like 22:14 should be allowed but not shown in the dropdown,
     * this can be set to a different value.
     *
     * @attribute selectStep
     * @type {Number}
     * @default 30
     * @public
     */
    selectStep: null,

    /**
     * Classes which should be added to the input.
     *
     * @attribute inputClasses
     * @type {String}
     * @public
     */
    inputClasses: '',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * @attribute value
     * @type {Boolean}
     * @public
     */
    renderInPlace: false,

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are right|center|left
     *
     * @attribute value
     * @type {String}
     * @public
     */
    horizontalPosition: 'auto',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * Available values are above|below
     *
     * @attribute value
     * @type {String}
     * @public
     */
    verticalPosition: 'auto',

    /**
     * Value passed to `ember-basic-dropdown`
     *
     * @attribute matchTriggerWidth
     * @type {Boolean}
     * @default true
     * @public
     */
    matchTriggerWidth: true,

    /**
     * Classes which should be added to the dropdown container.
     *
     * @attribute dropdownClasses
     * @type {String}
     * @public
     */
    dropdownClasses: '',

    /**
     * Optional classes for the button.
     *
     * @attribute buttonClasses
     * @type {String}
     * @optional
     * @public
     */
    buttonClasses: '',

    /**
     * If the dropdown is open.
     *
     * @property isOpen
     * @type {Boolean}
     * @protected
     */
    isOpen: false,

    /**
     * Which option is currently selected.
     * If -1, no option is selected.
     *
     * @property selectedOptionIndex
     * @type {Number}
     * @protected
     */
    selectedOptionIndex: -1,

    /**
     * The general options for this component.
     * These are built from the single attributes, but you could theoretically overwrite this if you need custom behaviour.
     * The options should always be fetched via this object.
     *
     * @property options
     * @type {amPm, step, minTime, maxTime}
     * @protected
     */
    options: Ember.computed('step', 'amPm', 'minTime', 'maxTime', 'selectStep', function () {
      var amPm = this.amPm,
          minTime = this.minTime,
          maxTime = this.maxTime,
          step = this.step,
          selectStep = this.selectStep;
      return {
        amPm: amPm,
        step: step,
        selectStep: selectStep,
        minTime: (0, _parseTime.default)(minTime),
        maxTime: (0, _parseTime.default)(maxTime)
      };
    }),

    /**
     * The format which should be used.
     * By default, this is computed via the amPm setting.
     * You can overwrite this if necessary.
     *
     * @property format
     * @type {String}
     * @protected
     */
    format: Ember.computed('options.amPm', function () {
      var amPm = this.options.amPm;
      return amPm ? 'hh:mm a' : 'HH:mm';
    }),

    /**
     * The options to chose from in the dropdown.
     *
     * @property timeOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    timeOptions: Ember.computed('format', 'options.{maxTime,minTime,selectStep}', function () {
      var _this$options = this.options,
          minTime = _this$options.minTime,
          maxTime = _this$options.maxTime,
          selectStep = _this$options.selectStep;
      var format = this.format;
      var steps = (0, _buildTimeRange.default)({
        minTime: minTime,
        maxTime: maxTime,
        step: selectStep
      });
      return steps.map(function (time) {
        return {
          value: time.format(format),
          time: time
        };
      });
    }),

    /**
     * The options for the dropdown which are currently visible.
     * This filters the timeOptions by the inputValue.
     *
     * @property filteredOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    filteredOptions: Ember.computed('timeOptions.[]', 'inputValue', function () {
      var val = (this.inputValue || '').toLowerCase();
      var options = this.timeOptions;
      return options.filter(function (option) {
        var optionValue = option.value;
        return optionValue.toLowerCase().indexOf(val) > -1;
      });
    }),

    /**
     * The value that is currently entered in the input field.
     *
     * @property inputValue
     * @type {String}
     * @protected
     */
    inputValue: null,

    /**
     * The API of ember-basic-dropdown.
     * This is used to manually open/close the dropdown.
     *
     * @property _dropdownApi
     * @type {Object}
     * @private
     */
    _dropdownApi: null,

    /**
     * The value actual value to display in the button.
     *
     * @property displayValue
     * @type {String}
     * @readOnly
     * @protected
     */
    displayValue: Ember.computed('format', 'value', function () {
      var value = this.value,
          format = this.format;
      value = (0, _parseTime.default)(value);
      value = _moment.default.isMoment(value) ? value.format(format) : value;
      return value || null;
    }),
    actions: {
      selectValue: function selectValue(value) {
        this._updateValueForString(value);
      },
      selectCurrent: function selectCurrent() {
        this._selectCurrent();
      },
      selectUp: function selectUp() {
        this.decrementProperty('selectedOptionIndex');

        if (this.selectedOptionIndex < -1) {
          Ember.set(this, 'selectedOptionIndex', -1);
        }
      },
      selectDown: function selectDown() {
        this.incrementProperty('selectedOptionIndex');
        var optionsLength = this.filteredOptions.length;

        if (this.selectedOptionIndex > optionsLength) {
          Ember.set(this, 'selectedOptionIndex', optionsLength - 1);
        }
      },
      updateInputValue: function updateInputValue(value) {
        Ember.set(this, 'inputValue', value);
        Ember.set(this, 'selectedOptionIndex', -1);
      },
      onDropdownOpened: function onDropdownOpened(dropdownApi) {
        Ember.set(this, 'isOpen', true);
        Ember.set(this, '_dropdownApi', dropdownApi);

        this._focusTimeInput();
      },
      onDropdownClosed: function onDropdownClosed() {
        Ember.set(this, 'isOpen', false);
        Ember.set(this, 'inputValue', null);
        Ember.set(this, 'selectedOptionIndex', -1);
      },
      onTriggerKeyDown: function onTriggerKeyDown(dropdownApi, event) {
        // If the input is focused, and the user starts typing a number or letter, we want to auto-open the dropdown
        var key = event.key;
        var regex = /^[\d\w]$/;

        if (regex.test(key)) {
          dropdownApi.actions.open(); // Add to the input, in order to not lose the typed characters

          var inputValue = this.inputValue || '';
          Ember.set(this, 'inputValue', "".concat(inputValue).concat(key));
        }
      },
      closeDropdown: function closeDropdown() {
        this._close();
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this._setupDefaults();
    },
    _setupDefaults: function _setupDefaults() {
      if (Ember.isNone(this.amPm)) {
        Ember.set(this, 'amPm', (0, _shouldUseAmPm.shouldUseAmPm)());
      }
    },
    _close: function _close() {
      var dropdownApi = this._dropdownApi;

      if (dropdownApi) {
        dropdownApi.actions.close();
      }
    },
    _selectCurrent: function _selectCurrent() {
      var options = this.filteredOptions;
      var selected = this.selectedOptionIndex; // If nothing is selected, simply try to parse the entered string

      if (selected === -1) {
        var inputValue = this.inputValue;

        this._updateValueForString(inputValue);

        return;
      }

      var selectedOption = options[selected]; // If, for whatever reason, the selected options doesn't exist
      // abort - but this shouldn't actually happen

      if (!selectedOption) {
        return;
      } // Actually get the string value from the option


      var value = selectedOption.value;

      this._updateValueForString(value);
    },
    _updateValueForString: function _updateValueForString(stringValue) {
      var parsedValue = (stringValue || '').toLowerCase();
      var newValue = (0, _parseTime.default)(parsedValue);

      this._sendNewValueAction(newValue); // Now close the input


      this._close();
    },
    _sendNewValueAction: function _sendNewValueAction(newValue) {
      var value = this.value,
          action = this.action,
          isDisabled = this.disabled;

      if (action && !isDisabled && value !== newValue) {
        return action(newValue);
      }
    },

    /**
     * Move the focus to the date picker.
     * This is called when `_open` is called, to ensure that the date picker can be used with the keyboard.
     * This will also save the previously focused element, to ensure we can correctly return the focus later.
     *
     * @method _focusDatePicker
     * @private
     */
    _originallyFocusedElement: null,
    _focusTimeInput: function _focusTimeInput() {
      if (this.isDestroyed) {
        return;
      }

      var originallyFocusedElement = document.activeElement;
      Ember.set(this, '_originallyFocusedElement', originallyFocusedElement);
      var elementId = this.elementId;
      Ember.run.next(function () {
        var timeInput = document.querySelector("[data-time-picker-input-instance=\"".concat(elementId, "\"]"));

        if (timeInput && timeInput !== document.activeElement) {
          timeInput.focus();
        }
      });
    },

    /**
     * Reset the focus to the previously focused element.
     * This is called when the date picker is closed.
     *
     * @method _resetFocus
     * @private
     */
    _resetFocus: function _resetFocus() {
      var originallyFocusedElement = this._originallyFocusedElement;
      Ember.set(this, '_originallyFocusedElement', null);

      if (originallyFocusedElement && document.body.contains(originallyFocusedElement)) {
        Ember.run.next(function () {
          return originallyFocusedElement.focus();
        });
      }
    }
  });

  _exports.default = _default;
});