define('ember-prism/components/code-inline', ['exports', 'ember-prism/components/code-base'], function (exports, _codeBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _codeBase.default.extend({
    tagName: 'code',
    classNames: ['code-inline'],
    inline: true
  });
});