define("ember-bulk-manager/services/bulk-lookup-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A proxy service to provide data from hosting application to addon. These
   * data include available fields within hosting application's database model
   * to be used for mapping imports, prefill fields and data grid. Furthermore,
   * the service acts as data provider for all relational data, which is used
   * for both selection within data grid and fuzzy matching lookups.
   *
   * @namespace Service
   * @class BulkLookupData
   * @extends Ember.Service
   * @public
   */
  var _default = Ember.Service.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Available fields from hosting application. These fields MUST be set
     * in order to map fields from an imported file to hosting app fields.
     * Besides, these fields are used to setup prefill component and
     * data grid with handsontable.
     *
     * Fields are a hash map and MUST have the following JSON structure:
     *
     * {
     *   value1:{
     *     value: <String>,
     *     label: <String>,
     *     default: <Mixed>,
     *     hidden: <Boolean>,
     *     prefillOptions:{
     *       type: <String>,
     *       skip: <Boolean>,
     *       source: <Array>,
     *       format: <String>,
     *       disabled: <Boolean>,
     *       multiple: <Boolean>,
     *       labelProperty: <String>
     *     },
     *     gridOptions:{
     *       // Used for `columns` option
     *       // of Handsontable creation
     *     }
     *   },
     *   value2:{
     *     value: <String>,
     *     label: <String>,
     *     default: <Mixed>,
     *     hidden: <Boolean>,
     *     prefillOptions:{
     *       type: <String>,
     *       skip: <Boolean>,
     *       source: <Array>,
     *       format: <String>,
     *       disabled: <Boolean>,
     *       multiple: <Boolean>,
     *       labelProperty: <String>
     *     },
     *     gridOptions:{
     *       // Used for `columns` option
     *       // of Handsontable creation
     *     }
     *   },
     * }
     *
     * Explanation of single properties:
     *
     * - `value`: An unique identifier for this field.
     * - `label`: A human-readable text for this field.
     * - `default`: A default value for this field (= optional).
     * - `hidden`: Indicator if this field is visible in grid (= optional).
     * - `prefillOptions`: Dedicated options for `bulk-import-prefill` component (= optional):
     * - `prefillOptions.skip`: If this flag is set to true, the field won't show up in prefill (= optional).
     * - `prefillOptions.source`: For type 'json', this should supply lookup data (= optional).
     * - `prefillOptions.multiple`: For type 'json', this indicates multiple choices (= optional).
     * - `prefillOptions.labelProperty`: For type 'json', this incidates the label property (= optional).
     * - `prefillOptions.format`: For type 'date' this should supply a predefined date format (= optional).
     * - `prefillOptions.type`: One of 'string', 'numeric', 'checkbox', 'timestamp', 'json' (= optional).
     * - `prefillOptions.disabled`: Whether or not the <input> or <select> field should be disabled (=optional).
     * - `gridOptions`: Dedicated `column` options only used by `bulk-grid` component (= optional):
     *    Please refer to Handsontable docs http://docs.handsontable.com/0.28.4/ and `json-editor` documentation
     *    for all possible `column` options, which will be attached to grid's default options.
     *
     * @property availableFields
     * @type {Object|Array}
     * @default {}
     * @public
     */
    availableFields: Ember.computed(function () {
      return {};
    })
  });

  _exports.default = _default;
});