define("ember-bulk-manager/services/bulk-fuzzy-match", ["exports", "ember-copy", "ember-inflector", "ember-bulk-manager/utils/bulk-errors", "ember-bulk-manager/utils/bulk-comparison-helper"], function (_exports, _emberCopy, _emberInflector, _bulkErrors, _bulkComparisonHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A service performing fuzzy matching within a provided data set.
   *
   * @namespace Service
   * @class BulkFuzzyMatch
   * @extends Ember.Service
   * @extends Ember.Evented
   * @public
   */
  var _default = Ember.Service.extend(Ember.Evented, {
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    bulkAssetLoader: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Most common separators used in texts.
     *
     * @property separators
     * @type {String}
     * @default ".,;:|/\\\\"
     * @public
     */
    separators: '.,;:|/\\\\',

    /**
     * Linking words used to split input by most common separators:
     * Please refer to: http://www.smart-words.org/linking-words/
     *
     * @property alternations
     * @type {Array}
     * @private
     */
    alternations: Ember.computed(function () {
      return [// 1) punctuation
      "[".concat(Ember.get(this, 'separators'), "]"), // 2) conjunctions
      '(?:\\s+for|for\\s+)', '(?:\\s+and|and\\s+)', '(?:\\s+nor|nor\\s+)', '(?:\\s+but|but\\s+)', '(?:\\s+or|or\\s+)', '(?:\\s+yet|yet\\s+)', '(?:\\s+so|so\\s+)', // 3) transitions
      '(?:\\s+in the first place|in the first place\\s+)', '(?:\\s+not only|not only\\s+)', '(?:\\s+but also|but also\\s+)', '(?:\\s+as a matter of fact|as a matter of fact\\s+)', '(?:\\s+in like manner|in like manner\\s+)', '(?:\\s+in addition|in addition\\s+)', '(?:\\s+coupled with|coupled with\\s+)', '(?:\\s+in the same (?:way|fashion)|in the same (?:way|fashion)\\s+)', '(?:\\s+(?:first|second|third)|(?:first|second|third)\\s+)', '(?:\\s+in the light of|in the light of\\s+)', '(?:\\s+not to mention|not to mention\\s+)', '(?:\\s+to say nothing of|to say nothing of\\s+)', '(?:\\s+equally important|equally important\\s+)', '(?:\\s+by the same token|by the same token\\s+)', '(?:\\s+again|again\\s+)', '(?:\\s+to|to\\s+)', '(?:\\s+and|and\\s+)', '(?:\\s+also|also\\s+)', '(?:\\s+then|then\\s+)', '(?:\\s+equally|equally\\s+)', '(?:\\s+identically|identically\\s+)', '(?:\\s+uniquely|uniquely\\s+)', '(?:\\s+like|like\\s+)', '(?:\\s+as|as\\s+)', '(?:\\s+too|too\\s+)', '(?:\\s+moreover|moreover\\s+)', '(?:\\s+as well as|as well as\\s+)', '(?:\\s+toghether with|toghether with\\s+)', '(?:\\s+of course|of course\\s+)', '(?:\\s+likewise|likewise\\s+)', '(?:\\s+comparatively|comparatively\\s+)', '(?:\\s+correspondingly|correspondingly\\s+)', '(?:\\s+similarly|similarly\\s+)', '(?:\\s+furthermore|furthermore\\s+)', '(?:\\s+additionally|additionally\\s+)'];
    }),

    /**
     * Flag indicating if library's loaded.
     *
     * @property isReady
     * @type {Boolean}
     * @default false
     * @public
     */
    isReady: false,

    /**
     * Base options used for invoking a fuzzy search.
     * Please refer to: https://github.com/krisk/fuse.
     *
     * All settings has been tested and should not be
     * adapated unless you know what you are doing. The
     * only properties which should be adapted depending
     * on your data set are `keys` and `id` when invoking
     * search() method with `options` parameter:
     *
     * `keys`:
     * Key names of object to look for matches. This is
     * only required if your dataset consists of objects
     * within an array.
     *
     * `id`
     * Key used as result for successful matches. If this
     * is omitted, the original items will go into results,
     * otherwise only a list of `id` properties.
     *
     * @property _defaultOptions
     * @type {Object}
     * @private
     */
    _defaultOptions: Ember.computed(function () {
      return {
        minMatchCharLength: 5,
        findAllMatches: false,
        matchAllTokens: true,
        maxPatternLength: 50,
        caseSensitive: false,
        shouldSort: true,
        tokenize: true,
        threshold: 0.4,
        distance: 50,
        location: 0,
        includeMatches: true,
        includeScore: true,
        // important: put label
        // properties at the end
        keys: [// id props
        'id', 'value', // label props
        'label', 'name'],
        id: null
      };
    }),

    /**
     * Temporary cache for ember models and objects, which
     * needs conversion to POJO to work with Fuse.js, as it
     * doesn't know anything about Ember's getter for lookup.
     *
     * @property _objectCache
     * @type {Object}
     * @private
     */
    _objectCache: Ember.computed(function () {
      return {};
    }),

    /**
     * Temporary cache for single results to store score.
     * This will we used to compare multiple matches by
     * fuzzy matching which one gets precedence.
     *
     * @property _scoreCache
     * @type {Number}
     * @private
     */
    _scoreCache: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads Fuse JS on initialization.
     * The method will trigger the events:
     *
     * - `bulk_fuzzy_match_ready`
     *
     * @method init
     * @return {Void}
     * @public
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // load Fuse API once


      var bulkAssetLoader = Ember.get(this, 'bulkAssetLoader');
      bulkAssetLoader.loadBundle('fuse').then(function () {
        Ember.set(_this, 'isReady', true);

        _this.trigger('bulk_fuzzy_match_ready');
      });
    },
    destroy: function destroy() {
      this._super.apply(this, arguments); // set(this, 'isReady', false);

    },

    /**
     * Filters data set for exact matches in a first step. Performs a fuzzy search
     * as a second step and concatenates results to comma seperated string list.
     *
     * @public
     * @method search
     * @param {String} needle Input search string.
     * @param {Array} dataSet Lookup data for fuzzy search containing an array of objects.
     * @param {Boolean} [multiple = true] If false, only one result per search is allowed.
     * @param {Boolean} [onlyBest = true] If true, only best match gets added to results.
     * @param {Boolean} [maxScore = 0.5] Maximum score accepted as valid match for search.
     * @param {Object} [options] Optional hash of customizeable search options for Fuse JS.
     * @param {Array} [options.keys = ['name','label']] Key names of array objects to look for matches.
     * @param {String} [options.id = 'id'] Key name of array object to provide as result for successful matches.
     * @throws DependencyNotResolvedError
     * @throws WrongParameterTypeError
     * @return {Array}
     */
    search: function search(needle, dataSet) {
      var _this2 = this;

      var multiple = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var onlyBest = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
      var maxScore = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0.4;
      var options = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};

      // assert that dependency has been resolved yet
      if (!Ember.get(this, 'isReady')) {
        throw new _bulkErrors.DependencyNotResolvedError(Ember.get(this, 'l10n').t('The service is not ready yet, please wait for `isReady` flag or listen to events.'));
      } // assert that `dataSet` parameter is an array


      if (!Ember.isArray(dataSet)) {
        throw new _bulkErrors.WrongParameterTypeError(Ember.get(this, 'l10n').t('`dataSet` parameter has to be an array!'));
      } // initialize default options


      var _defaultOptions = (0, _emberCopy.copy)(Ember.get(this, '_defaultOptions'), true);

      options = Ember.assign(_defaultOptions, options || {}); // setup temporary result cache

      var results = []; // assert needle is a string type

      if (Ember.typeOf(needle) !== 'string') {
        try {
          needle = needle.toString();
        } catch (e) {
          return results;
        }

        var _regex = /\[object (.*)\]/;

        if (needle.match(_regex)) {
          return results;
        }
      } // don't process empty needles


      if (Ember.isBlank(needle)) {
        return results;
      } // ----------------------------------------
      // 1) Try for exact matches first for query
      // ----------------------------------------


      for (var i = 0; i < dataSet.length; i++) {
        var item = dataSet[i];
        var id = Ember.get(options, 'id');
        var result = !Ember.isEmpty(id) ? Ember.get(item, id) : item;

        for (var j = 0; j < options.keys.length; j++) {
          var value = this._extractNeedle(item, options.keys[j]);

          if (Ember.isEmpty(value)) {
            continue;
          }

          var normalizedValue = value.toLowerCase().trim();
          var normalizedNeedle = needle.toLowerCase().trim();

          if (normalizedValue === normalizedNeedle) {
            // exact matches with needle ALWAYS get
            // precedence over other lookups and so
            // no multiple result sets are possible!
            return [result];
          }

          var _alternations = this._buildPatterns(value, needle, !multiple).join('|');

          var _regex2 = new RegExp("(?:".concat(_alternations, ")"), 'i');

          var match = needle.match(_regex2);

          if (Ember.isNone(match)) {
            continue;
          }

          this._addResult(results, result, multiple, options);
        }
      } // ----------------------------------------
      // 2) Try fuzzy search for remaining query
      // ----------------------------------------


      var alternations = Ember.get(this, 'alternations').join('|');
      var regex = new RegExp("(?:".concat(alternations, ")"), 'i');

      var fuzzy = this._searchFactory(dataSet, options);

      var queries = needle.split(regex); // callback for results to be validated

      var _validateMatch = function _validateMatch(match) {
        if (Ember.isNone(match)) {
          return false;
        }

        return match.score < maxScore;
      }; // callback for results to be evaluated


      var _processMatch = function _processMatch(match, matches) {
        if (!_validateMatch(match)) {
          return;
        }

        var score = _this2._extractScore(match, options);

        var result = _this2._extractResult(match, options, dataSet);

        _this2._addResult(results, result, multiple, options, score, dataSet, matches);
      }; // perform actual search and concatenate
      // search results as comma separated list


      var _loop = function _loop(_i) {
        var query = queries[_i].trim();

        if (Ember.isBlank(query)) {
          return "continue";
        }

        var result = fuzzy.search(query);

        if (onlyBest) {
          _processMatch(result[0], result);

          return "continue";
        }

        result.forEach(function (item) {
          return _processMatch(item, result);
        });
      };

      for (var _i = 0; _i < queries.length; _i++) {
        var _ret = _loop(_i);

        if (_ret === "continue") continue;
      } // ----------------------------------------
      // 3) Provide results
      // ----------------------------------------


      Ember.set(this, '_scoreCache', null);
      Ember.set(this, '_objectCache', {});
      return results;
    },

    /**
     * Tries to extract comparison needle
     * from `item`, which could be mixed,
     * but will always return a string.
     *
     * @method  _extractNeedle
     * @param {Mixed} item
     * @param {String} key
     * @return {String}
     * @private
     */
    _extractNeedle: function _extractNeedle(item, key) {
      var value;

      switch (Ember.typeOf(item)) {
        case 'object':
        case 'instance':
          if (Ember.isNone(item) || Ember.isEmpty(key)) {
            break;
          }

          value = Ember.get(item, key);
          break;

        default:
          value = item;
      }

      return value ? String(value) : '';
    },

    /**
     * Extracts corresponding value from
     * fuzzy match. Either delivers item
     * from index position, object value
     * or cached ember model instance.
     *
     * @method  _extractResult
     * @param {Mixed} match
     * @param {Array} options
     * @param {Array} dataSet
     * @return {Mixed}
     * @private
     */
    _extractResult: function _extractResult(match, options, dataSet) {
      var result = options.includeMatches ? match.item : match;

      switch (Ember.typeOf(result)) {
        case 'number':
          {
            result = dataSet[result];
            break;
          }

        case 'object':
          {
            var key = Ember.get(match, 'item.__key');
            var obj = Ember.get(this, "_objectCache.".concat(key));
            result = Ember.isNone(obj) ? match.item : obj;
            break;
          }

        default:
      }

      return result;
    },

    /**
     * Extracts `score` from fuzzy match.
     *
     * @method  _extractScore
     * @param {Mixed} match
     * @param {Array} options
     * @return {Mixed}
     * @private
     */
    _extractScore: function _extractScore(match, options) {
      return options.includeScore ? match.score : 0;
    },

    /**
     * Builds regex patterns for exact search by value.
     *
     * @method _buildPatterns
     * @param {String} value
     * @param {String} needle
     * @param {Boolean} strict
     * @return {Array}
     * @private
     */
    _buildPatterns: function _buildPatterns(value, needle, strict) {
      value = this._sanitizeInput(value);
      var patterns = [];
      var intRegex = /^\d+$/;
      var listRegex = /^(\d+\s*[,]?\s*)+$/;
      var separators = Ember.get(this, 'separators');

      var _idRegex = function _idRegex(value) {
        return "(?:^|[^\\d]|,)(".concat(value, ")(?:[^\\d]|,|$)");
      };

      var _stringRegex = function _stringRegex(value) {
        if (strict) {
          return "^(".concat(value, ")$");
        }

        return "(?:^|\\s+|[".concat(separators, "])(").concat(value, ")(?:\\s+|[").concat(separators, "]|$)");
      };

      if (value.match(intRegex) && needle.match(listRegex)) {
        // special case: search needle is comma separated list
        // and input value is id (= integer only) lookup value
        patterns.push(_idRegex(value));
      } else {
        // otherwise try to match by inflected string values
        // taking separators and possible whitespace into account
        patterns.push(_stringRegex((0, _emberInflector.singularize)(value)));
        patterns.push(_stringRegex((0, _emberInflector.pluralize)(value)));
      }

      return patterns;
    },

    /**
     * Escapes an input string for usage within regex pattern.
     *
     * @method _sanitizeInput
     * @param {String} value
     * @return {String}
     * @private
     */
    _sanitizeInput: function _sanitizeInput(value) {
      var escape = [// order matters for these
      '-', '[', ']', // order doesn't matter for any of these
      '/', '{', '}', '(', ')', '*', '+', '?', '.', '\\', '^', '$', '|'].join('\\');
      var regex = new RegExp("[".concat(escape, "]"), 'g'); // replace escape characters on trimmed value

      value = value.trim().replace(regex, '\\$&'); // special escape for whitespace characters

      value = value.replace(/\s+/g, '\\s*');
      return value;
    },

    /**
     * Primitive method to add a new match to results.
     *
     * @private
     * @method _addResult
     * @param {Array} stack
     * @param {String} result
     * @param {Boolean} [multiple=true]
     * @param {Object} [options={}]
     * @param {Number} [score=0]
     * @return {Void}
     */
    _addResult: function _addResult(stack, result) {
      var _this3 = this;

      var multiple = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      var score = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
      var dataSet = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : [];
      var matches = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : [];

      if (stack.indexOf(result) >= 0) {
        return;
      }

      var oldScore = Ember.get(this, '_scoreCache');
      var newScore = Ember.set(this, '_scoreCache', score);

      var isPreviousWeaker = function isPreviousWeaker() {
        for (var i = 0; i < matches.length; i++) {
          var currentResult = _this3._extractResult(matches[i], options, dataSet);

          var currentScore = _this3._extractScore(matches[i], options);

          var previousResult = stack[stack.length - 1];
          var isEqual = false;

          switch (Ember.typeOf(previousResult)) {
            case 'object':
              {
                isEqual = (0, _bulkComparisonHelper.compareObject)(currentResult, previousResult);
                break;
              }

            default:
              isEqual = currentResult == previousResult;
          }

          if (isEqual) {
            return currentScore > score;
          }
        }

        return false;
      };

      if (!multiple) {
        if (stack.length === 0) {
          stack[0] = result;
        } else {
          if (newScore < oldScore) {
            stack[0] = result;
          } else {
            if (isPreviousWeaker()) {
              stack[0] = result;
            }
          }
        }

        return;
      }

      if (isPreviousWeaker()) {
        var index = stack.length - 1;
        var prev = stack[index];
        stack[index] = result;
        stack.push(prev);
        return;
      }

      stack.push(result);
    },

    /**
     * Creates a new search instance.
     *
     * @method _searchFactory
     * @param {Array} dataSet
     * @param {Object} options
     * @return {Object}
     * @private
     */
    _searchFactory: function _searchFactory(dataSet, options) {
      var _this4 = this;

      var Search = this._getFuse();

      var data = dataSet;

      switch (Ember.typeOf(dataSet[0])) {
        case 'instance':
          {
            var keys = Ember.get(options, 'keys');
            data = data.map(function (object, index) {
              var json = {
                __key: index
              };
              Ember.assign(json, object.getProperties(keys));
              Ember.set(_this4, "_objectCache.".concat(index), object);
              return json;
            });
            break;
          }

        default:
      }

      return new Search(data, options);
    },

    /**
     * Gets reference to Fuse API object.
     *
     * @method _getFuse
     * @return {Object}
     * @private
     */
    _getFuse: function _getFuse() {
      return Ember.get(window, 'Fuse');
    }
  });

  _exports.default = _default;
});