define("ember-svg-jar/inlined/cupping-sheets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 1.996A2 2 0 011.99 0h12.796a2 2 0 011.99 1.996v7.773a2 2 0 01-1.99 1.996H1.99A2 2 0 010 9.769V1.996zm10.117 4.456a.497.497 0 00.135-.334.496.496 0 00-.135-.334.484.484 0 00-.662 0 .494.494 0 00-.135.334c0 .122.05.244.135.334a.477.477 0 00.331.136.488.488 0 00.33-.136zm1.067-.805a.468.468 0 00-.466.47c0 .26.208.471.466.471h2.796c.258 0 .466-.21.466-.47s-.208-.47-.466-.47h-2.796zm1.398 2.824h-2.33a.468.468 0 00-.466.47c0 .26.208.47.466.47h2.33c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zm1.533.136a.485.485 0 00-.135.334c0 .123.05.245.135.334.088.085.21.137.33.137a.488.488 0 00.332-.137.497.497 0 00.135-.334.487.487 0 00-.135-.334.484.484 0 00-.662 0zM2.796 6.588h5.126c.258 0 .466-.21.466-.47s-.208-.47-.466-.47H2.796a.468.468 0 00-.466.47c0 .26.208.47.466.47zm1.398 1.883H2.796a.468.468 0 00-.466.47c0 .26.208.47.466.47h1.398c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zm4.194 0h-2.33a.468.468 0 00-.466.47c0 .26.208.47.466.47h2.33c.258 0 .466-.21.466-.47s-.208-.47-.466-.47zM4.66 3.765h2.33c.258 0 .466-.21.466-.47 0-.261-.208-.471-.466-.471H4.66a.468.468 0 00-.466.47c0 .26.208.47.466.47zm-1.864 0a.488.488 0 00.33-.137.497.497 0 00.136-.334.496.496 0 00-.135-.334.484.484 0 00-.662 0 .494.494 0 00-.135.334c0 .127.05.245.135.334.088.085.21.137.331.137zm10.252 0h1.398c.258 0 .466-.21.466-.47 0-.261-.208-.471-.466-.471h-1.398a.468.468 0 00-.466.47c0 .26.208.47.466.47zm-1.398-.47c0-.261-.208-.471-.466-.471h-2.33a.468.468 0 00-.466.47c0 .26.208.47.466.47h2.33c.258 0 .466-.21.466-.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "12",
      "viewBox": "0 0 17 12"
    }
  };
  _exports.default = _default;
});