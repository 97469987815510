define("ember-data-copyable/-private/symbols", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.IS_COPYABLE = _exports.COPY_TASK_RUNNER = _exports.COPY_TASK = void 0;
  var COPY_TASK = '__DS_COPY_TASK__';
  _exports.COPY_TASK = COPY_TASK;
  var COPY_TASK_RUNNER = '__DS_COPY_TASK_RUNNER__';
  _exports.COPY_TASK_RUNNER = COPY_TASK_RUNNER;
  var IS_COPYABLE = '__DS_IS_COPYABLE__';
  _exports.IS_COPYABLE = IS_COPYABLE;
});