define("ember-launch-darkly/-sdk/variation", ["exports", "ember-launch-darkly/-sdk/context"], function (_exports, _context) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.variation = variation;

  function variation(key) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    var context = (0, _context.getCurrentContext)();

    if (!context.isLocal) {
      context.client.variation(key);
    }

    return context.get(key, defaultValue);
  }
});