define("ember-svg-jar/inlined/sync", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.083 9H0v5l1.481-1.361A8.001 8.001 0 0015.938 9h-2.021A6.001 6.001 0 012.97 11.271L5.441 9H2.083zM8 0A8.001 8.001 0 00.062 7h2.021a6.001 6.001 0 0111.041-2.124L11 7h5V2l-1.432 1.432A7.988 7.988 0 008 0z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});