define("ember-bulk-manager/components/sub/bulk-import-prefill-field-string", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-import-prefill-field-string"], function (_exports, _bulkComponent, _bulkImportPrefillFieldString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-prefill-field` representing field from type string.
   *
   * ```html
   * {{sub/bulk-import-prefill-field-string availableField=availableField}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(value)
   *
   * @namespace Component
   * @class BulkImportPrefillFieldString
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkImportPrefillFieldString.default,
    tagName: 'input',
    attributeBindings: ['value', 'type', 'id', 'disabled'],
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Id attribute of <input> element.
     *
     * @attribute id
     * @type {String}
     * @public
     */
    id: Ember.computed.reads('elementId'),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Type attribute of <input> element.
     *
     * @property type
     * @type {String}
     * @public
     */
    type: 'text',

    /**
     * Value attribute of <input> element.
     *
     * @property value
     * @type {String}
     * @public
     */
    value: Ember.computed.reads('availableField.default'),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets `default` value of field.
     *
     * @method change
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    change: function change(evt) {
      var value = evt.target.value;
      this.invokeCallback('onChange', value);
    }
  });

  _exports.default = _default;
});