define("ember-svg-jar/inlined/scale-weights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.69 6.013h.583c.126 0 .228.102.228.227a3.378 3.378 0 01-3.375 3.373A3.378 3.378 0 017.751 6.24c0-.125.103-.227.228-.227h.594a.37.37 0 01.057-.176l2.132-4.791h.712l2.16 4.791a.37.37 0 01.056.176zm-.78 0l-1.778-4.117-1.778 4.117h3.555zm-6.971 0h.583c.126 0 .228.102.228.227a3.378 3.378 0 01-3.375 3.373A3.378 3.378 0 010 6.24c0-.125.102-.227.228-.227h.593a.37.37 0 01.057-.176L3.01 1.046h.713l2.16 4.791a.37.37 0 01.056.176zm-.781 0L3.38 1.896 1.603 6.013h3.555zM1 0h12.722v1.003H1V0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "10",
      "viewBox": "0 0 15 10"
    }
  };
  _exports.default = _default;
});