define("ember-svg-jar/inlined/rating-yes-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.5 0C33.355 0 43 9.645 43 21.5S33.355 43 21.5 43 0 33.355 0 21.5 9.645 0 21.5 0zm11.796 25.034c-.496-.108-.986.17-1.102.618-1.18 4.523-5.577 7.681-10.692 7.681-5.121 0-9.52-3.163-10.694-7.692-.117-.449-.614-.721-1.102-.62-.493.106-.798.555-.681 1.003C10.396 31.309 15.528 35 21.502 35c5.967 0 11.097-3.685 12.473-8.962.117-.449-.187-.898-.679-1.004zM13.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm15 0a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43"
    }
  };
  _exports.default = _default;
});