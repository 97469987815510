define("ember-launch-darkly/-sdk/index", ["exports", "ember-launch-darkly/-sdk/initialize", "ember-launch-darkly/-sdk/identify", "ember-launch-darkly/-sdk/variation"], function (_exports, _initialize, _identify, _variation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _initialize.initialize;
    }
  });
  Object.defineProperty(_exports, "identify", {
    enumerable: true,
    get: function get() {
      return _identify.identify;
    }
  });
  Object.defineProperty(_exports, "variation", {
    enumerable: true,
    get: function get() {
      return _variation.variation;
    }
  });
});