define("ember-bulk-manager/templates/sub/bulk-file-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FD09TSlU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"supportsFileAPI\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"dragAndDrop\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"sub/bulk-drop-area\",null,[[\"files\",\"label\",\"multiple\",\"allowedTypes\",\"onChange\",\"onInvalid\"],[[23,[\"files\"]],[23,[\"dropLabel\"]],[23,[\"multiple\"]],[23,[\"allowedTypes\"]],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"action\",[[22,0,[]],\"onInvalid\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"button btn bulk-file-button-btn\"],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"bulk-file-button-input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],[27,\"action\",[[22,0,[]],\"onFile\"],null]],null]],[11,\"type\",\"file\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"buttonLabel\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"bulk-file-button-message-no-support\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Your browser does not support file uploads.\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/sub/bulk-file-button.hbs"
    }
  });

  _exports.default = _default;
});