define("ember-svg-jar/inlined/badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#333B46\"><path d=\"M1.855 11.289l-1.447 4.1 2.623-.875 1.492 2.327 1.465-4.15-1.392-1.212zM11.799 11.289l-2.74.19-1.393 1.212 1.465 4.15 1.492-2.327 2.623.875zM11.133 10.398l.17-2.453 1.615-1.854-1.615-1.854-.17-2.453-2.451-.17L6.828 0 4.973 1.614l-2.452.17-.17 2.454L.737 6.092 2.35 7.945l.17 2.453 2.453.17 1.854 1.613 1.854-1.614 2.451-.17zM6.795 9.47a3.412 3.412 0 110-6.823 3.412 3.412 0 010 6.823z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "17",
      "viewBox": "0 0 14 17"
    }
  };
  _exports.default = _default;
});