define("ember-svg-jar/inlined/qr-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M18.191 7.276h1.455V5.821h-1.455z\"/><path d=\"M18.191 7.276h1.455V5.821h-1.455zM5.821 7.276h1.455V5.821H5.821z\"/><path d=\"M16.736 8.732h4.366V4.366h-4.366v4.366zm-1.456 1.455h7.276V2.91H15.28v7.276z\"/><path d=\"M17.463 8.004h2.911v-2.91h-2.911zM10.915 4.366h1.455V2.911h-1.455z\"/><path d=\"M13.098 2.911v2.911h-2.183v2.91h1.455V7.277h.728v.727h1.455V2.911zM14.553 11.642h-1.455v-.728h-2.183V9.459h3.638zM10.915 12.37v1.455h.728v2.183h1.455v-2.183h1.455V12.37zM10.915 18.191h5.821v-1.455h-5.821zM15.28 10.915v5.094h1.456V12.37h5.82v-1.455zM21.102 23.285h1.455v-5.094h-1.455zM10.915 23.285h9.459v-1.456h-9.459zM18.191 21.101h2.183v-1.455h-2.183zM15.28 21.102h-4.366v-1.456h2.911v-.727h1.455z\"/><path d=\"M21.102 13.098v2.911h-2.183v-1.456h1.455v-1.455h-2.91v5.821h1.455v-1.455h3.638v-4.366zM16.008 21.101h1.456v-1.455h-1.456zM0 0v5.093h1.455V1.455h3.638V0zM20.374 0v1.455h3.638v3.638h1.455V0zM24.012 21.102v3.638h-3.638v1.455h5.093v-5.093zM5.093 15.28h2.183v-1.455H5.093zM7.276 12.37h.728v2.91h1.455v-2.91h.728v-1.456H7.276z\"/><path d=\"M2.911 10.915v4.365h1.455v-2.178l2.185-.004-.003-1.455-2.182.003v-.731zM4.365 8.732h4.366V4.366H4.365v4.366zm-1.454 1.455h7.276V2.91H2.911v7.276z\"/><path d=\"M5.093 8.004h2.911v-2.91H5.093zM4.365 21.829h4.366v-4.366H4.365v4.366zm-1.454 1.456h7.276v-7.276H2.911v7.276z\"/><path d=\"M5.093 21.102h2.911v-2.911H5.093z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "27",
      "viewBox": "0 0 26 27"
    }
  };
  _exports.default = _default;
});