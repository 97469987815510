define("ember-bulk-manager/components/sub/bulk-import-prefill-field-timestamp", ["exports", "ember-bulk-manager/templates/sub/bulk-import-prefill-field-date", "ember-bulk-manager/components/sub/bulk-import-prefill-field-string"], function (_exports, _bulkImportPrefillFieldDate, _bulkImportPrefillFieldString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-prefill-field` representing field from type date.
   *
   * ```html
   * {{sub/bulk-import-prefill-field-timestamp availableField=availableField}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(value)
   *
   * @namespace Component
   * @class BulkImportPrefillFieldTimestamp
   * @extends BulkImportPrefillFieldString
   * @public
   */
  var _default = _bulkImportPrefillFieldString.default.extend({
    layout: _bulkImportPrefillFieldDate.default,
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    bulkAssetLoader: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Date format for `pikaday` date picker linked to
     * `prefillOptions.dateFormat` of `availableField`.
     *
     * @property format
     * @type {String}
     * @default 'DD/MM/YYYY'
     * @public
     */
    format: Ember.computed('availableField.prefillOptions.format', function () {
      return Ember.get(this, 'availableField.prefillOptions.format') || 'DD/MM/YYYY';
    }),

    /**
     * Minimum date required for this picker.
     *
     * @attribute minDate
     * @optional
     * @type Date
     */
    minDate: null,

    /**
     * Maximum date required for this picker.
     *
     * @attribute maxDate
     * @optional
     * @type Date
     */
    maxDate: null,

    /**
     * Flag indicating if libraries loaded.
     *
     * @property _isReady
     * @type {Object}
     * @default false
     * @private
     */
    _isReady: false,

    /**
     * Reference to pikaday instance.
     *
     * @property _picker
     * @type {Object}
     * @default null
     * @private
     */
    _picker: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Loads pikaday in case not already loaded.
     *
     * @public
     * @method init
     * @return {Void}
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments); // override attribute bindings as they get
      // only concatenated by ember from super class


      Ember.set(this, 'attributeBindings', ['type', 'id', 'disabled']); // try to load Pikaday bundle from assets
      // Return an RSVP.Promise here so that tests pick this up easier

      var bulkAssetLoader = Ember.get(this, 'bulkAssetLoader');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        bulkAssetLoader.loadBundle('pikaday').then(function () {
          if (Ember.get(_this, 'isDestroyed')) {
            reject();
            return;
          }

          Ember.set(_this, '_isReady', true);
          resolve();
        }, reject);
      });
    },

    /**
     * Initializes pikaday with configured values
     * after library has loaded by asset loader.
     *
     * @public
     * @method didInsertElement
     * @return {Void}
     */
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var l10n = Ember.get(this, 'l10n');
      var format = Ember.get(this, 'format');
      var minDate = Ember.get(this, 'minDate');
      var maxDate = Ember.get(this, 'maxDate');
      var field = document.querySelector("#".concat(Ember.get(this, 'elementId'))); // actual initialization method

      var _onReady = function _onReady() {
        var _picker = new Pikaday({
          format: format,
          firstDay: 1,
          field: field,
          i18n: {
            nextMonth: l10n.t('Next month'),
            previousMonth: l10n.t('Previous month'),
            weekdaysShort: moment.weekdaysShort(),
            weekdays: moment.weekdays(),
            months: moment.months()
          },
          minDate: _this2._toDate(minDate),
          maxDate: _this2._toDate(maxDate),
          toString: Ember.run.bind(_this2, _this2._toString),
          onSelect: Ember.run.bind(_this2, _this2._onSelect),
          onClose: Ember.run.bind(_this2, _this2._onClose)
        });

        Ember.set(_this2, '_picker', _picker);

        _this2._setDate();
      }; // library already loaded


      if (Ember.get(this, '_isReady')) {
        _onReady();

        return;
      } // wait for library being loaded


      var observerCallback = function observerCallback() {
        if (Ember.get(_this2, '_isReady')) {
          _this2.removeObserver('_isReady', _this2, observerCallback);

          _onReady();
        }
      };

      this.addObserver('_isReady', this, observerCallback);
    },

    /**
     * Destroys the pikaday instance on destruction.
     *
     * @public
     * @method willDestroyElement
     * @return {Void}
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      _picker.destroy();
    },

    /**
     * Update `minDate`, `maxDate` and `date` in picker.
     *
     * @public
     * @method didUpdateAttrs
     * @return {Void}
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this._setMinDate();

      this._setMaxDate();

      this._setDate();
    },

    /**
     * Override default change value from input.
     *
     * @method change
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    change: function change(evt) {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = evt.target.value;

      if (!Ember.isBlank(value)) {
        return;
      } // Pikaday doesn't fire `onSelect`
      // if the input field gets cleared,
      // but avoid infinite loop if date
      // is already set to `null` before


      if (Ember.isNone(_picker.getDate())) {
        return;
      } // this resets selected day, but it
      // does NOT invoke `onSelect` call!


      _picker.setDate(null); // so we trigger our action manually


      this.invokeCallback('onChange', null);
    },

    /**
     * Sets current date from `value` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setDate: function _setDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'value');

      var date = this._toDate(value);

      _picker.setDate(date);
    },

    /**
     * Sets min date from `minDate` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setMinDate: function _setMinDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'minDate');

      var date = this._toDate(value);

      if (Ember.isNone(date)) {
        return;
      }

      _picker.setMinDate(date);
    },

    /**
     * Sets max date from `maxDate` in picker.
     *
     * @private
     * @method _setDate
     * @return {Void}
     */
    _setMaxDate: function _setMaxDate() {
      var _picker = Ember.get(this, '_picker');

      if (Ember.isNone(_picker)) {
        return;
      }

      var value = Ember.get(this, 'maxDate');

      var date = this._toDate(value);

      if (Ember.isNone(date)) {
        return;
      }

      _picker.setMaxDate(date);
    },

    /**
     * Sets `value` to moment from Pikaday
     * and tries to invoke `action` action.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _onSelect: function _onSelect() {
      var _picker = Ember.get(this, '_picker');

      var value = Ember.get(this, 'value'); // do NOT use moment object of `Pikaday` cause it
      // consists of a time zone aware moment object!

      var oldDate = this._toMoment(Ember.get(this, 'value'));

      var newDate = this._toMoment(_picker.getDate()); // do NOT invoke update if dates are still same!


      if (!Ember.isNone(oldDate) && !Ember.isNone(newDate)) {
        var newFormatted = newDate.format('YYYY-MM-DD');
        var oldFormatted = oldDate.format('YYYY-MM-DD');

        if (newFormatted === oldFormatted) {
          // if input wasn't timestamp send
          // update despite equality check!
          if (this._isTimestamp(value)) {
            return;
          }
        }
      } // propagate timestamp via `onChange`


      newDate = newDate ? +newDate : newDate;
      this.invokeCallback('onChange', newDate);
    },

    /**
     * Resets picker if no selection was made.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _onClose: function _onClose() {
      var _picker = Ember.get(this, '_picker');

      var value = Ember.get(this, 'value');

      if (!Ember.isNone(value)) {
        return;
      }

      _picker.setDate(null);
    },

    /**
     * Custom formatting function for Pikaday.
     *
     * @private
     * @method _change
     * @return {Void}
     */
    _toString: function _toString(date, format) {
      date = this._toMoment(date); // displaying current date in
      // picker required ALWAYS the
      // user's current locale zone
      // otherwise it would jump to
      // another date if utc offset

      date.local();
      return date.format(format);
    },

    /**
     * Parses a mixed value into a moment object.
     *
     * @private
     * @method _toMoment
     * @param {mixed} value
     * @param {direction} [string]
     * @return {Object|null}
     */
    _toMoment: function _toMoment(value) {
      // skip undefined/null values
      if (Ember.isNone(value)) {
        return null;
      } // try converting to moment


      if (!moment.isMoment(value)) {
        switch (Ember.typeOf(value)) {
          case 'string':
            {
              // try to parse date strings and even
              // stringified timestamps (attribute)
              var format = Ember.get(this, '_format');
              value = this._isTimestamp(value) ? value = moment(+value) : value = moment(value, format);
              break;
            }

          case 'date':
            {
              // create moment with default zone
              // ONLY from year, month and date
              // to get rid of any time diffs!
              value = moment([value.getFullYear(), value.getMonth(), value.getDate()]);
              break;
            }

          default:
            {
              value = moment(value);
            }
        }
      } // seems to be invalid input


      if (!value.isValid()) {
        return null;
      } // valid moment


      return value;
    },

    /**
     * Parses a mixed value into a native date.
     *
     * @private
     * @method _toDate
     * @param {mixed} value
     * @return {Date|null}
     */
    _toDate: function _toDate(value) {
      var date = this._toMoment(value);

      if (Ember.isNone(date)) {
        return null;
      }

      return new Date(date.year(), date.month(), date.date());
    },

    /**
     * Utility to detect timestamps.
     *
     * @private
     * @method _isTimestamp
     * @param {mixed} value
     * @return {boolean}
     */
    _isTimestamp: function _isTimestamp(value) {
      return !isNaN(+value);
    }
  });

  _exports.default = _default;
});