define("ember-bulk-manager/services/bulk-template", ["exports", "ember-copy", "ember-bulk-manager/utils/bulk-errors", "ember-bulk-manager/helpers/bulk-import-field-label"], function (_exports, _emberCopy, _bulkErrors, _bulkImportFieldLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MAPPING_PREFIX = void 0;

  /**
   * @constant MAPPING_PREFIX
   * @type {String}
   * @default 'EbmMp__'
   * @public
   */
  var MAPPING_PREFIX = 'EbmMp__';
  /**
   * A service managing templates for mapping client to internal format.
   *
   * The JSON structure of a template is as follows:
   * {
   *   id: <String>,
   *   name: <String>,
   *   mappings: <Object>
   * }
   *
   * @namespace Service
   * @class BulkTemplate
   * @extends Ember.Service
   * @extends Ember.Evented
   * @public
   */

  _exports.MAPPING_PREFIX = MAPPING_PREFIX;

  var _default = Ember.Service.extend(Ember.Evented, {
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    bulkLocalStorage: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Flag indicating if templates loaded.
     *
     * @property isReady
     * @type {Boolean}
     * @default false
     * @public
     */
    isReady: false,

    /**
     * Stores fetched templates (either from server or client)
     * in a hash map, where key is template id and value the
     * actual template content containing all mappings.
     *
     * @property templates
     * @type {Object}
     * @default {}
     * @public
     */
    templates: Ember.computed({
      get: function get()
      /* key*/
      {
        return {};
      }
    }),

    /**
     * Flag indicating if templates are available.
     *
     * @property hasTemplates
     * @type {Boolean}
     * @public
     */
    hasTemplates: Ember.computed.gt('templateCount', 0),

    /**
     * Current amount of available templates.
     *
     * @property templateCount
     * @type {Number}
     * @public
     */
    templateCount: Ember.computed('templates', {
      get: function get()
      /* key*/
      {
        var templates = Ember.get(this, 'templates');
        return Object.keys(templates).length;
      }
    }),

    /**
     * Provides the basic JSON skeleton for new templates
     * when on gets requested by invoking create() method.
     *
     * @property _skeleton
     * @type {Object}
     * @private
     */
    _skeleton: Ember.computed({
      get: function get()
      /* key*/
      {
        return {
          id: this._createTemplateId(),
          name: Ember.get(this, 'l10n').t('New template'),
          mappings: {
            /* importField: availableField */
          }
        };
      }
    }),

    /**
     * Storage key used for persisting templates in local storage.
     *
     * @property _storageKey
     * @type {Type}
     * @private
     */
    _storageKey: 'bulk-template',
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Fetches templates and set them in `templates` prop.
     * The method will trigger the events:
     *
     * - `bulk_template_ready`
     *
     * @method public
     * @return {Void}
     * @public
     */
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var successCallback = function successCallback(templates) {
        try {
          Ember.set(_this, 'templates', templates);
        } catch (exception) {
          /* noop */
        }
      };

      var failureCallback = function failureCallback()
      /* reason*/
      {// noop
      };

      var finallyCallback = function finallyCallback() {
        try {
          Ember.set(_this, 'isReady', true);

          _this.trigger('bulk_template_ready');
        } catch (exception) {
          /* noop */
        }
      };

      this.fetch().then(successCallback, failureCallback).finally(finallyCallback);
    },

    /**
     * Fetches all templates from corresponding data source.
     * Override this method for custom datasource adapters.
     *
     * @method fetch
     * @return {Promise}
     * @public
     */
    fetch: function fetch() {
      var _this2 = this;

      var promiseCallback = function promiseCallback(resolve
      /* ,reject*/
      ) {
        resolve(_this2._readTemplates());
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Implements template persistence on corresponding datasource.
     * Override this method for custom datasource adapters and call
     * protected save_() at the beginning of your method!
     *
     * @method save
     * @param {Object} template
     * @return {Promise}
     * @public
     */
    save: function save(template) {
      var _this3 = this;

      var promiseCallback = function promiseCallback(resolve
      /* , reject*/
      ) {
        // call internal method
        _this3.save_(template); // update local storage


        _this3._writeTemplates(); // resolve dummy promise


        resolve(true);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Implements template deletion on corresponding datasource.
     * Override this method for custom datasource adapters and
     * call protected delete_() at the beginning of your method!
     *
     * @method remove
     * @param {Object} template
     * @return {Promise}
     * @public
     */
    delete: function _delete(template) {
      var _this4 = this;

      var promiseCallback = function promiseCallback(resolve
      /* , reject*/
      ) {
        // call internal method
        _this4.delete_(template); // update local storage


        _this4._writeTemplates(); // resolve dummy promise


        resolve(true);
      };

      return new Ember.RSVP.Promise(promiseCallback);
    },

    /**
     * Reads a template from internal cache, which has been
     * filled by calling fetch() from service initialization.
     *
     * @method get
     * @param {String} id
     * @return {Object}
     * @throws {UnknownTemplate}
     * @throws {DependencyNotResolvedError}
     * @public
     */
    get: function get(id) {
      if (!Ember.get(this, 'isReady')) {
        throw new _bulkErrors.DependencyNotResolvedError(Ember.get(this, 'l10n').t('The service is not ready yet, please wait for `isReady` flag or listen to events.'));
      }

      var template = Ember.get(this, "templates.".concat(id));

      if (Ember.isNone(template)) {
        throw new _bulkErrors.UnknownTemplateError(Ember.get(this, 'l10n').t('This template does not exist (id: {{templateId}})!', {
          templateId: id || Ember.get(this, 'l10n').t('UNDEFINED')
        }));
      }

      return template;
    },

    /**
     * Creates a new template based on skeleton structure:
     *
     * {
     *   id: <String>,
     *   name: <String>,
     *   mappings: <Object>
     * }
     *
     * @public
     * @method create
     * @param {Object} data Extra JSON for template
     * @return {Object}
     */
    create: function create() {
      var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var template = (0, _emberCopy.copy)(Ember.get(this, '_skeleton'), true);

      switch (Ember.typeOf(data)) {
        case 'object':
          Ember.assign(template, data);
          break;

        default:
      }

      return template;
    },

    /**
     * Validates a template against given fields.
     * If there are fields in template, but not in
     * provided fields, a `MissingFieldsError` will
     * be thrown. If there are fields, which are not
     * contained in template, `AdditionalFieldsError`
     * will be thrown. Otherwise it will validate.
     *
     * @method validate
     * @param {Object} template
     * @param {Array} fields
     * @throws {MissingFieldsError}
     * @throws {AdditionalFieldsError}
     * @return {Boolean}
     * @public
     */
    validate: function validate(template, fields) {
      var mappings = Ember.get(template, 'mappings');
      var missing = [];

      for (var field in mappings) {
        if (fields.indexOf(field) < 0) {
          missing.push(field);
        }
      }

      if (missing.length > 0) {
        var missingFieldsError = new _bulkErrors.MissingFieldsError(Ember.get(this, 'l10n').t('Missing fields detected: {{missingFields}}.', {
          missingFields: missing.map(function (field) {
            return (0, _bulkImportFieldLabel.cleanField)(field);
          }).join(',')
        }), missing);
        missingFieldsError.fields = missing;
        throw missingFieldsError;
      }

      var additional = [];

      for (var i = 0; i < fields.length; i++) {
        if (!mappings.hasOwnProperty(fields[i])) {
          additional.push(fields[i]);
        }
      }

      if (additional.length > 0) {
        var additionalFieldsError = new _bulkErrors.AdditionalFieldsError(Ember.get(this, 'l10n').t('Additional fields detected: {{additionalFields}}.', {
          additionalFields: additional.map(function (field) {
            return (0, _bulkImportFieldLabel.cleanField)(field);
          }).join(', ')
        }), additional);
        additionalFieldsError.fields = additional;
        throw additionalFieldsError;
      }

      return true;
    },

    /**
     * Saves template in internal cache.
     * Call this method from save().
     *
     * @method save_
     * @param {Object} template
     * @return {Void}
     * @protected
     */
    save_: function save_(template) {
      var id = Ember.get(template, 'id');
      Ember.set(this, "templates.".concat(id), template);
      this.notifyPropertyChange('templates');
    },

    /**
     * Removes a template from internal cache.
     * Call this method from remove().
     *
     * @method remove_
     * @param {Object} template
     * @return {Void}
     * @protected
     */
    delete_: function delete_(template) {
      var templates = Ember.get(this, 'templates');
      var id = Ember.get(template, 'id');
      delete templates[id];
      this.notifyPropertyChange('templates');
    },

    /**
     * Reads templates from local storage.
     *
     * @method _readTemplates
     * @return {Object}
     * @private
     */
    _readTemplates: function _readTemplates() {
      var storage = Ember.get(this, 'bulkLocalStorage');
      var storageKey = Ember.get(this, '_storageKey');
      return storage.getItem(storageKey) || {};
    },

    /**
     * Writes templates to local storage.
     *
     * @method _writeTemplates
     * @return {Void}
     * @private
     */
    _writeTemplates: function _writeTemplates() {
      var storage = Ember.get(this, 'bulkLocalStorage');
      var storageKey = Ember.get(this, '_storageKey');
      var templates = Ember.get(this, 'templates');
      storage.setItem(storageKey, templates);
    },

    /**
     * Creates a unique id for new template.
     *
     * @method _createTemplateId
     * @return {String}
     * @private
     */
    _createTemplateId: function _createTemplateId() {
      var createId = function createId() {
        return Math.random().toString(36).slice(2);
      };

      var templates = Ember.get(this, 'templates');
      var id;

      do {
        id = createId();
      } while (Ember.get(templates, id));

      return id;
    }
  });

  _exports.default = _default;
});