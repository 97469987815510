define("@vollersgroup/ember-cropster-common/utils/copy-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = copyText;

  /**
   * Copy a text to the clipboard.
   * This will return true/false if copying was successful - in some browsers, this might fail.
   *
   * @namespace EmberCropsterCommon.Util
   * @method copyText
   * @param {String} text The text to copy
   * @returns {Boolean}
   * @public
   */
  function copyText(text) {
    var hasCopied = false;
    var tempInputElement;
    var container = document.body;

    try {
      tempInputElement = document.createElement('textarea');
      tempInputElement.setAttribute('style', 'position: absolute; z-index: -9999; top: 0; left: 0; opacity: 0;');
      tempInputElement.value = text;
      container.appendChild(tempInputElement);
      tempInputElement.focus();
      tempInputElement.select();

      if (document.queryCommandEnabled('copy')) {
        document.execCommand('copy');
        hasCopied = true;
      }
    } catch (e) {// Do nothing
    } // Remove it, even if something failed in the try-catch


    if (tempInputElement) {
      container.removeChild(tempInputElement);
    }

    return hasCopied;
  }
});