define("ember-bulk-manager/components/bulk-export-button", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/bulk-export-button"], function (_exports, _bulkComponent, _bulkExportButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @TODO.
   *
   * ```html
   * {{#bulk-export-button}}
   *  {{!-- Block --}}
   * {{/bulk-export-button}}
   * ```
   *
   * @namespace Component
   * @class BulkExportButton
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkExportButton.default,
    classNames: ['bulk-export-button'],
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Description
     *
     * @attribute attribute
     * @type {Type}
     * @default null
     * @public
     */
    attribute: null,
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Description
     *
     * @property property
     * @type {Type}
     * @default null
     * @public
     */
    property: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Description
     *
     * @method public
     * @return {Void}
     * @public
     */
    public: function _public() {},

    /**
     * Description
     *
     * @method _private
     * @return {Void}
     * @private
     */
    _private: function _private() {}
  });

  _exports.default = _default;
});