define("ember-svg-jar/inlined/comment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.31 11.333H15c1.11 0 2-.895 2-2V2c0-1.11-.895-2-2-2H2C.89 0 0 .895 0 2v7.333c0 1.111.895 2 2 2h1.778v3.423l4.532-3.423z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "15",
      "viewBox": "0 0 17 15"
    }
  };
  _exports.default = _default;
});