define("ember-svg-jar/inlined/drag-n-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><circle cx=\"1.625\" cy=\"1.625\" r=\"1.625\"/><circle cx=\"5.688\" cy=\"1.625\" r=\"1.625\"/><circle cx=\"1.625\" cy=\"6.5\" r=\"1.625\"/><circle cx=\"5.688\" cy=\"6.5\" r=\"1.625\"/><circle cx=\"1.625\" cy=\"11.375\" r=\"1.625\"/><circle cx=\"5.688\" cy=\"11.375\" r=\"1.625\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "13",
      "viewBox": "0 0 8 13"
    }
  };
  _exports.default = _default;
});