define("@vollersgroup/ember-cropster-common/components/modal-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oodbuo6z",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"in-element\",[[23,[\"wormholeElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"modal__overlay \",[27,\"if\",[[23,[\"isOpen\"]],\"modal__overlay--open\"],null]]]],[9],[10],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"modal__wrapper \",[27,\"if\",[[23,[\"isOpen\"]],\"modal__wrapper--open\"],null]]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"outsideClick\"],null]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"data-test-id\",\"modalWindow\"],[12,\"class\",[28,[\"modal \",[21,\"modalClasses\"]]]],[11,\"tabindex\",\"-1\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"enableAutoClose\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"data-test-id\",\"nativeClose\"],[11,\"class\",\"modal__close button button--icon button--plain button--icon--plain\"],[9],[0,\"\\n          \"],[1,[27,\"svg-icon\",[\"close\"],[[\"class\"],[\"icon--light-grey\"]]],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"close\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"        \"],[7,\"h2\"],[11,\"data-test-id\",\"title\"],[11,\"class\",\"modal__title\"],[9],[0,\"\\n          \"],[1,[21,\"title\"],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\"],[11,\"data-test-id\",\"yielded\"],[11,\"class\",\"modal__content\"],[9],[0,\"\\n\\n        \"],[14,1,[[27,\"action\",[[22,0,[]],\"close\"],null]]],[0,\"\\n\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@vollersgroup/ember-cropster-common/components/modal-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});