define("@vollersgroup/ember-cropster-common/utils/set-nested-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setNestedProperty;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function setNestedProperty(obj, property, value) {
    if (Ember.typeOf(obj) !== 'object' && Ember.typeOf(obj) !== 'instance') {
      throw new Error('The first argument for setNestedProperty must to be an object.');
    }

    if (Ember.typeOf(property) !== 'string' || property.length === 0) {
      throw new Error('The second argument for setNestedProperty must to be a string.');
    } // If the property is not nested, just set it


    var propertyPath = property.split('.');

    if (Ember.get(propertyPath, 'length') === 1) {
      return Ember.set(obj, property, value);
    } // If the property exists, simply overwrite it


    var exitingProperty = Ember.get(obj, property);

    if (exitingProperty) {
      return Ember.set(obj, property, value);
    } // Else, created the nested structure


    var currentObj = obj;

    var _propertyPath$splice = propertyPath.splice(-1),
        _propertyPath$splice2 = _slicedToArray(_propertyPath$splice, 1),
        lastProperty = _propertyPath$splice2[0];

    propertyPath.forEach(function (path) {
      // Check if the nested object already exists
      var newObj = Ember.get(currentObj, path);

      if (Ember.typeOf(newObj) !== 'object') {
        newObj = {};
        currentObj[path] = newObj;
      }

      currentObj = newObj;
    });
    currentObj[lastProperty] = value;
    return value;
  }
});