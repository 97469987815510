define("ember-svg-jar/inlined/arrow-thin-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6.2 5.6L4 7.8V.3H3v7.5L.8 5.6l-.7.8 3.1 3 .3.3.3-.3 3.1-3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "7",
      "height": "10",
      "viewBox": "0 0 7 10"
    }
  };
  _exports.default = _default;
});