define("ember-bulk-manager/templates/bulk-import-prefill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yINSjRwW",
    "block": "{\"symbols\":[\"availableField\"],\"statements\":[[4,\"each\",[[23,[\"filteredFields\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"sub/bulk-import-prefill-field\",null,[[\"availableField\",\"onChange\"],[[27,\"readonly\",[[22,1,[]]],null],[27,\"action\",[[22,0,[]],\"onChange\",[22,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"bulk-import-prefill-messages\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"bulk-import-prefill-message-warning\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"There are no fields to be pre filled.\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/bulk-import-prefill.hbs"
    }
  });

  _exports.default = _default;
});