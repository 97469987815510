define("ember-bulk-manager/templates/bulk-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lRzzXowj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[21,\"domId\"]],[9],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"Loading...\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"sub/bulk-tooltip\",null,[[\"target\",\"isVisible\"],[[23,[\"tooltip\",\"target\"]],[23,[\"tooltip\",\"isVisible\"]]]],{\"statements\":[[0,\"  \"],[1,[23,[\"tooltip\",\"content\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/bulk-grid.hbs"
    }
  });

  _exports.default = _default;
});