define("ember-bulk-manager/components/bulk-import-mapper", ["exports", "ember-copy", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/utils/bulk-array-helper", "ember-bulk-manager/templates/bulk-import-mapper", "ember-bulk-manager/utils/bulk-errors", "ember-bulk-manager/components/bulk-import-file"], function (_exports, _emberCopy, _bulkComponent, _bulkArrayHelper, _bulkImportMapper, _bulkErrors, _bulkImportFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STORAGE_KEY = void 0;

  /**
   * Storage key used for persisting
   * selected settings in storage.
   *
   * @constant STORAGE_KEY
   * @type {String}
   * @public
   */
  var STORAGE_KEY = 'bulk-import-mapper';
  /**
   * A custom component facilitating mapping UI for parsed bulkSpreadsheet files (Excel or CSV).
   * It provides a simple UI to create a new import template for `bulk-template` including
   * name and mapping of columns according to specified ones from `bulk-lookup-data`. As
   * soon as all fields have been mapped either to an available field or being marked as
   * skipped, the template will be ready for storage in database or local storage
   * (= default).
   *
   * ```html
   * {{#bulk-import-mapper
   *     template=template
   *     templateData=templateData
   *     availableFields=availableFields
   *     onEditField=(action "onEditField")
   *     onSaveField=(action "onSaveField")
   *     onSkipField=(action "onSkipField")
   *     onMappingSuccess=(action "onMappingSuccess")
   *     onSetTemplateName=(action "onSetTemplateName") as |component|}}
   *
   *    {{#if (bulk-is-equal remainingComposedFields 0)}}
   *      {{#unless (bulk-is-equal importFields.length 0)}}
   *        {{#unless hasOnlySkippedFields}}
   *          <div class="bulk-import-mapper-message-success">
   *            <strong>{{t "Congratulations:"}}</strong>
   *            {{t "All of your fields have been mapped, you can now continue importing."}}
   *          </div>
   *        {{else}}
   *          <div class="bulk-import-mapper-message-warning">
   *            <strong>{{t "Please note:"}}</strong>
   *            {{t "You have skipped all of your fields, so nothing will be imported!"}}
   *          </div>
   *        {{/unless}}
   *      {{/unless}}
   *    {{else}}
   *      {{#if hasMissingFields}}
   *        <div class="bulk-import-mapper-message-warning">
   *          <strong>{{t "Warning:"}}</strong>
   *          {{t "Your imported file misses some fields from template and will not be imported!"}}
   *          </div>
   *      {{/if}}
   *    {{/if}}
   *
   * {{/bulk-import-mapper}}
   * ```
   * Action signatures:
   *
   * - onSetTemplateName(name)
   * - onEditField(importField)
   * - onSkipField(importField,previousField)
   * - onSaveField(importField,mappedField,previousField)
   * - onMappingSuccess(template)
   *
   * @namespace Component
   * @class BulkImportMapper
   * @extends BaseComponent
   * @public
   */

  _exports.STORAGE_KEY = STORAGE_KEY;

  var _default = _bulkComponent.default.extend({
    layout: _bulkImportMapper.default,
    classNames: ['bulk-import-mapper'],
    // -------------------------------------------------------------------------
    // Dependencies
    bulkTemplate: Ember.inject.service(),
    bulkSpreadsheet: Ember.inject.service(),
    bulkLocalStorage: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Current template for mapping process.
     *
     * @attribute template
     * @type {Object}
     * @default null
     * @public
     */
    template: null,

    /**
     * Custom data for template when creating
     *
     * @attribute template
     * @type {Object}
     * @default null
     * @public
     */
    templateData: Ember.computed({
      get: function get()
      /* key*/
      {
        return {};
      }
    }),

    /**
     * Available fields for mapping process. This fields should be defined
     * in extended `bulk-lookup-data` service and must at least contain a
     * `value` and `label` property. Please refer to the documentation.
     *
     * @attribute availableFields
     * @type {Object}
     * @default {}
     * @public
     */
    availableFields: Ember.computed({
      get: function get()
      /* key*/
      {
        return {};
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Alias to `fields` from last import result of `bulk-spreadsheet`.
     *
     * @property fields
     * @type {Array}
     * @public
     */
    importFields: Ember.computed.reads('bulkSpreadsheet.lastImport.fields'),

    /**
     * Alias to `file` from last import result of `bulk-spreadsheet`.
     *
     * @property fields
     * @type {Array}
     * @public
     */
    importFile: Ember.computed.reads('bulkSpreadsheet.lastImport.file'),

    /**
     * Internal template during mapping process transformed by
     * from `template` with `_getImportTemplate()` method.
     *
     * @property currentTemplate
     * @type {Object}
     * @default null
     * @public
     */
    currentTemplate: null,

    /**
     * Collection of missing fields used to set `isMissing` on
     * `bulk-import-mapper-field` component. Furthermore, an
     * error message is shown on top of component. This gets
     * detected during template initialization by validation.
     *
     * @property missingFields
     * @type {Object}
     * @default {}
     * @public
     */
    missingFields: Ember.computed({
      get: function get()
      /* key*/
      {
        return {
          fields: {},
          message: ''
        };
      }
    }),

    /**
     * Collection of additional fields only used to display a
     * warning message on top of component. These fields has
     * to be mapped/skipped by user. This gets detected during
     * template initialization by validation.
     *
     * @property additionalFields
     * @type {Object}
     * @default {}
     * @public
     */
    additionalFields: Ember.computed({
      get: function get()
      /* key*/
      {
        return {
          message: ''
        };
      }
    }),

    /**
     * Composition of fields from template and current import fields.
     *
     * @property composedFields
     * @type {Array}
     * @public
     */
    composedFields: Ember.computed('importFields', 'importFields.[]', 'currentTemplate.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings');
        var importFields = Ember.get(this, 'importFields') || [];
        var templateFields = Object.keys(mappings || {});
        return (0, _bulkArrayHelper.unionArray)(importFields, templateFields);
      }
    }),

    /**
     * Computes displayable import fields. This depends whether
     * or not `showSkippedFields` is currently set from UI.
     *
     * @property composedFieldsFiltered
     * @type {Array}
     * @public
     */
    composedFieldsFiltered: Ember.computed('composedFields', 'composedFields.[]', 'showSkippedFields', 'currentTemplate.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var showSkippedFields = Ember.get(this, 'showSkippedFields');
        var composedFields = Ember.get(this, 'composedFields');

        if (showSkippedFields) {
          return composedFields;
        }

        return composedFields.filter(function (field) {
          return mappings[field] !== null;
        });
      }
    }),

    /**
     * Computes reversed representation of `currentTemplate.mappings`
     * for creating mapping overview when `showAvailableFields` is
     * currently active. Note that this gets only calculated once
     * and in case bulk template gets loaded asynchronously.
     *
     * @property mappedFields
     * @type {Array}
     * @public
     */
    mappedFields: Ember.computed({
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var availableFields = Ember.get(this, 'availableFields');
        var mappingKeys = Object.keys(mappings);
        var mappedFields = {};

        var matchField = function matchField(field) {
          var matchedField = null;

          if (mappingKeys.length === 0) {
            return matchedField;
          }

          for (var importField in mappings) {
            var current = mappings[importField];

            if (current !== field) {
              continue;
            }

            matchedField = importField;
            break;
          }

          return matchedField;
        };

        var processField = function processField(field) {
          var fieldKey = Ember.get(field, 'label');
          mappedFields[fieldKey] = matchField(field);
        };

        switch (Ember.typeOf(availableFields)) {
          case 'object':
            for (var key in availableFields) {
              processField(availableFields[key]);
            }

            break;

          case 'array':
            availableFields.forEach(processField);
            break;

          default:
            throw new _bulkErrors.WrongParameterTypeError(Ember.get(this, 'l10n').t('`availableFields` parameter has to be an array or object!'));
        }

        return mappedFields;
      }
    }),

    /**
     * Keeps reference of currently editing field for instructing
     * `isEdit` attribte from `bulk-import-mapper-field` component.
     *
     * @property editField
     * @type {Object}
     * @public
     */
    editField: Ember.computed('composedFields', 'composedFields.[]', 'currentTemplate.mappings', {
      set: function set(key, val
      /* ,oldVal*/
      ) {
        Ember.set(this, '_cachedField', val);
        return val;
      },
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var composedFields = Ember.get(this, 'composedFields');
        var cachedField = Ember.get(this, '_cachedField'); // try to use cached field from user selection

        if (!mappings.hasOwnProperty(cachedField)) {
          if (!Ember.isNone(cachedField)) {
            return cachedField;
          }
        } // otherwise find next unmapped field from stack


        var editField;

        for (var i = 0; i < composedFields.length; i++) {
          var field = composedFields[i];

          if (!mappings.hasOwnProperty(field)) {
            editField = field;
            break;
          }
        }

        return editField || null;
      }
    }),

    /**
     * Counts amount of remaining import fields for this import.
     *
     * @property remainingComposedFields
     * @type {Number}
     * @public
     */
    remainingComposedFields: Ember.computed('composedFields', 'composedFields.[]', 'currentTemplate.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var composedFields = Ember.get(this, 'composedFields');
        var mappedCount = Object.keys(mappings).length;
        var totalCount = Ember.get(composedFields, 'length');
        return totalCount - mappedCount;
      }
    }),

    /**
     * Counts amount of remaining available fields for this import.
     *
     * @property remainingAvailableFields
     * @type {Number}
     * @public
     */
    remainingAvailableFields: Ember.computed('availableFields', 'availableFields.[]', 'currentTemplate.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var availableFields = Ember.get(this, 'availableFields');
        var availableCount;

        switch (Ember.typeOf(availableFields)) {
          case 'object':
            availableCount = Object.keys(availableFields).length;
            break;

          case 'array':
            availableCount = Ember.get(availableFields, 'length');
            break;

          default:
            throw new _bulkErrors.WrongParameterTypeError(Ember.get(this, 'l10n').t('`availableFields` parameter has to be an array or object!'));
        }

        var mappedCount = 0;

        for (var importField in mappings) {
          if (!mappings[importField]) {
            continue;
          }

          ++mappedCount;
        }

        return availableCount - mappedCount;
      }
    }),

    /**
     * Counts the inverse of `remainingComposedFields` for progress.
     *
     * @property finishedComposedFields
     * @type {Number}
     * @public
     */
    finishedComposedFields: Ember.computed('remainingComposedFields', {
      get: function get()
      /* key*/
      {
        var remainingComposedFields = Ember.get(this, 'remainingComposedFields');
        var totalFields = Ember.get(this, 'composedFields.length');
        return totalFields - remainingComposedFields;
      }
    }),

    /**
     * Determines if mappings contain only skipped fields.
     *
     * @property hasOnlySkippedFields
     * @type {Boolean}
     * @public
     */
    hasOnlySkippedFields: Ember.computed('currentTemplate.mappings', {
      get: function get()
      /* key*/
      {
        var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
        var totalCount = Object.keys(mappings).length;
        var skipCount = 0;

        for (var importField in mappings) {
          if (mappings[importField]) {
            continue;
          }

          ++skipCount;
        }

        return totalCount === skipCount;
      }
    }),

    /**
     * Calculates progress in percent depending on `remainingComposedFields`.
     *
     * @property progress
     * @type {Number}
     * @public
     */
    progress: Ember.computed('finishedComposedFields', {
      get: function get()
      /* key*/
      {
        var finishedComposedFields = Ember.get(this, 'finishedComposedFields');
        var totalFields = Ember.get(this, 'composedFields.length');
        var progress = finishedComposedFields / totalFields;

        if (isNaN(progress)) {
          return 0;
        }

        return Math.ceil(progress * 100);
      }
    }),

    /**
     * Flag whether or not progress bar is shown. For existing
     * templates, which have already been mapped, progress is
     * not shown anymore.
     *
     * @property progress
     * @type {Number}
     * @public
     */
    showProgress: Ember.computed('template', 'progress', {
      get: function get()
      /* key*/
      {
        var template = Ember.get(this, 'template');

        if (Ember.isNone(template)) {
          return true;
        }

        var progress = Ember.get(this, 'progress');

        if (progress < 100) {
          return true;
        }

        return false;
      }
    }),

    /**
     * Flag whether or not available fields should be visible.
     *
     * @property showAvailableFields
     * @type {Boolean}
     * @default false
     * @public
     */
    showAvailableFields: false,

    /**
     * Flag whether or not skipped fields should be visible.
     *
     * @property showSkippedFields
     * @type {Boolean}
     * @default true
     * @public
     */
    showSkippedFields: true,

    /**
     * Cached reference of current edit field for restoring.
     *
     * @property _cachedField
     * @type {Object}
     * @private
     */
    _cachedField: null,
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Tries to initialize settings from local storage.
     *
     * @method init
     * @return {Void}
     * @private
     */
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._desist);
    },

    /**
     * Imports given template to internal format if
     * consumer provided the `template` attribute.
     *
     * @method didReceiveAttrs
     * @return {Void}
     * @private
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      Ember.run.once(this, this._initTemplate);
    },

    /**
     * Tries to restore data from local storage.
     *
     * @method _desist
     * @return {Void}
     * @private
     */
    _desist: function _desist() {
      var storage = Ember.get(this, 'bulkLocalStorage');
      var item = storage.getItem(STORAGE_KEY);

      if (Ember.isNone(item)) {
        return;
      } // try to restore `showSkippedFields`


      var showSkippedFields = Ember.get(item, 'showSkippedFields');

      if (!Ember.isNone(showSkippedFields)) {
        Ember.set(this, 'showSkippedFields', showSkippedFields);
      } // try to restore `showAvailableFields`


      var showAvailableFields = Ember.get(item, 'showAvailableFields');

      if (!Ember.isNone(showAvailableFields)) {
        Ember.set(this, 'showAvailableFields', showAvailableFields);
      }
    },

    /**
     * Writes data into local storage.
     *
     * @method _persist
     * @param {String} key Corresponding key within storage item.
     * @param {Mixed} data Mixed data to be stored under `key`.
     * @return {Void}
     * @private
     */
    _persist: function _persist(key, data) {
      var storage = Ember.get(this, 'bulkLocalStorage');
      var item = storage.getItem(STORAGE_KEY) || {};
      Ember.set(item, key, data);
      storage.setItem(STORAGE_KEY, item);
    },

    /**
     * Iterates all fields and sets unmapped to skipped.
     *
     * @method _skipUnmappedFields
     * @return {Void}
     * @private
     */
    _skipUnmappedFields: function _skipUnmappedFields() {
      var _this = this;

      var mappings = Ember.get(this, 'currentTemplate.mappings') || {};
      var composedFields = Ember.get(this, 'composedFields');
      composedFields.forEach(function (field) {
        if (!mappings.hasOwnProperty(field)) {
          _this.send('onSkipField', field);
        }
      });
    },

    /**
     * Primitive method to add a new mapping to `currentTemplate`.
     * Marks all remaining fields to skipped, if there are not
     * available fields left. When all mapping has been done,
     * `onMappingSuccess` gets invoked.
     *
     * @method _setMapping
     * @param {String} key
     * @param {Mixed} value
     * @param {Mixed} [oldValue=null]
     * @return {Void}
     * @private
     */
    _setMapping: function _setMapping(key, value) {
      var oldValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      // add key-value pair into our bulkTemplate skeleton
      var mappings = Ember.get(this, 'currentTemplate.mappings') || {}; // create a dummy copy and add the new key-value

      var newMappings = (0, _emberCopy.copy)(mappings);
      newMappings[key] = value; // now force observer update by instructing set()

      Ember.set(this, 'currentTemplate.mappings', newMappings); // store reversed hash map for `mappedFields`

      var mappedFields = Ember.get(this, 'mappedFields');
      var newMappedFields = (0, _emberCopy.copy)(mappedFields);

      if (!Ember.isNone(value)) {
        // set new value
        var newMappedKey = Ember.get(value, 'label');
        newMappedFields[newMappedKey] = key;
      }

      if (!Ember.isNone(oldValue)) {
        // reset old value
        var oldMappedKey = Ember.get(oldValue, 'label');
        newMappedFields[oldMappedKey] = null;
      } // force observer update by instructing set()


      Ember.set(this, 'mappedFields', newMappedFields); // check if we should skip remaining fields

      this._checkSkipRemainingFields(); // check if all fields has been mapped


      this._checkMappingSuccess();
    },

    /**
     * Sets new template if no one is supplied by `template` attribute.
     * If a template is provided, it gets validated and transformed to
     * internal format used during mapping process.
     *
     * @method _initTemplate
     * @return {Void}
     * @private
     */
    _initTemplate: function _initTemplate() {
      var originalTemplate = Ember.get(this, 'template');
      var importFields = Ember.get(this, 'importFields');
      var bulkTemplate = Ember.get(this, 'bulkTemplate'); // create a fresh template if no one is given

      if (Ember.isNone(originalTemplate)) {
        var externalData = Ember.get(this, 'templateData');
        var internalData = {
          name: Ember.get(this, 'importFile.name')
        };
        var data;

        switch (Ember.typeOf(externalData)) {
          case 'object':
            data = Ember.assign(internalData, externalData);
            break;

          default:
            data = internalData;
        }

        var newTemplate = bulkTemplate.create(data);
        Ember.set(this, 'currentTemplate', newTemplate);
        return;
      } // otherwise validate and transform given template


      try {
        bulkTemplate.validate(originalTemplate, importFields);
      } catch (exception) {
        switch (exception.name) {
          case 'MissingFieldsError':
            {
              var missingFields = Ember.get(this, 'missingFields.fields');
              exception.fields.forEach(function (field) {
                missingFields[field] = true;
              });
              Ember.set(this, 'missingFields.message', exception.message);
              break;
            }

          case 'AdditionalFieldsError':
            {
              Ember.set(this, 'additionalFields.message', exception.message);
              break;
            }

          default:
        }
      }

      var importTemplate = this._getImportTemplate();

      Ember.set(this, 'currentTemplate', importTemplate);
    },

    /**
     * Transforms template to internal format, so that during mapping
     * always an object reference to actual available field is stored.
     *
     * @method _getImportTemplate
     * @return {Object}
     * @private
     */
    _getImportTemplate: function _getImportTemplate() {
      var originalTemplate = Ember.get(this, 'template');
      var availableFields = Ember.get(this, 'availableFields');
      var importTemplate = (0, _emberCopy.copy)(originalTemplate, true);
      var mappings = Ember.get(importTemplate, 'mappings') || {};

      for (var importField in mappings) {
        var key = mappings[importField];

        if (Ember.isNone(key)) {
          // skip fields
          continue;
        }

        var availableField = Ember.get(availableFields, key);
        mappings[importField] = availableField;
      }

      return importTemplate;
    },

    /**
     * Transforms template to "native" format, as during mapping
     * available fields are stored by object reference and not only
     * by their `value` property (= hash map key) as for storage mode.
     *
     * @method _getExportTemplate
     * @return {Object}
     * @private
     */
    _getExportTemplate: function _getExportTemplate() {
      var currentTemplate = Ember.get(this, 'currentTemplate');
      var bulkTemplate = Ember.get(this, 'bulkTemplate');
      var templateData = Ember.get(this, 'templateData');
      var exportTemplate = bulkTemplate.create();
      var oldMappings = Ember.get(currentTemplate, 'mappings');
      var newMappings = Ember.get(exportTemplate, 'mappings');

      for (var importField in oldMappings) {
        var availableField = oldMappings[importField];
        var mappedField = !Ember.isNone(availableField) ? Ember.get(availableField, 'value') : null;
        newMappings[importField] = mappedField;
      }

      switch (Ember.typeOf(templateData)) {
        case 'object':
          {
            Ember.assign(exportTemplate, templateData);
            break;
          }

        default:
      }

      exportTemplate.name = currentTemplate.name;
      exportTemplate.id = currentTemplate.id;
      return exportTemplate;
    },

    /**
     * Checks if there are remaining available fields, otherwise all
     * fields will be automatically skipped to reduce user input.
     *
     * @method _checkSkipRemainingFields
     * @return {Void}
     * @private
     */
    _checkSkipRemainingFields: function _checkSkipRemainingFields() {
      var remainingAvailableFields = Ember.get(this, 'remainingAvailableFields');

      if (remainingAvailableFields !== 0) {
        return;
      }

      this._skipUnmappedFields();
    },

    /**
     * Checks if there are remaining composed fields, otherwise action
     * `onMappingSuccess` will be triggered to be handled by consumer.
     *
     * @method _checkMappingSuccess
     * @return {Void}
     * @private
     */
    _checkMappingSuccess: function _checkMappingSuccess() {
      var _this2 = this;

      var remainingComposedFields = Ember.get(this, 'remainingComposedFields');

      if (remainingComposedFields !== 0) {
        return;
      }

      Ember.run.once(function () {
        var template = _this2._getExportTemplate();

        Ember.set(_this2, 'additionalFields.message', null);

        _this2.invokeCallback('onMappingSuccess', template);

        var bulkLocalStorage = Ember.get(_this2, 'bulkLocalStorage');
        (0, _bulkImportFile.setTemplatePreferenceInStorage)(bulkLocalStorage, template);
      });
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets file name if provides no template name.
       *
       * @event setTemplateName
       * @param {String} name
       * @private
       */
      setTemplateName: function setTemplateName(name) {
        if (Ember.isEmpty(name)) {
          name = Ember.get(this, 'importFile.name');
          Ember.set(this, 'currentTemplate.name', name);
        }

        this.invokeCallback('onSetTemplateName', name);
      },

      /**
       * Updates `showSkippedFields` and persists user
       * choice in to local storage for next page visit.
       *
       * @event setShowSkippedFields
       * @param {Boolean} checked
       * @private
       */
      setShowSkippedFields: function setShowSkippedFields(checked) {
        Ember.set(this, 'showSkippedFields', checked);

        this._persist('showSkippedFields', checked);
      },

      /**
       * Deletes all missing fields from current template.
       *
       * @event deleteMissingFields
       * @private
       */
      deleteMissingFields: function deleteMissingFields() {
        var mappings = Ember.get(this, 'currentTemplate.mappings');
        var missingFields = Ember.get(this, 'missingFields.fields');
        var mappedFields = Ember.get(this, 'mappedFields'); // create shallow copies for reassignment

        var newMappedFields = (0, _emberCopy.copy)(mappedFields);
        var newMappings = (0, _emberCopy.copy)(mappings); // iterate over missing fields from validation

        for (var field in missingFields) {
          var mappedField = newMappings[field]; // remove from reversed `mappedFields` property

          if (!Ember.isNone(mappedField)) {
            var mappedKey = Ember.get(mappedField, 'label');
            newMappedFields[mappedKey] = null;
          } // remove from `mappings` property of template


          delete newMappings[field];
        } // force observer update by instructing set()


        Ember.set(this, 'currentTemplate.mappings', newMappings);
        Ember.set(this, 'mappedFields', newMappedFields);
        Ember.set(this, 'missingFields.message', null); // check for mapping success

        this._checkMappingSuccess();
      },

      /**
       * Toggles `showAvailableFields` and persists user
       * choice in to local storage for next page visit.
       *
       * @event toggleShowAvailableFields
       * @private
       */
      toggleShowAvailableFields: function toggleShowAvailableFields() {
        var value = this.toggleProperty('showAvailableFields');

        this._persist('showAvailableFields', value);
      },

      /**
       * Marks all unmapped fields to be skipped.
       *
       * @event skipUnmappedFields
       * @private
       */
      skipUnmappedFields: function skipUnmappedFields() {
        this._skipUnmappedFields();

        this.invokeCallback('onSkipUnmappedFields');
      },

      /**
       * Updates `editField` if changed from subcomponent.
       *
       * @event onSaveField
       * @param {String} importField
       * @private
       */
      onEditField: function onEditField(importField) {
        Ember.set(this, 'editField', importField);
        this.invokeCallback('onEditField', importField);
      },

      /**
       * Adds key-value pair to `currentTemplate.mappings`, where
       * import field is key and mapped field is value.
       *
       * @event onSaveField
       * @param {String} importField
       * @param {Object} mappedField
       * @param {Object} previousField
       * @private
       */
      onSaveField: function onSaveField(importField, mappedField, previousField) {
        this._setMapping(importField, mappedField, previousField);

        this.invokeCallback('onSaveField', importField, mappedField, previousField);
      },

      /**
       * Adds key-value pair to `currentTemplate.mappings`, where
       * import field is key and value is set to null.
       *
       * @event onSkipField
       * @param {String} importField
       * @param {Object} previousField
       * @private
       */
      onSkipField: function onSkipField(importField, previousField) {
        this._setMapping(importField, null, previousField);

        this.invokeCallback('onSkipField', importField, previousField);
      }
    }
  });

  _exports.default = _default;
});