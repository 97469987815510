define("ember-svg-jar/inlined/positive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.723 8H8v3.063H7V8H4.278V7H7V3.938h1V7h2.723v1zM7.5 0a7.5 7.5 0 100 15 7.5 7.5 0 000-15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});