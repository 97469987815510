define("ember-bulk-manager/components/sub/bulk-import-mapper-field", ["exports", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/templates/sub/bulk-import-mapper-field", "ember-bulk-manager/helpers/bulk-import-field-label"], function (_exports, _bulkComponent, _bulkImportMapperField, _bulkImportFieldLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Sub component for `bulk-import-mapper` representing one import field
   * to be configured to `mappedField`. Important: Mapped fields will be
   * saved with corresponding field from `availableFields` from service
   * `bulk-lookup-data`, whereas skipped ones will be saved with null!
   *
   * ```html
   * {{#sub/bulk-import-mapper-field
   *     isEdit=isEdit
   *     importField=importField
   *     mappedField=mappedField
   *     previewRows=previewRows
   *     availableFields=availableFields
   *     onEditField=(action "onEditField")
   *     onSaveField=(action "onSaveField")
   *     onSkipField=(action "onSkipField")
   *     onEditCancel=(action "onEditCancel")}}
   * {{/sub/bulk-import-mapper-field}}
   * ```
   *
   * Action signatures:
   *
   * - onEditField(importField)
   * - onSaveField(mappedField)
   * - onSkipField(mappedField)
   * - onEditCancel(importField)
   *
   * @namespace Component
   * @class BulkImportMapperField
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkImportMapperField.default,
    classNames: ['bulk-import-mapper-field'],
    classNameBindings: ['isDone:bulk-import-mapper-field--done', 'isSkip:bulk-import-mapper-field--skip', 'isEdit:bulk-import-mapper-field--edit', 'isMissing:bulk-import-mapper-field--missing'],
    // -------------------------------------------------------------------------
    // Dependencies
    bulkLookupData: Ember.inject.service(),
    bulkSpreadsheet: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Key of the the field to be mapped.
     *
     * @attribute importField
     * @type {String}
     * @default null
     * @public
     */
    importField: null,

    /**
     * Selected mapping field from available fields.
     *
     * @attribute mappedField
     * @type {Object}
     * @default undefined
     * @public
     */
    mappedField: undefined,

    /**
     * Flag determining if field is in edit mode.
     *
     * @attribute isEdit
     * @type {Boolean}
     * @default false
     * @public
     */
    isEdit: Ember.computed('isMissing', {
      get: function get()
      /* key*/
      {
        if (Ember.get(this, 'isMissing')) {
          return false;
        }

        return false;
      }
    }),

    /**
     * Flag determining if field has an error.
     *
     * @attribute isMissing
     * @type {Boolean}
     * @default false
     * @public
     */
    isMissing: false,

    /**
     * How many rows should be shown from import data.
     *
     * @attribute previewRows
     * @type {Number}
     * @default 3
     * @public
     */
    previewRows: 3,

    /**
     * Available fields for mapping drop down.
     *
     * @attribute availableFields
     * @type {Object}
     * @default {}
     * @public
     */
    availableFields: Ember.computed({
      get: function get()
      /* key*/
      {
        return {};
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * Label of import field to be used for display without `MAPPING_PREFIX`.
     *
     * @property property
     * @type {String}
     * @public
     */
    label: Ember.computed('importField', {
      get: function get() {
        return (0, _bulkImportFieldLabel.cleanField)(Ember.get(this, 'importField'));
      }
    }),

    /**
     * Alias to `data` from last import result of `bulk-spreadsheet`.
     *
     * @property property
     * @type {Array}
     * @default null
     * @public
     */
    importData: Ember.computed.reads('bulkSpreadsheet.lastImport.data'),

    /**
     * Currently selected field from drop down select box, which
     * will be set as `mappedField` if user clicks save button.
     *
     * @property selectedField
     * @type {Object}
     * @default null
     * @public
     */
    selectedField: Ember.computed.reads('mappedField'),

    /**
     * Sliced data for preview within UI depending on `previewRows`.
     *
     * @property previewData
     * @type {Array}
     * @default []
     * @public
     */
    previewData: Ember.computed('previewRows', 'importData.[]', {
      get: function get()
      /* key*/
      {
        var data = Ember.get(this, 'importData') || [];
        var max = Ember.get(data, 'length');
        var end = Ember.get(this, 'previewRows');

        if (end > max) {
          end = max;
        }

        return data.slice(0, end);
      }
    }),

    /**
     * Flag determining if this field has been mapped.
     *
     * @property isDone
     * @type {Boolean}
     * @default false
     * @public
     */
    isDone: Ember.computed('mappedField', {
      get: function get()
      /* key*/
      {
        return !Ember.isNone(Ember.get(this, 'mappedField'));
      }
    }),

    /**
     * Flag determining if field is marked to be skipped.
     *
     * @property isSkip
     * @type {Boolean}
     * @default false
     * @public
     */
    isSkip: Ember.computed('mappedField', {
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'mappedField') === null;
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Sets up observers for component on inititialization.
     *
     * @method init
     * @return {Void}
     * @private
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._watchIsMissing();

      this.addObserver('isMissing', this, this._watchIsMissing);
    },

    /**
     * Sets internal `disabled` flagged for `mappedField`.
     * addon/components/sub/bulk-import-mapper-field.js
     * @method init
     * @return {Void}
     * @private
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var mappedField = Ember.get(this, 'mappedField');

      if (mappedField !== Ember.get(this, '_mappedField')) {
        this._disableField(mappedField);

        Ember.set(this, '_mappedField', mappedField);
      }
    },

    /**
     * Forces recomputation of `options` for dropdown in
     * case it has been modified by another field as the
     * current field - f.e. when clicking skip on another
     * field as the current active edit field, otherwise
     * the `disabled` change won't propagate through as
     * `availableFields` is a deep object literal.
     *
     * @method didUpdateAttrs
     * @return {Void}
     * @private
     */
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.notifyPropertyChange('availableFields');
    },

    /**
     * Resets internal `disabled` flagged for `mappedField`.
     *
     * @method willDestroyElement
     * @return {Void}
     * @private
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this._disableField(null);

      this.removeObserver('isMissing', this, this._watchIsMissing);
    },

    /**
     * Sets `disabled` property for given field and
     * automatically resets `disabled` for old field.
     *
     * @method _disableField
     * @param {Object} field
     * @private
     */
    _disableField: function _disableField(field) {
      // enable currently mapped field
      var mappedField = Ember.get(this, 'mappedField');

      if (!Ember.isNone(mappedField)) {
        Ember.set(mappedField, 'disabled', false);
      } // disable newly selected field


      if (!Ember.isNone(field)) {
        Ember.set(field, 'disabled', true);
      } // invoke change detection for select component


      this.notifyPropertyChange('availableFields');
    },

    /**
     * Resets `isEdit` if `isMissing` is truthy.
     *
     * @method _watchIsMissing
     * @private
     */
    _watchIsMissing: function _watchIsMissing() {
      if (Ember.get(this, 'isMissing')) {
        Ember.set(this, 'isEdit', false);
      }
    },
    // -------------------------------------------------------------------------
    // Actions
    actions: {
      /**
       * Sets `isEdit` and invokes callback.
       *
       * @event editField
       * @param {String} importField
       * @private
       */
      editField: function editField(importField) {
        Ember.set(this, 'isEdit', true);
        this.invokeCallback('onEditField', importField);
      },

      /**
       * Resets `isEdit` and invokes callback.
       *
       * @event editField
       * @param {String} importField
       * @private
       */
      cancelEdit: function cancelEdit(importField) {
        Ember.set(this, 'isEdit', false);
        this.invokeCallback('onEditCancel', importField);
      },

      /**
       * Unsets mapped field and calls `onSkipField`.
       * Enables `mappedField` as a select choice.
       *
       * @event skipField
       * @param {Object} mappedField
       * @private
       */
      skipField: function skipField(mappedField) {
        this._disableField(null);

        Ember.set(this, 'mappedField', null);
        this.invokeCallback('onSkipField', mappedField);
      },

      /**
       * Resets `isEdit` and saves a mapped field.
       * Disables `mappedField` as a select choice.
       *
       * @event saveField
       * @param {Object} selectedField
       * @private
       */
      saveField: function saveField(selectedField) {
        this._disableField(selectedField);

        var mappedField = Ember.get(this, 'mappedField');
        Ember.set(this, 'mappedField', selectedField);
        Ember.set(this, 'isEdit', false);
        this.invokeCallback('onSaveField', selectedField, mappedField);
      },

      /**
       * Caches `selectedField` for saveField() action.
       *
       * @event onSelectedField
       * @private
       */
      onSelectField: function onSelectField(selectedField) {
        Ember.set(this, 'selectedField', selectedField);
      }
    }
  });

  _exports.default = _default;
});