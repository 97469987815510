define("ember-svg-jar/inlined/calendar-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>small-calendar</title><g fill=\"#010002\" fill-rule=\"evenodd\" opacity=\".8\"><path d=\"M11 1.125H9.375v-.75a.375.375 0 10-.75 0v.75h-2.25v-.75a.375.375 0 10-.75 0v.75h-2.25v-.75a.375.375 0 10-.75 0v.75H1a1 1 0 00-1 1V11a1 1 0 001 1h10a1 1 0 001-1V2.125a1 1 0 00-1-1zM11.25 11a.25.25 0 01-.25.25H1A.25.25 0 01.75 11V2.125a.25.25 0 01.25-.25h1.625v.75a.375.375 0 10.75 0v-.75h2.25v.75a.375.375 0 10.75 0v-.75h2.25v.75a.375.375 0 10.75 0v-.75H11a.25.25 0 01.25.25V11z\"/><path d=\"M2.625 4.5h1.5v1.125h-1.5zM2.625 6.375h1.5V7.5h-1.5zM2.625 8.25h1.5v1.125h-1.5zM5.25 8.25h1.5v1.125h-1.5zM5.25 6.375h1.5V7.5h-1.5zM5.25 4.5h1.5v1.125h-1.5zM7.875 8.25h1.5v1.125h-1.5zM7.875 6.375h1.5V7.5h-1.5zM7.875 4.5h1.5v1.125h-1.5z\"/></g>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});