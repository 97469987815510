define("ember-svg-jar/inlined/unlink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.916 0l1.43.303L6.78 12.409l-.106.505-.717 3.382-1.43-.303.573-2.703.36-1.7L7.916 0zM2.144 12.982c.525.525 1.4.525 1.925 0l.095-.095-.463 2.187a3.157 3.157 0 01-2.782-.866 3.17 3.17 0 010-4.463l.54-.542-.015-.016 2.999-3c.075-.1.15-.199.239-.292l1.215-1.188-.706 3.332-2.447 2.448-.015-.015-.585.585a1.373 1.373 0 000 1.925zm10.85-10.85a3.016 3.016 0 01.048 4.408l.007.007-4.377 4.377-.003-.003-.698.697.697-3.288 1.284-1.284c.002.003.002.007.003.01l1.802-1.801.019.019a1.373 1.373 0 00-.007-1.916 1.373 1.373 0 00-1.925 0l-.156.155.476-2.242a3.162 3.162 0 012.83.862z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "17",
      "viewBox": "0 0 14 17"
    }
  };
  _exports.default = _default;
});