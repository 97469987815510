define("ember-svg-jar/inlined/close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.325 2.325L9.675.675 6 4.425 2.325.675l-1.65 1.65L4.425 6 .675 9.675l1.65 1.65L6 7.575l3.675 3.75 1.65-1.65L7.575 6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
  _exports.default = _default;
});