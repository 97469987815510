define("ember-svg-jar/inlined/lab-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#282F39\" d=\"M9.589 6.553V2.114h.711V.68H2.525v1.434h.711v4.44l-3.035 7.72a2.47 2.47 0 00.311 2.369 2.752 2.752 0 002.225 1.107h7.36c.887 0 1.72-.414 2.225-1.108.506-.694.622-1.58.31-2.37l-3.043-7.72zM3.906 8.946l.84-2.133V2.115H8.08v4.698l.84 2.133H3.907z\"/><path fill=\"#79BB43\" d=\"M10.815 8.983H2.208l-2.007 5.29a2.47 2.47 0 00.311 2.37 2.752 2.752 0 002.225 1.107h7.36c.887 0 1.72-.414 2.225-1.108.506-.694.622-1.58.31-2.37l-1.817-5.289z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "18",
      "viewBox": "0 0 13 18"
    }
  };
  _exports.default = _default;
});