define("ember-svg-jar/inlined/group-together", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M4 0h3v2H4zM8 3h3v2H8zM4 7h3v2H4zM0 3h3v2H0z\"/><path fill-rule=\"nonzero\" d=\"M6 5v1H5V5H4V4h1V3h1v1h1v1H6z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "11",
      "height": "9",
      "viewBox": "0 0 11 9"
    }
  };
  _exports.default = _default;
});