define("ember-bulk-manager/components/sub/bulk-select", ["exports", "ember-bulk-manager/templates/sub/bulk-select", "ember-bulk-manager/utils/bulk-component", "ember-bulk-manager/utils/bulk-errors", "ember-bulk-manager/utils/bulk-comparison-helper"], function (_exports, _bulkSelect, _bulkComponent, _bulkErrors, _bulkComparisonHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A generic component providing drop down select.
   *
   * ```html
   * {{bulk-select
   *     options=options
   *     selected=selected
   *     multiple=true|[false]
   *     disabled=true|[false]
   *     labelProperty='label'
   *     disabeldProperty='disabled'
   *     prompt='Please choose...'
   *     onChange=(action "myAction")}}
   * ```
   *
   * Action signatures:
   *
   * - onChange(selected)
   *
   * @namespace Component
   * @class BulkSelect
   * @extends BaseComponent
   * @public
   */
  var _default = _bulkComponent.default.extend({
    layout: _bulkSelect.default,
    tagName: 'select',
    classNames: ['bulk-select'],
    attributeBindings: ['multiple', 'disabled'],
    classNameBindings: ['multiple:bulk-select--multiple', 'disabled:bulk-select--disabled'],
    // -------------------------------------------------------------------------
    // Dependencies
    l10n: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Attributes

    /**
     * Collection of available options for drop down.
     *
     * @attribute options
     * @type {Array|Object}
     * @default []
     * @public
     */
    options: Ember.computed(function () {
      return [];
    }),

    /**
     * If provided, an empty option will be added.
     *
     * @attribute prompt
     * @type {String}
     * @default null
     * @public
     */
    prompt: null,

    /**
     * If true, the bound object will be passed
     * instead of item's `value` property only.
     *
     * @attribute reference
     * @type Boolean
     * @optional
     */
    reference: true,

    /**
     * Flag to set whether multiple items are allowed or not.
     *
     * @attribute multiple
     * @type {Boolean}
     * @default false
     * @public
     */
    multiple: false,

    /**
     * Flag to set whether select is currently disabled or not.
     *
     * @attribute disabled
     * @type {Boolean}
     * @default false
     * @public
     */
    disabled: false,

    /**
     * Property path of an option's label in case `options`
     * is an array of obects. Sets option value if not given.
     *
     * @attribute labelProperty
     * @type {String}
     * @default 'label'
     * @public
     */
    labelProperty: 'label',

    /**
     * Property path of an option's disabled state in case
     * `options` is an array of obects.
     *
     * @attribute disabledProperty
     * @type {String}
     * @default 'disabled'
     * @public
     */
    disabledProperty: 'disabled',

    /**
     * Current selection of <select> as reference to values.
     * Will be an array of options if `multiple` is true or
     * a single option depending on data type of `options`.
     *
     * @attribute selected
     * @type {Mixed}
     * @public
     */
    selected: Ember.computed('multiple', {
      set: function set(key, val
      /* , oldVal*/
      ) {
        if (Ember.isNone(val)) {
          return Ember.get(this, 'multiple') ? [] : null;
        }

        return val;
      },
      get: function get()
      /* key*/
      {
        return Ember.get(this, 'multiple') ? [] : null;
      }
    }),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * References from `value` attribute of <select>.
     *
     * @property references
     * @type {Object}
     * @public
     */
    references: Ember.computed('keys', 'options', 'selected', 'selected.{id,value,disabled}', 'parsedOptions.@each.{id,value,disabled}', {
      get: function get()
      /* key*/
      {
        return this._getReferences();
      }
    }),

    /**
     * This normalizes the options to an object.
     * This is necessary for '.@each.x' to work with it
     *
     * @property parsedOptions
     * @type {Object[]}
     * @readOnly
     * @protected
     */
    parsedOptions: Ember.computed('options.[]', function () {
      var options = Ember.get(this, 'options');
      return options.map(function (opt) {
        if (typeof opt === 'string') {
          return {
            id: opt,
            value: opt
          };
        }

        return opt;
      });
    }),

    /**
     * Dummy key to be used for prompt option.
     *
     * @attribute promptValue
     * @type {String}
     * @default '__prompt__'
     * @public
     */
    promptValue: '__prompt__',

    /**
     * Current type information of `options`.
     *
     * @property type
     * @type {String}
     * @public
     */
    type: Ember.computed('options', {
      get: function get()
      /* key*/
      {
        var options = Ember.get(this, 'options');

        if (Ember.isArray(options)) {
          // detects proxy array's
          // too if asynchronously
          return 'array';
        }

        return Ember.typeOf(options);
      }
    }),

    /**
     * Sorted options if they are
     * from type Array to used by
     * view to iterate over.
     */
    sortedOptions: Ember.computed('references', {
      get: function get()
      /* key*/
      {
        var prop = Ember.get(this, 'labelProperty');
        var options = Ember.get(this, 'options');

        var sortOptions = function sortOptions() {
          options.sort(function (a, b) {
            var labelA = Ember.get(a, prop);
            var labelB = Ember.get(b, prop);

            if (labelA < labelB) {
              return -1;
            }

            if (labelA > labelB) {
              return 1;
            }

            return 0;
          });
        };

        switch (Ember.typeOf(options)) {
          case 'instance':
            options = options.toArray();
            sortOptions();
            break;

          case 'array':
            sortOptions();
            break;

          default:
            options = [];
        }

        return options;
      }
    }),

    /**
     * Sorted keys from options if
     * they are from type Object to
     * used by view to iterate over.
     *
     * @property keys
     * @type {Array}
     * @public
     */
    keys: Ember.computed({
      get: function get()
      /* key*/
      {
        var options = Ember.get(this, 'options');

        switch (Ember.typeOf(options)) {
          case 'object':
            {
              var prop = Ember.get(this, 'labelProperty');
              var keys = Object.keys(options);
              keys.sort(function (a, b) {
                var labelA = Ember.get(options, "".concat(a, ".").concat(prop));
                var labelB = Ember.get(options, "".concat(b, ".").concat(prop));

                if (labelA < labelB) {
                  return -1;
                }

                if (labelA > labelB) {
                  return 1;
                }

                return 0;
              });
              return keys;
            }

          default:
            {
              return [];
            }
        }
      }
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Recalculates `keys` property if `options` is an object.
     *
     * @public
     * @method didReceiveAttrs
     * @return {Void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var options = Ember.get(this, 'options');

      switch (Ember.typeOf(options)) {
        case 'object':
          this.notifyPropertyChange('keys');
          break;

        case 'array':
        case 'instance':
          break;

        default:
          throw new _bulkErrors.WrongParameterTypeError(Ember.get(this, 'l10n').t('`options` parameter has to be an array or object!'));
      }
    },

    /**
     * Sets `selected` and invokes `onChange` callback.
     *
     * @method change
     * @param {Object} evt
     * @return {Void}
     * @public
     */
    change: function change()
    /* evt*/
    {
      var selected = this._getSelected();

      Ember.set(this, 'selected', selected);
      this.invokeCallback('onChange', selected);
    },

    /**
     * Retrieves selected elements from <select>
     * and links to correct item from `options`.
     *
     * @method _getSelected
     * @return {Mixed}
     * @private
     */
    _getSelected: function _getSelected() {
      var options = Ember.get(this, 'element.options');
      var selected = [];

      for (var i = 0; i < options.length; i++) {
        var option = options[i];

        if (!Ember.get(option, 'selected')) {
          continue;
        }

        var reference = Ember.get(option, 'value');

        var value = this._getValueByRef(reference);

        if (!Ember.get(this, 'reference')) {
          value = Ember.get(value, 'id') || Ember.get(value, 'value');
        }

        selected.push(value);
      }

      if (!Ember.get(this, 'multiple')) {
        return selected[0] || null;
      }

      return selected.filter(function (v) {
        return v;
      });
    },

    /**
     * Retrieves `references` from current selection
     * for applying initial `selected` attributes.
     *
     * @method _getReferences
     * @return {Object}
     * @private
     */
    _getReferences: function _getReferences() {
      var selected = Ember.get(this, 'selected');

      if (Ember.isNone(selected)) {
        var value = Ember.get(this, 'multiple') ? [] : null;
        Ember.set(this, 'selected', value);
        return {};
      }

      if (!Ember.isArray(selected)) {
        selected = [selected];
      }

      var references = {};

      for (var i = 0; i < selected.length; i++) {
        references[this._getRefByValue(selected[i])] = true;
      }

      return references;
    },

    /**
     * Get a value from array or object by reference.
     *
     * @method _getValueByRef
     * @param {Mixed} reference
     * @return {Void}
     * @private
     */
    _getValueByRef: function _getValueByRef(reference) {
      var promptValue = Ember.get(this, 'promptValue');

      if (reference === promptValue) {
        return null;
      }

      var options = Ember.get(this, 'options');
      var option;

      switch (Ember.typeOf(options)) {
        case 'array':
        case 'instance':
          options = Ember.get(this, 'sortedOptions');
          option = options[reference];
          break;

        case 'object':
          option = Ember.get(options, reference);
          break;

        default:
      }

      return option;
    },

    /**
     * Get a reference from array or object by value.
     *
     * @method _getRefByValue
     * @param {Mixed} value
     * @return {Void}
     * @private
     */
    _getRefByValue: function _getRefByValue(value) {
      var promptValue = Ember.get(this, 'promptValue');
      var options = Ember.get(this, 'options');
      var prompt = Ember.get(this, 'prompt');

      var _cmpRef = function _cmpRef(option, ref) {
        var reference;

        switch (Ember.typeOf(value)) {
          case 'object':
            {
              if ((0, _bulkComparisonHelper.compareObject)(value, option)) {
                reference = ref;
              }

              break;
            }

          case 'instance':
            {
              var optionId = Ember.get(value, 'id');
              var valueId = Ember.get(value, 'id');

              if (valueId === optionId) {
                reference = ref;
              }

              break;
            }

          default:
            {
              if (value == Ember.get(option, 'value')) {
                reference = ref;
              }

              if (value == Ember.get(option, 'id')) {
                reference = ref;
              }

              if (value == option) {
                reference = ref;
              }
            }
        }

        return reference;
      };

      var reference;

      switch (Ember.typeOf(options)) {
        case 'array':
        case 'instance':
          {
            options.forEach(function (option, index) {
              if (Ember.isNone(reference)) {
                reference = _cmpRef(option, index);
              }
            });

            if (Ember.isNone(reference)) {
              reference = prompt ? promptValue : 0;
            }

            break;
          }

        case 'object':
          {
            var keys = Ember.get(this, 'keys');

            for (var i = 0; i < keys.length; i++) {
              var option = Ember.get(options, keys[i]);
              reference = _cmpRef(option, keys[i]);

              if (!Ember.isNone(reference)) {
                break;
              }
            }

            if (Ember.isNone(reference)) {
              reference = prompt ? promptValue : keys[0];
            }

            break;
          }

        default:
      }

      return reference;
    }
  });

  _exports.default = _default;
});