define("ember-svg-jar/inlined/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.408 1.843a6.292 6.292 0 00-8.898 0C.296 4.057.046 8.226 1.925 10.73L6.96 18l5.033-7.27c1.88-2.504 1.63-6.673-.584-8.887zM7.02 8.308a2.077 2.077 0 110-4.154 2.077 2.077 0 010 4.154z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18"
    }
  };
  _exports.default = _default;
});