define("ember-bulk-manager/services/bulk-asset-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    assetLoader: Ember.inject.service(),
    // -------------------------------------------------------------------------
    // Properties

    /**
     * The asset map is built by this addons `index.js` and put into a special meta tag in the head.
     * It maps e.g. `fuse.js` to `/assets/....../fuse-xxxx.js`, allowing us to dynamically load fingerprinted assets.
     *
     * @property _assetMap
     * @type {Object}
     * @private
     */
    _assetMap: Ember.computed(function () {
      var assetMapString = document.querySelector("meta[name='ember-bulk-manager:assetMap']").content;

      if (!assetMapString) {
        return {};
      }

      return JSON.parse(decodeURIComponent(assetMapString));
    }),
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Registers async test helpers.
     *
     * @public
     * @method destroy
     * @return {Void}
     */
    init: function init() {
      this._super.apply(this, arguments);

      this._registerBundles();
    },

    /**
     * Register a manifest with ember-asset-loader.
     * From there, we can then load these bundles via `loadBundle()`.
     *
     * @method _registerBundles
     * @private
     */
    _registerBundles: function _registerBundles() {
      var assetLoader = this.assetLoader;
      var handsontable = {
        assets: [{
          uri: this._getPath('handsontable.js'),
          type: 'js'
        }, {
          uri: this._getPath('handsontable.css'),
          type: 'css'
        }]
      };
      var pikaday = {
        assets: [{
          uri: this._getPath('pikaday.js'),
          type: 'js'
        }, {
          uri: this._getPath('pikaday.css'),
          type: 'css'
        }]
      };
      var fuse = {
        assets: [{
          uri: this._getPath('fuse.js'),
          type: 'js'
        }]
      };
      var xlsx = {
        assets: [{
          uri: this._getPath('xlsx.js'),
          type: 'js'
        }, {
          uri: this._getPath('papaparse.js'),
          type: 'js'
        }]
      };
      var manifest = {
        bundles: {
          pikaday: pikaday,
          fuse: fuse,
          handsontable: handsontable,
          xlsx: xlsx
        }
      };
      assetLoader.pushManifest(manifest);
    },

    /**
     * Load a bundle from ember-asset-loader.
     * This basically aliases to `assetLoader.loadBundle(bundleName)`.
     *
     * @method loadBundle
     * @param {String} bundleName The name of the bundle to load, e.g. handsontable
     * @return {RSVP.Promise}
     * @public
     */
    loadBundle: function loadBundle(bundleName) {
      var assetLoader = this.assetLoader;
      return assetLoader.loadBundle(bundleName);
    },

    /**
     * Gets complete path to a custom asset.
     *
     * @private
     * @method _getPath
     * @param {String} file
     * @return {String}
     */
    _getPath: function _getPath(file) {
      var _assetMap = this._assetMap;
      var filePath = _assetMap[file];
      (false && !(filePath) && Ember.assert("Could not find file \"".concat(file, "\" in ember-bulk-manager asset map"), filePath));
      return filePath;
    }
  });

  _exports.default = _default;
});