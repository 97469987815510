define("ember-bulk-manager/helpers/bulk-import-field-label", ["exports", "ember-bulk-manager/services/bulk-template"], function (_exports, _bulkTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.cleanField = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * Removes `MAPPING_PREFIX` from field for display.
   *
   * @method cleanField
   * @param {String} field
   * @return {String}
   * @public
   */
  var cleanField = function cleanField(field) {
    return field.replace(_bulkTemplate.MAPPING_PREFIX, '');
  };
  /**
   * This helper cleans given import field from mapping prefix.
   *
   * ```html
   * {{bulk-import-field-label importField}}
   * ```
   *
   * @namespace Helper
   * @extends Ember.Helper
   * @class BulkImportFieldLabel
   * @public
   */


  _exports.cleanField = cleanField;

  var _default = Ember.Helper.extend({
    // -------------------------------------------------------------------------
    // Dependencies
    // -------------------------------------------------------------------------
    // Methods

    /**
     * Removes `MAPPING_PREFIX` from import field.
     *
     * @method compute
     * @param {Array} params Positional params
     * @param {Object} [hash={}]
     * @public
     *
     * @return {Boolean}
     */
    compute: function compute(_ref
    /* , hash*/
    ) {
      var _ref2 = _slicedToArray(_ref, 1),
          field = _ref2[0];

      switch (Ember.typeOf(field)) {
        case 'string':
          {
            return cleanField(field);
          }

        default:
          {
            return '';
          }
      }
    }
  });

  _exports.default = _default;
});