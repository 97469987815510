define("ember-svg-jar/inlined/setting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 7.875v-1.75L12.512 5.6c-.175-.525-.35-1.05-.612-1.575l.7-1.4L11.375 1.4l-1.4.7c-.438-.263-.963-.525-1.575-.613L7.875 0h-1.75L5.6 1.488c-.525.175-1.05.35-1.487.612l-1.4-.7L1.4 2.713l.7 1.4c-.263.437-.438.962-.613 1.487L0 6.125v1.75l1.488.525c.175.525.35 1.05.612 1.575l-.7 1.4L2.625 12.6l1.4-.7c.438.262.962.525 1.575.612L6.125 14h1.75l.525-1.488c.525-.175 1.05-.35 1.575-.612l1.4.7 1.225-1.225-.7-1.4c.262-.438.525-.963.612-1.575L14 7.875zM7 10.5A3.51 3.51 0 013.5 7 3.51 3.51 0 017 3.5 3.51 3.51 0 0110.5 7 3.51 3.51 0 017 10.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});