define("ember-svg-jar/inlined/rating-maybe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.5 0C9.645 0 0 9.645 0 21.5S9.645 43 21.5 43 43 33.355 43 21.5 33.355 0 21.5 0zm0 41.208c-10.867 0-19.708-8.84-19.708-19.708 0-10.867 8.84-19.708 19.708-19.708 10.867 0 19.708 8.84 19.708 19.708 0 10.867-8.84 19.708-19.708 19.708z\"/><circle cx=\"15\" cy=\"16\" r=\"2\"/><circle cx=\"29\" cy=\"16\" r=\"2\"/><path d=\"M31.125 27h-19.25c-.484 0-.875.447-.875 1 0 .553.391 1 .875 1h19.25c.484 0 .875-.447.875-1 0-.553-.391-1-.875-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "43",
      "height": "43",
      "viewBox": "0 0 43 43"
    }
  };
  _exports.default = _default;
});