define("ember-svg-jar/inlined/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M0 1.024c.003.105.01.292.026.55.026.425.066.896.122 1.403.162 1.451.426 2.905.817 4.27 1.18 4.113 3.28 6.697 6.6 6.697 2.707 0 4.306-1.465 6.496-4.96.15-.238.76-1.228.92-1.48C17.446 3.568 19.314 2 22.607 2V0c-4.168 0-6.525 1.98-9.322 6.441-.163.26-.775 1.25-.92 1.48-1.847 2.95-3.02 4.023-4.8 4.023-2.177 0-3.717-1.895-4.678-5.247-.357-1.247-.602-2.593-.752-3.941-.052-.473-.09-.912-.113-1.304-.015-.232-.021-.394-.023-.476l-2 .048z\"/><path d=\"M4.354 13.47a30.138 30.138 0 01.193-1.613c.18-1.22.43-2.44.756-3.57.859-2.971 2.064-4.662 3.449-4.662 1.416 0 2.707 1.326 5.111 4.905-.101-.152.39.58.51.758.194.288.357.527.514.754 2.033 2.938 3.46 4.277 5.348 4.277v-2c-.954 0-2.056-1.034-3.703-3.415-.152-.22-.31-.452-.5-.734l-.509-.756c-2.835-4.22-4.362-5.789-6.771-5.789-2.61 0-4.284 2.348-5.37 6.107a29.397 29.397 0 00-.813 3.833 32.129 32.129 0 00-.209 1.752l1.994.154z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "23",
      "height": "15",
      "viewBox": "0 0 23 15"
    }
  };
  _exports.default = _default;
});