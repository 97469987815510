define("ember-svg-jar/inlined/reorder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.5 4.942V4.48v.462zm0-.72v.258-.258zm1 1.47V15.5h4v2h-6V3.712h2l1.88.042V.793l3.934 3.935-3.93 3.93V5.692H2.5zm13.607 10.366v.462-.462zm0 .462v.258-.258zm-1-11.02h-4v-2h6v13.788h-2l-1.88-.042v2.961l-3.934-3.935 3.93-3.93v2.966h1.884V5.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "21",
      "viewBox": "0 0 18 21"
    }
  };
  _exports.default = _default;
});