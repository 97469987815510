define("ember-bulk-manager/templates/sub/bulk-import-prefill-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XkMRxPJ5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[12,\"for\",[21,\"subComponentId\"]],[11,\"class\",\"bulk-import-prefill-field-label\"],[9],[1,[23,[\"availableField\",\"label\"]],false],[10],[0,\"\\n\"],[1,[27,\"component\",[[23,[\"subComponentName\"]]],[[\"id\",\"disabled\",\"onChange\",\"availableField\",\"class\"],[[23,[\"subComponentId\"]],[27,\"readonly\",[[23,[\"disabled\"]]],null],[27,\"action\",[[22,0,[]],\"onChange\"],null],[27,\"readonly\",[[23,[\"availableField\"]]],null],\"bulk-import-prefill-field-subcomponent\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-bulk-manager/templates/sub/bulk-import-prefill-field.hbs"
    }
  });

  _exports.default = _default;
});