define("ember-svg-jar/inlined/warehouse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#282F39\" fill-rule=\"evenodd\"><path d=\"M83.115 172.911h293.189v286.508h82.473V106.956L223.178 0 .642 106.956v352.463h82.473V172.911z\"/><path d=\"M196.042 403.053h64.095v55.234h-64.095zM274.708 403.053h64.095v55.234h-64.095zM117.386 403.053h64.095v55.234h-64.095zM196.042 327.289h64.095v55.234h-64.095zM117.386 327.289h64.095v55.234h-64.095zM274.708 327.289h64.095v55.234h-64.095zM196.042 251.537h64.095v55.234h-64.095zM117.386 251.537h64.095v55.234h-64.095z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "459",
      "height": "460",
      "viewBox": "0 0 459 460"
    }
  };
  _exports.default = _default;
});