define("ember-svg-jar/inlined/download-xls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#000\" d=\"M0 13.125h15V15H0zM7.5 12.188L12.188 7.5H9.375V0h-3.75v7.5H2.813z\"/><rect width=\"22\" height=\"12\" x=\"17\" y=\"1\" fill=\"#60A425\" rx=\"2\"/><path fill=\"#FFF\" d=\"M23.531 6.23l.938-1.918h1.523l-1.629 2.82L26.117 10h-1.601l-.97-1.957L22.579 10h-1.523l1.672-2.867-1.63-2.82h1.512l.922 1.917zm4.344 2.754h2.45V10H26.55V4.312h1.324v4.672zm6.027-.492c0-.2-.066-.355-.199-.465-.133-.109-.371-.225-.715-.347-.718-.227-1.251-.47-1.597-.729-.347-.259-.52-.633-.52-1.123 0-.476.206-.862.62-1.156.412-.294.936-.442 1.572-.442.63 0 1.146.163 1.548.487.403.324.597.741.584 1.252l-.008.023h-1.28a.718.718 0 00-.231-.553c-.154-.141-.366-.212-.637-.212-.266 0-.474.058-.625.175a.53.53 0 00-.227.434c0 .167.077.3.229.4.152.1.438.226.857.377.646.193 1.133.43 1.46.711.326.281.49.668.49 1.16 0 .5-.195.892-.584 1.174-.39.283-.906.424-1.55.424-.645 0-1.204-.162-1.675-.486-.471-.325-.7-.791-.687-1.4l.007-.024h1.286c0 .328.088.562.265.703.177.14.445.21.805.21.273 0 .477-.054.611-.163a.528.528 0 00.201-.43z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "39",
      "height": "15",
      "viewBox": "0 0 39 15"
    }
  };
  _exports.default = _default;
});