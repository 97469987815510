define("@vollersgroup/ember-cropster-common/components/modal-dialog/component", ["exports", "@vollersgroup/ember-cropster-common/components/modal-dialog/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    dialog: Ember.inject.service(),
    isOpen: Ember.computed.alias('dialog.isOpen'),
    mode: Ember.computed.alias('dialog.mode'),
    title: Ember.computed.alias('dialog.title'),
    modalClassNames: Ember.computed.alias('dialog.modalClassNames'),
    message: Ember.computed.alias('dialog.message'),
    confirmButtonText: Ember.computed.alias('dialog.confirmButtonText'),
    cancelButtonText: Ember.computed.alias('dialog.cancelButtonText'),
    messageHasHtml: Ember.computed('message', function () {
      return Ember.String.isHTMLSafe(Ember.get(this, 'message'));
    }),
    _confirm: function _confirm() {
      var dialog = Ember.get(this, 'dialog');
      dialog.close();
    },
    _becomesShown: Ember.observer('isOpen', function () {
      if (Ember.get(this, 'isOpen')) {
        Ember.run.next(function () {
          document.querySelector('[data-modal-primary-button]').focus();
        });
      }
    }),
    _cancel: function _cancel() {
      var dialog = Ember.get(this, 'dialog');
      dialog.closeCancel();
    },
    actions: {
      close: function close(mode) {
        if (mode === 'cancel') {
          return this._cancel();
        }

        return this._confirm();
      }
    }
  });

  _exports.default = _default;
});