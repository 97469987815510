define("ember-svg-jar/inlined/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M13.037.963a3.169 3.169 0 00-4.462 0l-3.85 3.762c-1.225 1.313-1.225 3.238 0 4.55.088.088.263.175.35.262l1.313-1.312c-.088-.088-.263-.175-.35-.262a1.373 1.373 0 010-1.926l3.85-3.85a1.373 1.373 0 011.925 0c.524.526.524 1.4 0 1.925L10.675 5.25c.35.7.438 1.487.35 2.188l2.012-2.013a3.02 3.02 0 000-4.462z\"/><path d=\"M8.925 4.463L7.612 5.775s.263.175.35.262c.526.526.526 1.4 0 1.926l-3.85 3.85c-.525.524-1.4.524-1.925 0a1.373 1.373 0 010-1.926L3.325 8.75c-.35-.7-.088-1.138-.35-2.188L.962 8.575a3.169 3.169 0 000 4.463 3.169 3.169 0 004.463 0l3.85-3.85a3.169 3.169 0 000-4.463c-.175-.087-.35-.262-.35-.262z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});