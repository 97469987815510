define("ember-bulk-manager/utils/bulk-file-helper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFile = _exports.getExtension = _exports.validateFiles = _exports.supportsFileAPI = void 0;

  /**
   * Reads file extension from a valid file.
   *
   * @public
   * @namespace Utils
   * @return {String}
   */
  var supportsFileAPI = function supportsFileAPI() {
    return !!(window.File && window.Blob && window.FileList && window.FileReader);
  };
  /**
   * Validates file with allowed types by extension.
   * Returns array containing invalid files if any.
   *
   * @public
   * @param {Array} allowedTypes
   * @param {Mixed} files
   * @namespace Utils
   * @return {Array}
   */


  _exports.supportsFileAPI = supportsFileAPI;

  var validateFiles = function validateFiles(allowedTypes, files) {
    var invalidFiles = [];

    if (!Ember.isArray(allowedTypes)) {
      return false;
    }

    if (!allowedTypes.length) {
      return false;
    }

    if (!Ember.isArray(files)) {
      files = [files];
    }

    files.forEach(function (file) {
      var ext = getExtension(file);

      if (allowedTypes.indexOf(ext) >= 0) {
        return;
      }

      if (!isFile(file)) {
        file = new File([''], '');
      }

      invalidFiles.push(file);
    });
    return invalidFiles;
  };
  /**
   * Reads file extension from a valid file.
   *
   * @public
   * @param {File} file
   * @namespace Utils
   * @return {String}
   */


  _exports.validateFiles = validateFiles;

  var getExtension = function getExtension(file) {
    if (!isFile(file)) {
      return '';
    }

    var name = file.name;
    var regex = /.+\.([\w]+)$/;
    var match = name.match(regex);

    if (Ember.isNone(match)) {
      return '';
    }

    return match[1];
  };
  /**
   * Determines if file is an instance of File.
   *
   * @public
   * @param {Mixed} file
   * @namespace Utils
   * @return {Boolean}
   */


  _exports.getExtension = getExtension;

  var isFile = function isFile(file) {
    return file instanceof File || file instanceof Blob;
  };

  _exports.isFile = isFile;
});