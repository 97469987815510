define("ember-svg-jar/inlined/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 8.75v3.5h1.75V14h10.5v-1.75H14v-3.5H0zm11.375 4.375h-8.75V10.5h8.75v2.625zM10.5 5.25v-3.5L8.137 0H3.5v5.25H0v2.625h14V5.25h-3.5zM7.875.875l1.137.875H7.875V.875zm1.75 5.25h-5.25V.875H7v1.75h2.625v3.5zm3.5.875h-.875v-.875h.875V7z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});