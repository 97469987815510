define('ember-prism/components/code-base', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['languageClass'],
    inline: false,
    language: 'markup',

    languageClass: Ember.computed('language', function () {
      return 'language-' + this.get('language');
    }),

    getElement: function getElement() {
      return this.element;
    },
    didInsertElement: function didInsertElement() {
      Prism.highlightElement(this.getElement());
    }
  });
});