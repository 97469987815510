define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.8 0A7.768 7.768 0 000 7.8c0 4.333 3.467 7.8 7.8 7.8s7.8-3.467 7.8-7.8S12.067 0 7.8 0zm.533 11.667H7v-1.2h1.333v1.2zm1.4-4.4c-.266.4-.6.733-1 1.066-.2.2-.333.334-.4.534-.066.133-.066.4-.066.666H7c0-.533.067-.866.2-1.066.133-.2.333-.467.733-.8.267-.267.467-.534.667-.8.133-.267.267-.534.267-.8 0-.334-.067-.6-.267-.8-.2-.2-.467-.267-.8-.267-.267 0-.533.067-.733.267-.2.133-.334.4-.334.733H5.467c0-.6.2-1.133.6-1.467.4-.333 1-.533 1.666-.533.734 0 1.334.2 1.734.533.466.267.666.8.666 1.467 0 .467-.133.867-.4 1.267z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});