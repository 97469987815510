define("ember-svg-jar/inlined/see", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M7.685.009A8.442 8.442 0 00.031 4.863a.326.326 0 000 .277 8.442 8.442 0 007.654 4.854A8.442 8.442 0 0015.34 5.14a.326.326 0 000-.277A8.442 8.442 0 007.685.009zm0 8.437c-1.914 0-3.464-1.542-3.464-3.444 0-1.903 1.552-3.444 3.464-3.444 1.915 0 3.465 1.542 3.465 3.444S9.598 8.446 7.685 8.446z\"/><ellipse cx=\"7.685\" cy=\"5\" rx=\"2.218\" ry=\"2.205\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "10",
      "viewBox": "0 0 16 10"
    }
  };
  _exports.default = _default;
});